/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { getMemberData, getProfileData } from "../Pages/Profile/api";
import { renderRedirect } from "../util";

function MenuSideBar({ showCloseIcon, ...props }) {
  const _renderRedirect = (url = "") => {
    renderRedirect(props, "/" + url);
  };

  const pathName = props?.location?.pathname;

  const [profileData, setProfileData] = useState({ name: "", trdrId: "" });

  const fetchUserDetails = async () => {
    let data = await getProfileData();
    const profileData = data?.data?.fetch_signup_application;
    data = await getMemberData();
    const memberData = data?.data?.get_member_data;
    setProfileData({
      name: profileData?.fullName || "",
      trdrId: memberData?.trdr_id || "",
    });
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const logout = async (e) => {
    localStorage.clear();
    renderRedirect(props, "/");
  };

  return (
    <div className="p-0" style={{ position: "relative", top: 45 }}>
      {!showCloseIcon && (
        <div
          style={{
            position: "fixed",
            textAlign: "center",
            top: 0,
            zIndex: 999,
            padding: 10,
            width: "16%",
          }}>
          <img
            src="../assets/img/trdr-horizontal.png"
            alt="logo"
            className="logo"
            style={{ width: "60%" }}
          />
        </div>
      )}
      <div className="profileBox pt-2 pb-2">
        <div className="image-wrapper"></div>
        <div className="in">
          <strong>{profileData.name}</strong>
          <div className="text-muted">{profileData.trdrId}</div>
        </div>
        {showCloseIcon && (
          <a href="#" className="btn btn-link btn-icon sidebar-close" data-bs-dismiss="modal">
            <ion-icon name="close-outline"></ion-icon>
          </a>
        )}
      </div>

      {/* <div className="listview-title mt-1">Menu</div> */}
      <ul className="listview flush transparent no-line image-listview">
        <li className={pathName === "/home" ? "active" : ""}>
          <a
            data-bs-dismiss="modal"
            onClick={() => _renderRedirect("home")}
            className="item pointer">
            <div className="icon-box ">
              <ion-icon name="home-outline"></ion-icon>
            </div>
            <div className="in">Home</div>
          </a>
        </li>
        <li className={pathName === "/account" ? "active" : ""}>
          <a
            data-bs-dismiss="modal"
            onClick={() => _renderRedirect("account")}
            className="item pointer">
            <div className="icon-box ">
              <ion-icon name="wallet-outline"></ion-icon>
            </div>
            <div className="in">Account</div>
          </a>
        </li>
        <li className={pathName === "/portfolio" ? "active" : ""}>
          <a
            data-bs-dismiss="modal"
            onClick={() => _renderRedirect("portfolio")}
            className="item pointer">
            <div className="icon-box ">
              <ion-icon name="briefcase-outline"></ion-icon>
            </div>
            <div className="in">Portfolio</div>
          </a>
        </li>
        <li className={pathName === "/reports" ? "active" : ""}>
          <a
            data-bs-dismiss="modal"
            onClick={() => _renderRedirect("reports")}
            className="item pointer">
            <div className="icon-box ">
              <ion-icon name="bar-chart-outline"></ion-icon>
            </div>
            <div className="in">Reports</div>
          </a>
        </li>
        <li className={pathName === "/profile" ? "active" : ""}>
          <a
            data-bs-dismiss="modal"
            onClick={() => _renderRedirect("profile")}
            className="item pointer">
            <div className="icon-box ">
              <ion-icon name="person-outline"></ion-icon>
            </div>
            <div className="in">Profile</div>
          </a>
        </li>
        <li className={pathName === "/trdr/ipo" ? "active" : ""}>
          <a
            data-bs-dismiss="modal"
            onClick={() => _renderRedirect("trdr/ipo")}
            className="item pointer">
            <div className="icon-box ">
              <ion-icon name="briefcase-outline"></ion-icon>
            </div>
            <div className="in">IPO</div>
          </a>
        </li>
        <li className={pathName === "/support" ? "active" : ""}>
          <a
            data-bs-dismiss="modal"
            onClick={() => _renderRedirect("support")}
            className="item pointer">
            <div className="icon-box ">
              <ion-icon name="chatbox-ellipses-outline"></ion-icon>
            </div>
            <div className="in">Support</div>
          </a>
        </li>

        <li className={pathName === "/notification" ? "active" : ""}>
          <a
            data-bs-dismiss="modal"
            onClick={() => _renderRedirect("notification")}
            className="item pointer">
            <div className="icon-box ">
              <ion-icon name="notifications-outline"></ion-icon>
            </div>
            <div className="in">
              Notification
              {/* <span className="badge badge-primary">10</span> */}
            </div>
          </a>
        </li>
        {/* <li className={pathName === "/settings" ? "active" : ""}>
          <a
            data-bs-dismiss="modal"
            onClick={() => _renderRedirect("settings")}
            className="item pointer"
          >
            <div className="icon-box ">
              <ion-icon name="card-outline"></ion-icon>
            </div>
            <div className="in">Settings</div>
          </a>
        </li> */}
        <div class="section mt-3 text-center">
          <button type="button" class="btn btn-dark me-1 mb-1" onClick={() => logout()}>
            Logout
          </button>
        </div>
      </ul>
    </div>
  );
}

export default withTranslation()(withRouter(MenuSideBar));
