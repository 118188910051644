import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";

import { renderRedirect } from "../../util";
import { add_nominee_details, getProfileData } from "./api";
import { validateForm } from "./Validations";
import LoadingContent from "../../Components/loading-content";
import CollapseCard from "./CollapseCard";
import ConfirmationPage from "./Confirmation";
import ConsentPage from "./Consent";
import Form from "./Form";
class AddNominee extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isValid: false,
            showLoader: true,
            activeCard: "1",
            nominee1: null,
            nominee2: null,
            nominee3: null,
            finalPayload: [],
            showConfirmPage: false,
            scroll: false,
            profile: {},
        };
    }

    async componentDidMount() {
        this.setState({
            showLoader: true,
        });

        let profileData = await getProfileData();
        if (profileData?.data) {
            let data = profileData?.data?.fetch_signup_application;
            let pincode =
                data.nominee_form_pincode ?? data?.permanentAddress
                    ? data.permanentAddress.substring(data.permanentAddress.length - 6)
                    : null;
            if (!pincode) {
                toast.error("permanent address is not updated");
                return this._renderRedirect();
            }
            if (data?.nominee_consent === false) {
                toast.error("Your consent has already been updated");
                return this._renderRedirect();
            }
            this.setState({
                profile: {
                    pincode: pincode,
                    nominees: data?.nominees ?? [],
                    consent: data?.nominee_consent,
                },
            });

            if (!this.handleAddNominee(this.state.profile.nominees)) {
                toast.error("You have already updated the nominees");
                return this._renderRedirect();
            }

            this.handleFormState();
            this.setState({ showLoader: false });
        }
    }

    handleAddNominee = (data) => {
        if (data && data.length <= 3) {
            if (data.length === 3) {
                return false;
            }
            let sum = data.reduce((accumulator, curValue) => {
                return accumulator + parseInt(curValue.percentage_of_share);
            }, 0);
            return 100 - sum !== 0 ? true : false;
        } else {
            return true;
        }
    };

    _renderRedirect = (url = "") => {
        renderRedirect(this.props, url === "" ? "/profile" : "/" + url);
    };

    saveNominee() {
        this.setState({
            showLoader: true,
        });

        let data = [];
        let totalPercentage = 100;

        for (let e = 1; e < 4; e++) {
            let obj = this.state[`nominee${e}`];
            if (obj) {
                if (obj?.name) {
                    let validation = validateForm(obj, `nominee${e}`);
                    if (!validation.status) {
                        toast.error(validation.err);
                        data = [];
                        break;
                    }

                    totalPercentage -= parseInt(obj?.percentage_of_share);

                    let payload = {};
                    payload.nominee_number = obj?.nominee_number ?? `${e}`;
                    payload.name = obj.name;
                    payload.percentage_of_share = obj?.percentage_of_share;
                    payload.relationship = obj.relationship;
                    payload.address = obj.address;
                    payload.mobile = obj.mobile;
                    payload.email = obj.email;
                    payload.id_type = obj.id_type;
                    payload.id_number = obj.id_number;
                    if (obj.minor) {
                        payload.dob_if_minor = obj?.dob_if_minor
                            ? obj?.dob_if_minor.split("-").reverse().join("/")
                            : moment().format("DD/MM/YYYY");
                        payload.minor_guardian_info = {
                            guardian_name: obj.guardian_name,
                            guardian_address: obj.guardian_address,
                            guardian_mobile: obj.guardian_mobile,
                            guardian_email: obj.guardian_email,
                            guardian_relationship: obj.guardian_relationship,
                            guardian_id_type: obj.guardian_id_type,
                            guardian_id_number: obj.guardian_id_number,
                        };
                    }
                    data.push(payload);
                }
            }
        }

        if (data.length) {
            this.setState({ finalPayload: data });
        }

        this.setState({
            showLoader: false,
            showConfirmPage: data.length ? true : false,
        });
        return;
    }

    async updateNomineeDetails() {
        if (this.state.finalPayload.length) {
            this.setState({
                showLoader: true,
            });
            let update = await add_nominee_details(this.state.profile.pincode, this.state.finalPayload);
            if (!update?.data) {
                return toast.error(update?.errors[0]?.message || "Something Went Wrong");
            } else {
                toast.success("nominees updated");
                this.setState({
                    showLoader: false,
                });
                return this._renderRedirect();
            }
        }
    }

    handleUserInput = (event) => {
        const name = event.target.name;
        switch (name) {
            default:
                let data = { [event.target.name]: event.target.value };
                this.setState({
                    [`nominee${this.state.activeCard}`]: { ...this.state[`nominee${this.state.activeCard}`], ...data },
                });
                break;
        }
    };

    handleSetState = (key, value) => {
        let data = { [key]: value };
        if (key === "minor") {
            if (!value) {
                let fields = [
                    "dob_if_minor",
                    "guardian_name",
                    "guardian_address",
                    "guardian_mobile",
                    "guardian_email",
                    "guardian_relationship",
                    "guardian_id_type",
                    "guardian_id_number",
                ];
                fields.map((e) => delete this.state[`nominee${this.state.activeCard}`][e]);
            }
            this.setState({
                [`nominee${this.state.activeCard}`]: { ...this.state[`nominee${this.state.activeCard}`], ...data },
            });
        } else {
            this.setState({
                [`nominee${this.state.activeCard}`]: { ...this.state[`nominee${this.state.activeCard}`], ...data },
            });
        }
    };

    handleActiveCard = (value) => {
        if (value === this.state.activeCard) {
            this.setState({ activeCard: "0" });
        } else {
            if (value === "1") {
                this.setState({ activeCard: value });
            } else if (value === "2") {
                if (this.state[`nominee${value - 1}`]) {
                    if (this.fetchPercentage("2") !== "0") {
                        this.setState({ activeCard: value });
                    }
                }
            } else if (value === "3") {
                if (this.state[`nominee${value - 1}`]) {
                    if (this.fetchPercentage("3") !== "0") {
                        this.setState({ activeCard: value });
                    }
                }
            }
        }
    };

    fetchPercentage = (e) => {
        let total = 100;
        const { nominee1, nominee2, nominee3 } = this.state;
        if (e === "1")
            if (nominee1) {
                if (nominee2?.percentage_of_share) {
                    total = total - parseFloat(nominee2?.percentage_of_share);
                }
                if (nominee3?.percentage_of_share) {
                    total = total - parseFloat(nominee3?.percentage_of_share);
                }
            }
        if (e === "2") {
            if (nominee1?.percentage_of_share) {
                total = total - parseFloat(nominee1?.percentage_of_share);
            }
            if (nominee3?.percentage_of_share) {
                total = total - parseFloat(nominee3?.percentage_of_share);
            }
        }
        if (e === "3") {
            if (nominee1?.percentage_of_share) {
                total = total - parseFloat(nominee1?.percentage_of_share);
            }
            if (nominee2?.percentage_of_share) {
                total = total - parseFloat(nominee2?.percentage_of_share);
            }
        }
        if (e === "0") {
            if (nominee1?.percentage_of_share) {
                total = total - parseFloat(nominee1?.percentage_of_share);
            }
            if (nominee2?.percentage_of_share) {
                total = total - parseFloat(nominee2?.percentage_of_share);
            }
            if (nominee3?.percentage_of_share) {
                total = total - parseFloat(nominee3?.percentage_of_share);
            }
        }
        return total.toString();
    };

    handleConsent = () => {
        this.setState({ profile: { ...this.state.profile, consent: true } });
    };

    handleFormState = async () => {
        const {
            profile: { nominees },
        } = this.state;

        for (let i = 1; i < 4; i++) {
            let filteredData = nominees.filter((e) => e.nominee_number == i);
            if (filteredData.length) {
                filteredData[0].minor = filteredData[0]?.dob_if_minor !== null;
                if (filteredData[0].minor) {
                    filteredData[0].guardian_name = filteredData[0]?.minor_guardian_info?.guardian_name;
                    filteredData[0].guardian_address = filteredData[0]?.minor_guardian_info?.guardian_address;
                    filteredData[0].guardian_mobile = filteredData[0]?.minor_guardian_info?.guardian_mobile;
                    filteredData[0].guardian_email = filteredData[0]?.minor_guardian_info?.guardian_email;
                    filteredData[0].guardian_relationship = filteredData[0]?.minor_guardian_info?.guardian_relationship;
                    filteredData[0].guardian_id_type = filteredData[0]?.minor_guardian_info?.guardian_id_type;
                    filteredData[0].guardian_id_number = filteredData[0]?.minor_guardian_info?.guardian_id_number;
                    delete filteredData[0]?.minor_guardian_info;
                }
                this.setState({ [`nominee${i}`]: filteredData[0], activeCard: `${i + 1}` });
            }
        }
        return;
    };

    render() {
        return (
            <div id="appCapsule">
                <LoadingContent showLoader={this.state.showLoader}>
                    {!this.state.profile?.consent ? (
                        <ConsentPage handleYesBtn={this.handleConsent} props={this.props} />
                    ) : (
                        <div className="section">
                            <div className="listed-detail mt-3">
                                {this.state.showConfirmPage ? (
                                    <button
                                        type="button"
                                        className="btn btn-dark me-1 mb-1"
                                        onClick={() => this.setState({ showConfirmPage: false })}
                                    >
                                        {"<"} Go Back To Edit
                                    </button>
                                ) : null}
                                <h3 className="section-title">
                                    {!this.state.showConfirmPage ? "Add Nominee Details" : "Review nominee details"}
                                </h3>
                            </div>

                            {!this.state.showConfirmPage ? (
                                <>
                                    {["1", "2", "3"].map((e) => {
                                        return (
                                            <CollapseCard
                                                key={e}
                                                active={this.state.activeCard === e}
                                                onClick={() => this.handleActiveCard(e)}
                                                title={`Nominee ${e}`}
                                                content={
                                                    <Form
                                                        state={this.state[`nominee${e}`]}
                                                        handleState={this.handleSetState}
                                                        handleUserInput={this.handleUserInput}
                                                        maxPercentage={this.fetchPercentage(e)}
                                                    />
                                                }
                                            />
                                        );
                                    })}
                                </>
                            ) : (
                                <ConfirmationPage data={this.state.finalPayload} allActive={true} />
                            )}
                            <div className="row">
                                <div className="col col-12">
                                    <div className="form-group basic">
                                        <button
                                            type="button"
                                            className="btn btn-primary btn-block btn-lg"
                                            data-bs-dismiss="modal"
                                            disabled={
                                                !this.state.showConfirmPage
                                                    ? !this.state.nominee1 && !this.state.nominee2 && !this.state.nominee3
                                                    : false
                                            }
                                            onClick={
                                                !this.state.showConfirmPage
                                                    ? () => this.saveNominee()
                                                    : () => this.updateNomineeDetails()
                                            }
                                        >
                                            {!this.state.showConfirmPage ? "SUBMIT" : "CONFIRM"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </LoadingContent>
            </div>
        );
    }
}
export default withRouter(AddNominee);
