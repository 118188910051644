import React from "react";
import { successMessageResolver } from "../../../util/helper";

const SuccessComponent = ({ title, response }) => (
  <div className="row" style={{ flex: 1 }}>
    <div className="col-1"></div>
    <div className="col-md-5" style={{ display: "grid", placeItems: "center" }}>
      <div>
        <div className="row">
          <div className="mx-auto text-center col-md-6 pb-3">
            <ion-icon
              style={{ width: 200, height: 200, color: "#00b52d" }}
              name="cloud-done"></ion-icon>
          </div>
        </div>
        <div className="row">
          <div style={{ fontSize: 18 }} className="mx-auto text-center col-md-6 pb-3">
            {title}
            <br />
            You will receive a mandate notification on your UPI app.
            <br />
            Please check the app and accept the mandate to complete the bid process.
            <br />
            The amount will be blocked in your account once you accept it.
            <br />
          </div>
        </div>
      </div>
    </div>
    <div className="col-md-5" style={{ display: "grid", placeItems: "center" }}>
      <div className="row">
        <div className=" mx-auto table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col" style={{ fontSize: 18 }}>
                  Order details
                </th>
              </tr>
            </thead>
            <tbody>
              {response &&
                Object.entries(successMessageResolver(response)).map(
                  (item) =>
                    item[0] === "orderDetails" &&
                    Object.entries(item[1]).map((value) => (
                      <tr key={value[1]}>
                        <td className="text-primary">{value[0]}</td>
                        <td className="text-end text-primary">{value[1]}</td>
                      </tr>
                    ))
                )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div className="col-1"></div>
  </div>
);

SuccessComponent.defaultProps = {
  response: {
    _id: "61f22dd905f8e3000830ff60",
    status: null,
    message: null,
    user_id: "60e6de023b78980007173b3e",
    created_ts: "1643261401069",
    scripid: "AWL",
    applicationno: "aa92a594043cc8ea",
    category: "IND",
    applicantname: "Amal Rag C",
    depository: "CDSL",
    dpid: "0",
    clientbenfid: "1208800000013002",
    chequereceivedflag: "n",
    chequeamount: "29900",
    panno: "CQDPC5147H",
    bankname: "8888",
    location: "upiidl",
    accountnumber_upiid: "amalragc@ybl",
    ifsccode: "",
    referenceno: "",
    asba_upiid: "1",
    statuscode: "0",
    statusmessage: "",
    bids: [
      {
        bidid: "333999723",
        quantity: "130",
        rate: "230",
        cuttoffflag: "1",
        orderno: "e0cf05f7c00de9ea",
        actioncode: "n",
        errorcode: "0",
        message: "BID ENTRY ACCEPTED. BID ID 333999723",
      },
    ],
  },
};

export default SuccessComponent;
