import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { renderRedirect } from "../../util";
import { verifyEmailOTP } from "./api";
import $ from "jquery";
import { spinnerService } from "../../Components/spinner.service";
import OuterLogo from "../../Components/outer-logo";

class VerifyEmailOTP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: localStorage.getItem("email"),
      opt1: "",
      opt2: "",
      opt3: "",
      opt4: "",
      opt5: "",
      opt6: "",
      formErrors: {
        otp1: "",
        otp2: "",
        otp3: "",
        otp4: "",
        otp5: "",
        otp6: "",
      },
      otpValid: false,
      errorMessage: "",
    };
  }

  async componentDidMount() {
    $("#otp-screen .form-control").keyup(function () {
      if (this.value.length == 0) {
        $(this).blur().parent().prev().children(".form-control").focus();
      } else if (this.value.length == this.maxLength) {
        $(this).blur().parent().next().children(".form-control").focus();
      }
    });
  }

  _renderRedirect = () => {
    // renderRedirect(this.props, "/language");
    //renderRedirect(this.props, "/welcome");
    renderRedirect(this.props, "/home");

  };

  _verifyOTP = async () => {
    spinnerService.show("mainSpinner", "Please wait...");
    let otpValue =
      this.state.otp1 +
      this.state.otp2 +
      this.state.otp3 +
      this.state.otp4 +
      this.state.otp5 +
      this.state.otp6;
    let otp = await verifyEmailOTP(
      localStorage.getItem("email"),
      otpValue
    ).catch((errors) => {
      spinnerService.hide("mainSpinner");
      this.setState({ errorMessage: errors.message });
    });
    if (otp && otp.data && otp.data.verify_email_signin.status) {
      localStorage.setItem("token", otp.data.verify_email_signin.token);
      spinnerService.hide("mainSpinner");
      this._renderRedirect();
    } else if (otp && !otp.data.verify_email_signin.status) {
      spinnerService.hide("mainSpinner");
      this.setState({ errorMessage: otp.data.verify_email_signin.message });
    }
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let otpValid = this.state.otpValid;

    switch (fieldName) {
      case "otp1":
        otpValid = value.length == 1;
        fieldValidationErrors.otp1 = otpValid ? "" : " is invalid";
        break;
      case "otp2":
        otpValid = value.length == 1;
        fieldValidationErrors.otp2 = otpValid ? "" : " is invalid";
        break;
      case "otp3":
        otpValid = value.length == 1;
        fieldValidationErrors.otp3 = otpValid ? "" : " is invalid";
        break;
      case "otp4":
        otpValid = value.length == 1;
        fieldValidationErrors.otp4 = otpValid ? "" : " is invalid";
      case "otp5":
        otpValid = value.length == 1;
        fieldValidationErrors.otp5 = otpValid ? "" : " is invalid";
      case "otp6":
        otpValid = value.length == 1;
        fieldValidationErrors.otp6 = otpValid ? "" : " is invalid";
        break;
      default:
        break;
    }
    this.setState(
      { formErrors: fieldValidationErrors, otpValid: otpValid },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({ formValid: this.state.otpValid });
  }

  errorClass(error) {
    return error.length === 0 ? "" : "is-invalid";
  }

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  }

  render() {
    const ConfirmButton = ({ style }) => (
      <div class="form-button-group  transparent" style={{ ...style }}>
        <button
          type="button"
          disabled={!this.state.formValid}
          onClick={this._verifyOTP}
          class="btn btn-primary btn-block btn-lg"
        >
          CONFIRM
        </button>
      </div>
    );

    return (
      <div id="appCapsule">
        <OuterLogo />
        <div className="row">
          <div class="col col-md-4 d-none d-md-block" />
          <div class="col">
            <div class="section mt-2 text-center">
              <div className="container my-auto py-4 bg-white rounded">
                <div className="row mt-2">
                  <div className="col-11 col-lg-11 mx-auto">
                    <h2>Confirm OTP</h2>
                    <p className="text-center"></p>
                    <p className="text-muted text-2 text-center">
                      Please enter the OTP (one time password) to verify your
                      account. A Code has been sent to{" "}
                      <span className="text-dark text-4">
                        {this.state.email}
                      </span>
                    </p>

                    {this.state.errorMessage ? (
                      <span
                        style={{ display: "block" }}
                        className="alert alert-warning alert-dismissible fade show"
                      >
                        {this.state.errorMessage}
                      </span>
                    ) : (
                      ""
                    )}

                    <form id="otp-screen" className="form-border" method="post">
                      <div className="form-row">
                        <div className="col form-group">
                          <input
                            inputmode="decimal"
                            pattern="[0-9]*"
                            className="form-control text-center text-6 px-0 py-2"
                            name="otp1"
                            onChange={(event) => this.handleUserInput(event)}
                            maxLength="1"
                            required
                            autoComplete="off"
                          />
                        </div>
                        <div className="col form-group">
                          <input
                            inputmode="decimal"
                            pattern="[0-9]*"
                            className="form-control text-center text-6 px-0 py-2"
                            name="otp2"
                            onChange={(event) => this.handleUserInput(event)}
                            maxLength="1"
                            required
                            autoComplete="off"
                          />
                        </div>
                        <div className="col form-group">
                          <input
                            inputmode="decimal"
                            pattern="[0-9]*"
                            className="form-control text-center text-6 px-0 py-2"
                            name="otp3"
                            onChange={(event) => this.handleUserInput(event)}
                            maxLength="1"
                            required
                            autoComplete="off"
                          />
                        </div>
                        <div className="col form-group">
                          <input
                            inputmode="decimal"
                            pattern="[0-9]*"
                            className="form-control text-center text-6 px-0 py-2"
                            name="otp4"
                            onChange={(event) => this.handleUserInput(event)}
                            maxLength="1"
                            required
                            autoComplete="off"
                          />
                        </div>
                        <div className="col form-group">
                          <input
                            inputmode="decimal"
                            pattern="[0-9]*"
                            className="form-control text-center text-6 px-0 py-2"
                            name="otp5"
                            onChange={(event) => this.handleUserInput(event)}
                            maxLength="1"
                            required
                            autoComplete="off"
                          />
                        </div>
                        <div className="col form-group">
                          <input
                            inputmode="decimal"
                            pattern="[0-9]*"
                            className="form-control text-center text-6 px-0 py-2"
                            name="otp6"
                            onChange={(event) => this.handleUserInput(event)}
                            maxLength="1"
                            required
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col col-md-4 d-none d-md-block" />
        </div>

        <div className="row">
          <div class="col col-md-4 d-none d-md-block" />
          <div class="col d-none d-md-block">
            <ConfirmButton style={{ position: "unset" }} />
          </div>
          <div class="col col-md-4 d-none d-md-block" />
        </div>
        <div className="row">
          <div class="col d-md-none">
            <ConfirmButton />
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(VerifyEmailOTP);
