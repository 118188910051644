import React from "react";
import { NavLink } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { renderRedirect } from "../../util";

import "./LanguageSelector.css";
import OuterLogo from "../../Components/outer-logo";

const LanguageSelector = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  const _renderRedirect = () => {
    renderRedirect(this.props, "/welcome");
  };

  const ContinueButton = ({ style }) => (
    <div class="form-button-group  transparent" style={{ ...style }}>
      <button type="button" class="btn btn-primary btn-block btn-lg">
        <NavLink to="/welcome" exact>
          CONTINUE{" "}
        </NavLink>
      </button>
    </div>
  );

  return (
    <div id="appCapsule">
      <OuterLogo />

      <div class="section mt-2 text-center">
        <h2>Choose Language</h2>
        <div className="row">
          <div class="col">
            <div>
              <div className="languageSelector" onChange={changeLanguage}>
                <label>
                  <input
                    type="radio"
                    value="en"
                    name="language"
                    defaultChecked
                  />
                  <div className="language">
                    <div className="heading1">English</div>
                    <div className="heading2">English</div>
                    <div className="heading3">A</div>
                  </div>
                </label>
                <label>
                  <input type="radio" value="hindi" name="language" />
                  <div className="language">
                    <div className="heading1">हिंदी</div>
                    <div className="heading2">Hindi</div>
                    <div className="heading3">क</div>
                  </div>
                </label>
                <label>
                  <input type="radio" value="marathi" name="language" />
                  <div className="language">
                    <div className="heading1">मराठी</div>
                    <div className="heading2">Marathi</div>
                    <div className="heading3">म</div>
                  </div>
                </label>
                <label>
                  <input type="radio" value="kannada" name="language" />
                  <div className="language">
                    <div className="heading1">ಕನ್ನಡ</div>
                    <div className="heading2">Kannada</div>
                    <div className="heading3">ಕೆ</div>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div class="col col-md-4 d-none d-md-block" />
          <div class="col d-none d-md-block">
            <ContinueButton style={{ position: "unset" }} />
          </div>
          <div class="col col-md-4 d-none d-md-block" />
        </div>
        <div className="row">
          <div class="col d-md-none">
            <ContinueButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(LanguageSelector);
