/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { renderRedirect } from "../util";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
      isHome: false,
      currentPath: "",
    };
  }

  componentWillMount() {
    const { history } = this.props;
    this.unsubscribeFromHistory = history.listen(this.handleLocationChange);
    this.handleLocationChange(history.location);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const pathName = nextProps?.location?.pathname || "";
    return { ...prevState, currentPath: pathName };
  }

  componentWillUnmount() {
    if (this.unsubscribeFromHistory) this.unsubscribeFromHistory();
  }

  handleLocationChange = (location) => {
    this.setState({
      isHome:
        location.pathname.toLowerCase() === "/login" ||
        location.pathname.toLowerCase() === "/mobile-otp" ||
        location.pathname.toLowerCase() === "/language" ||
        location.pathname.toLowerCase() === "/verify-email" ||
        location.pathname.toLowerCase() === "/welcome" ||
        location.pathname.toLowerCase() === "/stock"
          ? true
          : false,
    });
  };

  _renderRedirect = (url = "") => {
    renderRedirect(this.props, "/" + url);
  };

  render() {
    const { currentPath } = this.state;

    if (!this.state.isHome) {
      return (
        <div className="appBottomMenu">
          <a
            className={`item ${currentPath === "/home" ? "active" : ""}`}
            onClick={() => this._renderRedirect("home")}
          >
            <div className="col">
              <ion-icon name="home-outline"></ion-icon>
              <strong>HOME</strong>
            </div>
          </a>
          <a
            className={`item ${currentPath === "/portfolio" ? "active" : ""}`}
            onClick={() => this._renderRedirect("portfolio")}
          >
            <div className="col">
              <ion-icon name="briefcase-outline"></ion-icon>
              <strong>PORTFOLIO</strong>
            </div>
          </a>
          {/* <a className={`item ${currentPath === "/home"? "active" : ""}`}  onClick={() => this._renderRedirect("reports")}>
            <div className="col">
              <ion-icon name="list"></ion-icon>
              <strong>REPORTS</strong>
            </div>
          </a> */}
          <a
            className={`item ${currentPath === "/support" ? "active" : ""}`}
            onClick={() => this._renderRedirect("support")}
          >
            <div className="col">
              <ion-icon name="chatbox-ellipses-outline"></ion-icon>
              <strong>SUPPORT</strong>
            </div>
          </a>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}
export default withRouter(Footer);
