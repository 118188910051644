
import { query, mutation } from "../../api";
export async function gerOrderStatus(order_id) {
    return query(
      "fetch_single_transaction",
      {order_id},
      `user_id
      created_ts
      orderId
      orderAmount
      referenceId
      txStatus
      paymentMode
      txMsg
      txTime
      signature`,
      "cashfree"
    );
  }
