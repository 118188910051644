import React, { useEffect, useState } from "react";
import LoadingContent from "../../Components/loading-content";
import { fetchNotificationLatest } from "../Home/api";

export default function TimeLineList() {
  const [notificationList, setNotificationList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);

  const fetchNotifications = async () => {
    let notificationResponse = await fetchNotificationLatest();
    setNotificationList(
      notificationResponse?.data?.fetch_notification_by_cateogory
        ?.notification_info || []
    );
    setShowLoader(false);
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <div className="section" style={{ padding: 0 }}>
      <LoadingContent showLoader={showLoader}>
        <div className="timeline timed ms-1 me-2">
          {notificationList.length > 0 ? (
            <>
              {notificationList.map((notification, index) => (
                <div className="item" key={notification.created_ts}>
                  <span className="time">
                    {new Intl.DateTimeFormat("en-US", {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                    }).format(notification.created_ts)}
                  </span>
                  <div className="dot"></div>
                  <div className="content">
                    <h4 className="title">
                      {decodeURIComponent(notification?.title || "")}
                    </h4>
                    <div className="text">
                      {decodeURIComponent(notification?.text || "")}
                    </div>
                    <div className="text">
                      {decodeURIComponent(notification?.details || "")}
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <span className="ps-1">No Updates</span>
          )}
        </div>
      </LoadingContent>
    </div>
  );
}
