import { query, mutation } from "../../api";

export async function authenitcateUser() {
  return query(
    "authenticate_user",
    {},
    `status, _id, mobile, approval_status, roles`
  );
}

export async function getOTP(mobile) {
  // return mutation("get_otp", { mobile: mobile }, "status", "signin");
  return mutation("get_session_otp", { mobile }, "status session_id", "signin");
}

export async function verifyOTP(mobile, session_id, otp) {
  // return mutation("verify_otp", { mobile: mobile, otp: otp }, `status, token`);
  return mutation(
    "verify_session_otp",
    { mobile: mobile, otp_secret: otp, session_id },
    `status, token`,
    "signin"
  );
}

export async function getCurrentWorkflow() {
  return mutation(
    "get_current_workflow",
    {},
    `status, _id, signup_application_id, workflow_status, current_workflow_step_id, workflow_steps{_id,name}`
  );
}

export async function startSignUpFlow() {
  return mutation("start_signup_application", {}, `status, application_id`);
}

export async function startSignUpWorkFlow() {
  return mutation(
    "start_signup_workflow",
    {},
    ` status
    _id
    signup_application_id
    workflow_status
    current_workflow_step_id
    workflow_steps {
      _id
      name
    }`
  );
}

export async function workFlowStepUpdate(workflow_step_id) {
  return mutation(
    "workflow_step_submit",
    { workflow_step_id: workflow_step_id },
    `status
    message
    current_workflow_step_id`
  );
}

export async function updateSignApplication(reqObj) {
  return mutation(
    "update_signup_application",
    reqObj,
    `_id
    status
    message
    fullName
    lastName
    emailAddress
    gender
    dob
    pan
    martialStatus
    residentialStatus
    permanentAddress
    communicationAddress
    fathersName
    mothersName
    occupationType
    annualIncome
    isPoliticalyExposed`
  );
}

export async function sendEmailSignin(emailId) {
  return mutation("send_email_signin", { to: emailId }, `status`, "email");
}

export async function verifyEmailOTP(emailId, otp) {
  return mutation(
    "verify_email_signin",
    { email_id: emailId, otp: otp },
    `status
    message`,
    "email"
  );
}
