import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";

import { fetchIposList } from "./api";
import { currencyFormat } from "../../util/helper";
import moment from "moment";
import LoadingContent from "../../Components/loading-content";

const UpcomingIposTable = () => {
  const issueType = ["BB"];
  const category = ["IND", "POL"];
  const { isLoading, data, isSuccess } = useQuery("ipos", fetchIposList);
  const [ipos, setIpos] = useState([]);

  useEffect(() => {
    isSuccess && setIpos(removeDuplicates(filterIpos(data.data.eipo_get_open_issues.open_issues)));
  }, [data]);

  const isAfterToday = (date) => moment(date, "DD-MM-YYYY hh:mm:ss").isAfter();

  const openIssuesFilter = (array) => array.filter((item) => isAfterToday(item.opendatetime));

  const filterIpos = (data) =>
    openIssuesFilter(data).filter(
      (item) => issueType.includes(item.issuetype) && category.includes(item.category)
    );

  const removeDuplicates = (array) =>
    array.reduce((acc, current) => {
      const x = acc.find((item) => item.symbol === current.symbol);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

  return (
    <LoadingContent showLoader={isLoading}>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Stock</th>
              <th scope="col">Open date</th>
              <th scope="col">Closing date</th>
              <th scope="col">Floor price</th>
              <th scope="col">Ceiling price</th>
              <th scope="col">Min. qty</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(ipos).map((key) => (
              <tr key={key} className="borderless-last-tr">
                <th scope="row">{ipos[key].name}</th>
                <td>{ipos[key].opendatetime.substring(0, 10)}</td>
                <td>{ipos[key].closedatetime.substring(0, 10)}</td>
                <td>{currencyFormat(ipos[key].floorprice)}</td>
                <td>{currencyFormat(ipos[key].ceilingprice)}</td>
                <td>{ipos[key].minbidqty}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </LoadingContent>
  );
};

export default UpcomingIposTable;
