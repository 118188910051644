/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { renderRedirect } from "../util";
import $ from "jquery";
import MenuSideBar from "./menu-sidebar";
import { getNotifications } from "../Pages/Notification/api";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
      isHome: false,
      notificationCount: 0,
    };
  }

  callOneTime = 1;

  componentWillMount() {
    const { history } = this.props;
    this.unsubscribeFromHistory = history.listen(this.handleLocationChange);
    this.handleLocationChange(history.location);
  }

  componentDidMount() {
    this.getUnreadNotificationCount();
    if (this.callOneTime === 1) {
      this.interval = setInterval(async () => {
        this.getUnreadNotificationCount();
      }, 10000);
      this.callOneTime = 2;
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    if (this.unsubscribeFromHistory) this.unsubscribeFromHistory();
  }

  async getUnreadNotificationCount() {
    // setInterval(async () => {
    const reponse = await getNotifications();
    const notificationsList =
      reponse?.data?.fetch_notifications?.notification_info;

    let count = 0;

    (notificationsList || []).forEach(({ status }) => {
      if (status === "unread") count++;
    });

    // update notification count if count is different
    if (this.state.notificationCount !== count) {
      this.setState({
        notificationCount: count,
      });
    }
    // }, 10000);
  }

  handleLocationChange = (location) => {
    this.setState({
      isHome: [
        "/login",
        "/mobile-otp",
        "/language",
        "/verify-email",
        "/welcome",
      ].includes(location.pathname.toLowerCase())
        ? true
        : false,
    });
  };

  _renderRedirect = (url = "") => {
    renderRedirect(this.props, "/" + url);
  };

  render() {
    if (!this.state.isHome) {
      return (
        <div>
          <div className="appHeader bg-primary text-light">
            <div className="left">
              {this.props.showMenuOutLine && (
                <a
                  href="#"
                  className="headerButton"
                  data-bs-toggle="modal"
                  data-bs-target="#sidebarPanel"
                >
                  <ion-icon name="menu-outline"></ion-icon>
                </a>
              )}
            </div>
            {this.props.showMenuOutLine && (
              <div className="pageTitle">
                <img
                  src="../assets/img/trdr-horizontal.png"
                  alt="logo"
                  className="logo"
                />
              </div>
            )}
            <div className="right">
              <a
                href="#"
                className="headerButton"
                onClick={() => this._renderRedirect("notification")}
              >
                <ion-icon
                  className="icon"
                  name="notifications-outline"
                ></ion-icon>
                <span className="badge badge-danger">
                  {this.state.notificationCount}
                </span>
              </a>
            </div>
          </div>

          <div
            className="modal fade panelbox panelbox-left"
            id="sidebarPanel"
            tabIndex="-1"
            role="dialog"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body p-0">
                  <MenuSideBar showCloseIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="appHeader bg-primary text-light">
            <img
              src="../assets/img/trdr-horizontal.png"
              alt="logo"
              className="logo"
              width="150px"
            />
          </div>
          <div></div>
        </div>
      );
    }
  }
}
export default withRouter(Header);
