import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  async componentDidMount() {
  }
  render() {
    return (
        <div id="appCapsule">
        <h1>About</h1>
        </div>
    );
  }
}
export default withRouter(About);
