import React from "react";

import { issueTitles } from "../Constants";

const DetailsTable = ({ data, drhp }) => {
  return (
    <div className="table-responsive">
      <table className="table">
        {data && (
          <>
            <thead>
              <tr>
                <th scope="col">{data.symbol}</th>
                <th scope="col" className="text-end">
                  <a
                    target="_blank"
                    href={drhp.drhp_link || ""}
                    style={{ color: "#067ebf" }}>
                    DRHP
                    <ion-icon name="open-outline"></ion-icon>
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>
              {issueTitles.map((issue) =>
                issue.key === "issueprice" ? (
                  <tr key={issue.key}>
                    <th scope="row">{issue.label}</th>
                    <td className="text-end text-primary">
                      {`${data.floorprice} - ${data.ceilingprice}`}
                    </td>
                  </tr>
                ) : (
                  <tr key={issue.key}>
                    <th scope="row">{issue.label}</th>
                    <td className="text-end text-primary">{data[issue.key]}</td>
                  </tr>
                )
              )}
            </tbody>
          </>
        )}
      </table>
    </div>
  );
};

export default DetailsTable;
