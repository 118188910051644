import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import LoadingContent from "../../Components/loading-content";
import { currencyFormat } from "../../util/helper";
import { getReport } from "./api";

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      netpositionpnl: "",
      clientScripts: [],
      transaction_charges: null,
      showLoader: true,
    };
  }
  async componentDidMount() {
    let reportReponse = await getReport();

    this.setState({
      clientScripts: reportReponse?.data?.get_report?.client_scrips || [],
      netpositionpnl:
        reportReponse?.data?.get_report?.total_details?.profit_loss || 0,
      transaction_charges:
        reportReponse?.data?.get_report?.transaction_charges || null,
      showLoader: false,
    });
  }
  render() {
    const displayProfitLossValue = (amount) => {
      return Number(amount) < 0 ? (
        <span style={{ color: "red" }}>{Number(amount * -1).toFixed(2)}</span>
      ) : (
        <span style={{ color: "blue" }}>{Number(amount).toFixed(2)}</span>
      );
    };

    const TotalPL = () => {
      const netValue = Number(this.state.netpositionpnl || 0);
      const displayValue = netValue < 0 ? netValue * -1 : netValue;
      return (
        <div>
          <span>Total P&L:</span>
          <span
            style={{ color: netValue < 0 ? "red" : "green" }}
          >{` ${currencyFormat(displayValue)}`}</span>
        </div>
      );
    };
    return (
      <div id="appCapsule">
        <div
          className="modal fade action-sheet"
          id="actionSheetContent"
          tabindex="-1"
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="row">
              <div className="col col-md-3 d-none d-md-block" />
              <div className="col">
                <div
                  className="modal-content"
                  style={{ borderRadius: "10px 10px 0px 0px" }}
                >
                  <div className="modal-body">
                    <div className="section mt-2">
                      <div className="row">
                        <div className="col-6 text-start">
                          <div className="section-title">Statutory Charges</div>
                        </div>
                        {/* <div className="col-6 text-end">
                          <ion-icon
                            name="close-circle"
                            className="h2 pointer"
                            data-bs-toggle="modal"
                            data-bs-target="#actionSheetContent"
                            style={{ marginTop: 5 }}
                          ></ion-icon>
                        </div> */}
                      </div>
                      {this.state.transaction_charges != null ? (
                        <div>
                          <div>
                            {/* <h2 className="accordion-header">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                              >
                                Total Transaction Charges: 100
                              </button>
                            </h2> */}

                            <ul className="listview simple-listview no-space mt-3">
                              {this.state.transaction_charges?.charges !==
                                null && (
                                <li>
                                  <h3>Total Charges</h3>
                                  <h3>
                                    {currencyFormat(
                                      this.state.transaction_charges?.charges
                                    )}
                                  </h3>
                                </li>
                              )}

                              {this.state.transaction_charges?.tran_charges !==
                                null && (
                                <li>
                                  <span>Transaction Charges</span>
                                  <strong>
                                    {currencyFormat(
                                      this.state.transaction_charges
                                        ?.tran_charges
                                    )}
                                  </strong>
                                </li>
                              )}
                              {this.state.transaction_charges?.sebi_charges !==
                                null && (
                                <li>
                                  <span>SEBI Charges</span>
                                  <strong>
                                    {currencyFormat(
                                      this.state.transaction_charges
                                        ?.sebi_charges
                                    )}
                                  </strong>
                                </li>
                              )}
                              {this.state.transaction_charges?.stt !== null && (
                                <li>
                                  <span>STT</span>
                                  <strong>
                                    {currencyFormat(
                                      this.state.transaction_charges?.stt
                                    )}
                                  </strong>
                                </li>
                              )}

                              {this.state.transaction_charges
                                ?.stamp_duty_charges !== null && (
                                <li>
                                  <span>Stamp Duty Charges</span>
                                  <strong>
                                    {currencyFormat(
                                      this.state.transaction_charges
                                        ?.stamp_duty_charges
                                    )}
                                  </strong>
                                </li>
                              )}

                              {this.state.transaction_charges?.central_gst !==
                                null && (
                                <li>
                                  <span>Central GST</span>
                                  <strong>
                                    {currencyFormat(
                                      this.state.transaction_charges
                                        ?.central_gst
                                    )}
                                  </strong>
                                </li>
                              )}

                              {this.state.transaction_charges
                                ?.central_gst_on_brokerage !== null && (
                                <li>
                                  <span>Central GST Brokerage</span>
                                  <strong>
                                    {currencyFormat(
                                      this.state.transaction_charges
                                        ?.central_gst_on_brokerage
                                    )}
                                  </strong>
                                </li>
                              )}

                              {this.state.transaction_charges?.state_gst !==
                                null && (
                                <li>
                                  <span>State GST</span>
                                  <strong>
                                    {currencyFormat(
                                      this.state.transaction_charges?.state_gst
                                    )}
                                  </strong>
                                </li>
                              )}

                              {this.state.transaction_charges
                                ?.central_gst_on_tran_charges !== null && (
                                <li>
                                  <span>Central GST on Transaction</span>
                                  <strong>
                                    {currencyFormat(
                                      this.state.transaction_charges
                                        ?.central_gst_on_tran_charges
                                    )}
                                  </strong>
                                </li>
                              )}

                              {this.state.transaction_charges
                                ?.state_gst_on_brokerage !== null && (
                                <li>
                                  <span>State GST Brokerage</span>
                                  <strong>
                                    {currencyFormat(
                                      this.state.transaction_charges
                                        ?.state_gst_on_brokerage
                                    )}
                                  </strong>
                                </li>
                              )}
                              {this.state.transaction_charges
                                ?.state_gst_on_tran_charges !== null && (
                                <li>
                                  <span> State GST on Transaction</span>
                                  <strong>
                                    {currencyFormat(
                                      this.state.transaction_charges
                                        ?.state_gst_on_tran_charges
                                    )}
                                  </strong>
                                </li>
                              )}
                              {this.state.transaction_charges
                                ?.integrated_gst_on_brokerage !== null && (
                                <li>
                                  <span> Integerated GST on Brokerage</span>
                                  <strong>
                                    {currencyFormat(
                                      this.state.transaction_charges
                                        ?.integrated_gst_on_brokerage
                                    )}
                                  </strong>
                                </li>
                              )}
                              {this.state.transaction_charges
                                ?.integrated_gst_on_tran_charges !== null && (
                                <li>
                                  <span> Integerated GST on Transaction</span>
                                  <strong>
                                    {currencyFormat(
                                      this.state.transaction_charges
                                        ?.integrated_gst_on_tran_charges
                                    )}
                                  </strong>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <div className="section mb-2 text-center">
                          <div className="section-title">
                            {`No charges to show`}
                          </div>
                        </div>
                      )}
                      <div className="mt-2 mb-2 text-center">
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-lg"
                          data-bs-toggle="modal"
                          data-bs-target="#actionSheetContent"
                          style={{ width: "50%" }}
                        >
                          CLOSE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-md-3 d-none d-md-block" />
            </div>
          </div>
        </div>
        <div className="section mt-2">
          <LoadingContent showLoader={this.state.showLoader}>
            <div className="row">
              <div className="col col-12 text-start">
                <div className="section-title">Net Position Report</div>
              </div>
              <div className="col col-12 col-md-6 text-start">
                {/* <div>{`Total P&L: ${currencyFormat(
                  this.state.netpositionpnl || 0
                )}`}</div> */}
                <TotalPL />
              </div>
              <div className="col col-12 col-md-6 text-end">
                <div
                  className="pointer"
                  data-bs-toggle="modal"
                  data-bs-target="#actionSheetContent"
                  style={{ padding: "6px 0px" }}
                >
                  <span className="me-2">Statutory Charges </span>
                  <ion-icon
                    className="right-icon mt-05"
                    name="filter-outline"
                  />
                </div>
              </div>
            </div>
            {this.state.clientScripts != null &&
            this.state.clientScripts.length !== 0 &&
            !this.state.showLoader ? (
              <div className="card p-1">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Scrip Name</th>
                        <th scope="col" className="text-end">
                          Buy Qty.
                        </th>
                        <th scope="col" className="text-end">
                          Buy Value
                        </th>
                        <th scope="col" className="text-end">
                          Buy Avg.
                        </th>
                        <th scope="col" className="text-end">
                          Sell Qty.
                        </th>
                        <th scope="col" className="text-end">
                          Sell Value
                        </th>
                        <th scope="col" className="text-end">
                          Sell Avg
                        </th>
                        <th scope="col" className="text-end">
                          Net Qty.
                        </th>
                        <th scope="col" className="text-end">
                          Net Value
                        </th>
                        <th scope="col" className="text-end">
                          Net Avg.
                        </th>
                        <th scope="col" className="text-end">
                          Market Rate
                        </th>
                        <th scope="col" className="text-end">
                          Market Value
                        </th>
                        <th scope="col" className="text-end">
                          P&L
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.clientScripts.map((row, index) => (
                        <tr
                          key={`${row?.scrip_name}-${index}`}
                          className="borderless-last-tr"
                        >
                          <th scope="row">{row?.scrip_name || ""}</th>
                          <td className="text-end">
                            {Number(row?.buy_qty || 0).toFixed(2)}
                          </td>
                          <td className="text-end">
                            {displayProfitLossValue(row?.buy_value || 0)}
                          </td>
                          <td className="text-end">
                            {Number(row?.buy_avg || 0).toFixed(2)}
                          </td>
                          <td className="text-end">
                            {Number(row?.sell_qty || 0).toFixed(2)}
                          </td>
                          <td className="text-end">
                            {Number(row?.sell_value || 0).toFixed(2)}
                          </td>
                          <td className="text-end">
                            {Number(row?.sell_avg || 0).toFixed(2)}
                          </td>
                          <td className="text-end">
                            {Number(row?.net_qty || 0).toFixed(2)}
                          </td>
                          <td className="text-end">
                            {displayProfitLossValue(row?.net_value || 0)}
                          </td>
                          <td className="text-end">
                            {Number(row?.net_avg || 0).toFixed(2)}
                          </td>
                          <td className="text-end">
                            {Number(row?.market_rate || 0).toFixed(2)}
                          </td>
                          <td className="text-end">
                            {displayProfitLossValue(row?.market_value || 0)}
                          </td>
                          <td className="text-end">
                            {displayProfitLossValue(row?.profit_loss || 0)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="section mt-3 text-center">
                <div className="section-title">
                  {`No net position report available for your account :(`}
                </div>
              </div>
            )}
          </LoadingContent>
        </div>
      </div>
    );
  }
}
export default withRouter(Reports);
