import React, { useEffect, useState } from "react";
import LoadingContent from "../../Components/loading-content";
import { currencyFormat } from "../../util/helper";
import { getHomePageData } from "../Home/api";

export default function LedgerBalance(props) {
  const [ledgerBalance, setLedgerBalance] = useState(0);
  const [ledgerBalanceType, setLedgerBalanceType] = useState("CR");
  const [showLoader, setShowLoader] = useState(true);

  const fetchLedgerBalance = async () => {
    const response = await getHomePageData();
    const responseData = response?.data?.get_home_page_data;
    setLedgerBalance(responseData?.ledger_balance || 0);
    setLedgerBalanceType(responseData?.ledger_balance_type);
    setShowLoader(false);
  };

  useEffect(() => {
    fetchLedgerBalance();
  }, []);

  return (
    <div className="section">
      <LoadingContent showLoader={showLoader}>
        <div className="stat-box" style={{ padding: "22px 16px" }}>
          <div className="title">Cash Balance</div>
          <div
            className={
              ledgerBalanceType === "CR"
                ? "value text-success"
                : "value text-danger"
            }
          >
            {`${currencyFormat(ledgerBalance)} ${ledgerBalanceType}`}
          </div>

          <span className="text-10">Your current balance</span>
        </div>
      </LoadingContent>
    </div>
  );
}
