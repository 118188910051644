import { mutation } from "../../api";
export async function add_nominee_details(pin, nominees) {
    return mutation(
        "update_signup_application",
        {
            nominee_form_pincode: pin,
            nominees: nominees,
            nominee_consent: true,
        },
        `
    _id
    status
    message
    fullName
    nominee_form_pincode
    nominee_consent
    nominees {
      nominee_number
      name
      percentage_of_share
      relationship
      address
      mobile
      email
      id_type
      id_number
      dob_if_minor
      minor_guardian_info {
        guardian_name
        guardian_address
        guardian_mobile
        guardian_email
        guardian_relationship
        guardian_id_type
        guardian_id_number
      }
    }
    `
    );
}

export async function getProfileData() {
    return mutation(
        "fetch_signup_application",
        {},
        `
    nominee_form_pincode
    permanentAddress
    nominee_consent
    nominees {
      nominee_number
      name
      percentage_of_share
      relationship
      address
      mobile
      email
      id_type
      id_number
      dob_if_minor
      minor_guardian_info {
        guardian_name
        guardian_address
        guardian_mobile
        guardian_email
        guardian_relationship
        guardian_id_type
        guardian_id_number
      }
    }`
    );
}

export async function updateConsentToFalse() {
    return mutation(
        "update_signup_application",
        { nominee_consent: false },
        `
      _id
      message
      nominee_consent`
    );
}
