import {
  query,
  mutation
} from "../../api";

export async function getDepositTransactions() {
  return query(
    "fetch_transactions", {},
    `transaction_info {
      user_id
      created_ts
      orderId
      orderAmount
      referenceId
      txStatus
      paymentMode
      txMsg
      txTime
      signature
    }`,
    "cashfree"
  );
}


export async function getCashFreeOrder(fields) {
  return mutation(
    "payment_gateway", fields,
    `status
        accountNumber
        html
        appId
        customerEmail
        customerName
        customerPhone
        ifsc
        notifyUrl
        orderAmount
        orderCurrency
        orderId
        orderNote
        paymentCode
        paymentOption
        returnUrl
        signature
        upi_vpa`,
    "cashfree"
  );
}

export async function verifyUPI(upi_id) {
  return query(
    "validate_upi_id", {
    upi_id
  },
    `status
    name
    vpa`,
    "cashfree"
  );
}

export async function submit_withdraw_request(withdraw_amount) {
  return mutation("submit_withdraw_request", {withdraw_amount},
    `status
    message`,
    "signin")
}

export async function get_withdraw_requests() {
  return query(
    "get_withdraw_requests", {},
    `status
    withdraw_requests {
    ucc
    withdraw_amount
    withdraw_status
    modified_ts
    txTime
    _id }`,
    "signin"
  )
}

export async function cancel_withdraw_request(transaction_id) {
  return mutation(
    "cancel_withdraw_request", {transaction_id},
    `message
    transaction_id`,
    "signin"
  )
}

// export async function cashFreeAddMoney(orderDetails) {
//   let appId = configApp.CASHFREE_APP_ID;
//   let secretKey = configApp.CASHFREE_SECRET_KEY;
//   try {
//     var postData = {
//       accountNumber:orderDetails.accountNumber,
//       ifsc:orderDetails.ifsc,
//       appId,
//       orderId: orderDetails.orderId,
//       orderAmount: orderDetails.orderAmount,
//       orderCurrency: orderDetails.orderCurrency,
//       orderNote: orderDetails.orderNote,
//       customerName: orderDetails.customerName,
//       customerEmail: orderDetails.customerEmail,
//       customerPhone: orderDetails.customerPhone,
//       returnUrl: orderDetails.returnUrl,
//       notifyUrl: orderDetails.notifyUrl,
//       paymentOption: orderDetails.paymentOption,
//     };
//     orderDetails.paymentOption === "upi"?postData.upi_vpa = orderDetails.upi_vpa: postData.paymentCode = orderDetails.paymentCode;
//     let sortedPostData= Object.fromEntries(Object.entries(postData).sort());
 
//     console.log("------sortedPostData-------");
//     console.log(sortedPostData);
//     let formData = new FormData();
//     Object.keys(postData).map((key) => formData.append(key, sortedPostData[key]));
//     formData.append("signature",orderDetails.signature);
//     var config = {
//       method: "post",
//       url: configApp.REACT_APP_CASHFREE_URL,
//       headers: { 'content-type': 'multipart/form-data' },
//       data: formData,
//     };
//     let response = await axios(config);
//     let html = response.data;
//     console.log("REPONSE FROM CashFree"+html);
//     return html;
//   } catch (err) {
//     console.error(err);
//     throw err;
//   }
// }