import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import LoadingContent from "../../Components/loading-content";
import { getNotifications, updateNotificationStatus } from "./api";
import TimeLineList from "./TimeLineList";

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationsList: [],
      showLoader: true,
    };
    this.notificationClicked = this.notificationClicked.bind(this);
  }
  async notificationClicked(event, index) {
    //update notification status
    console.log("Clicked Notification No-" + index);
    console.log(this.state.notificationsList[index]);
    let response = await updateNotificationStatus(
      this.state.notificationsList[index]._id
    );
    let notificationList = this.state.notificationsList;
    notificationList[index].status = "read";
    this.setState({ notificationsList: notificationList });
  }
  async componentDidMount() {
    //call function to get list of Notifications
    let reponse = await getNotifications();
    let notificationsList = reponse.data.fetch_notifications.notification_info;
    this.setState({ notificationsList: notificationsList, showLoader: false });
  }
  render() {
    return (
      <div id="appCapsule">
        <div class="section full">
          <div class="section mt-3 text-left">
            <div class="section-title">Notifications</div>
          </div>

          <ul className="nav nav-tabs lined" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                data-bs-toggle="tab"
                href="#notificationList"
                role="tab"
              >
                Notification
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                href="#timeLineList"
                role="tab"
              >
                Timeline
              </a>
            </li>
          </ul>
          <div className="tab-content mt-2">
            <div
              className="tab-pane fade show active"
              id="notificationList"
              role="tabpanel"
            >
              <LoadingContent showLoader={this.state.showLoader}>
                <ul class="listview image-listview flush">
                  {this.state.notificationsList.map((notification, index) => (
                    <li
                      class={
                        notification.status === "unread" ? "active" : "inactive"
                      }
                      key={index}
                      onClick={(e) => this.notificationClicked(e, index)}
                    >
                      <div class="item">
                        <div class="icon-box bg-primary">
                          <ion-icon name="arrow-down-outline"></ion-icon>
                        </div>
                        <div class="in">
                          <div>
                            <div class="mb-05">
                              <strong>
                                {decodeURIComponent(notification?.title || "")}
                              </strong>
                            </div>
                            <div class="text-small mb-05">
                              <p>
                                {decodeURIComponent(notification?.text || "")}
                              </p>
                              <b>
                                {decodeURIComponent(
                                  notification?.details || ""
                                )}
                              </b>
                            </div>
                            <div class="text-xsmall">
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                              }).format(notification.created_ts)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                {this.state.notificationsList.length === 0 && (
                  <div class="section mt-3 text-center">
                    <div class="section-title">There are no notifications.</div>
                  </div>
                )}
              </LoadingContent>
            </div>
            <div className="tab-pane fade" id="timeLineList" role="tabpanel">
              <TimeLineList />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Notification);
