import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import LoadingContent from "../../Components/loading-content";
import { getProfileData, getMemberData } from "./api";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showLoader: true };
  }
  async componentDidMount() {
    let data = await getProfileData();
    let profileData = data.data.fetch_signup_application;
    data = await getMemberData();
    console.log(data);
    let memberData = data.data.get_member_data;

    this.setState({
        ...profileData,
        ...memberData,
    });
    let nominees = this.handleAddNominee()
    this.setState({ nomineeBtns: nominees, showLoader: false });
  }

  handleAddNominee=()=>{
    const details = this.state
    if(details?.nominee_consent !== false){
      if(details?.nominees && details?.nominees.length <= 3){
        if(details?.nominees.length === 3){
          return " "
        }
        let sum = details?.nominees.reduce((accumulator, curValue) =>{
          return accumulator + parseInt(curValue.percentage_of_share)    
        }, 0)
        return 100 - sum !== 0 ? true : " "
      }else{
        return true
      }
    }else{
      return false
    }
    
  }

  render() {
    const details = this.state;

    return (
      <div id="appCapsule">
        <div className="section">
          <div className="listed-detail mt-3">
            <h3 className="section-title">Profile Details</h3>
          </div>

          <LoadingContent showLoader={this.state.showLoader}>
            <div className="accordion card">
              <ul className="listview simple-listview no-space profile-ul">
                <li>
                  <span>Name</span>
                  <strong>{details?.fullName}</strong>
                </li>
                <li>
                  <span>PAN</span>
                  <strong>{details?.pan}</strong>
                </li>
                <li>
                  <span>TRDR ID</span>
                  <strong>{details?.trdr_id}</strong>
                </li>
                <li>
                  <span>UCC / Client Code</span>
                  <strong>{details?.ucc}</strong>
                </li>
              </ul>
            </div>
          </LoadingContent>

          <div className="row listed-detail mt-3">
            <div className="col col-8">
              <h3 className="section-title">Nominee Details</h3>
            </div>
            <LoadingContent showLoader={this.state.showLoader}>
            <div className="accordion card">
              <ul className="listview simple-listview no-space profile-ul">
                <li>
                <span>{details?.nomineeBtns ? <>
                  <button type="button" class="btn btn-dark me-1 mb-1" onClick={() => window.open("/view-nominee", "_self")} disabled={!details?.nominees}>
                    View Nominees
                  </button>
                  {details?.nomineeBtns !== " " ?<button type="button" class="btn btn-dark me-1 mb-1" onClick={() => window.open("/add-nominee", "_self")}>
                    Add Nominees
                  </button>:null}
                  </> : "You had provided nominee consent as 'NO'. To change your consent, please contact care@trdr.money"}</span>
                </li>
              </ul>
            </div>
          </LoadingContent>
            {/* {details?.nomineeBtns ?<div className="col col-4 text-end">
              {details?.nomineeBtns === " " ?<button type="button" class="btn btn-dark me-1 mb-1" onClick={() => window.open("/view-nominee", "_self")}>
                View Nominees
              </button> : <button type="button" class="btn btn-dark me-1 mb-1" onClick={() => window.open("/add-nominee", "_self")}>
                Add Nominees
              </button>}
            </div>:null} */}
          </div>
          <div className="listed-detail mt-3">
            <h3 className="section-title">Demat Details</h3>
          </div>
          <LoadingContent showLoader={this.state.showLoader}>
            <div className="accordion card">
              <ul className="listview simple-listview no-space profile-ul">
                <li>
                  <span>Demat ID / BO ID</span>
                  <strong>{details?.demat_acc_number}</strong>
                </li>

                <li>
                  <span>DP ID</span>
                  <strong>{details?.dp_id}</strong>
                </li>
                <li>
                  <span>Depository participant</span>
                  <strong>{details?.depository_participant}</strong>
                </li>
                <li>
                  <span>Depository name</span>

                  <strong>{details?.depository_name}</strong>
                </li>
              </ul>
            </div>
          </LoadingContent>
          <div className="listed-detail mt-3">
            <h3 className="section-title">Contact Details</h3>
          </div>
          <LoadingContent showLoader={this.state.showLoader}>
            <div className="accordion card">
              <ul className="listview simple-listview no-space profile-ul">
                <li>
                  <span>Mobile number</span>
                  <strong>{details?.mobile}</strong>
                </li>
                <li>
                  <span>Email</span>
                  <strong>{details?.emailAddress}</strong>
                </li>
                <li>
                  <span>Contact Address</span>
                  <strong className="text-right">
                    {decodeURIComponent(details?.communicationAddress)}
                  </strong>
                </li>
              </ul>
            </div>
          </LoadingContent>
          <div className="listed-detail mt-3">
            <h3 className="section-title">Bank Details</h3>
          </div>
          <LoadingContent showLoader={this.state.showLoader}>
            <div className="accordion card">
              <ul className="listview simple-listview no-space profile-ul">
                <li>
                  <span>Bank Name</span>
                  <strong>{details?.bank_name}</strong>
                </li>
                <li>
                  <span>Account Number</span>
                  <strong>{details?.account_number}</strong>
                </li>
                <li>
                  <span>IFSC Code</span>
                  <strong>{details?.ifsc}</strong>
                </li>
              </ul>
            </div>
          </LoadingContent>
        </div>
      </div>
    );
  }
}
export default withRouter(Profile);
