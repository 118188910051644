import React from "react";
import { currencyFormat } from "../../util/helper";
import { cancel_withdraw_request } from "./api";

import Buttons from "./Button";

function WithdrawalTransactionsTable({ transactionsList, ...props }) {
  const confirmCancelWR = (data) =>
    window.confirm("Are you sure you want to cancel this withdraw request ?")
    && cancelWithDrawRequest(data);

  function cancelWithDrawRequest(data) {
    cancel_withdraw_request(data).then((response) => {
      if (response.errors && response.errors[0].message) throw response.errors[0];
      window.alert(`Withdraw Request ID:${response?.data?.cancel_withdraw_request?.transaction_id} has been cancelled. Click OK to reload page.`)
      window.location.reload();
    }).catch((error) => {
      console.log(error.message)
      window.alert(`something went wrong: ${error.message}. Click OK to reload page.`);
      window.location.reload();
    });
  }

  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Withdrawal Initiated On</th>
            <th scope="col">Status</th>
            <th scope="col">Reference ID</th>
            <th scope="col" className="text-end">{`Amount (₹)`}</th>
            <th scope="col" className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {transactionsList.map(
            (
              {
                txTime,
                withdraw_amount,
                withdraw_status,
                _id,
              },
              index
            ) => (
              <>
                {_id && (
                  <tr key={_id} className="borderless-last-tr">
                    <th scope="row">{txTime}</th>
                    <td>{withdraw_status}</td>
                    <td>{_id}</td>
                    <td className="text-end text-primary">
                      {currencyFormat(withdraw_amount)}
                    </td>
                    <td className="text-center text-primary">
                      {
                        withdraw_status === "pending" ?
                          <Buttons cancelWR={() => confirmCancelWR(_id)} />
                          : null
                      }
                    </td>
                  </tr>
                )}
              </>
            )
          )}
        </tbody>
      </table>
    </div>
  );
}

export default WithdrawalTransactionsTable;
