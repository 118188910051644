import * as React from "react";
import { spinnerService } from "./spinner.service";

export class Spinner extends React.Component {
  spinnerService = spinnerService;

  set show(show) {
    this.setState({ show });
  }

  set text(text) {
    this.setState({ text });
  }

  get show() {
    return this.state.show;
  }

  get text() {
    return this.state.text;
  }

  get name() {
    return this.props.name;
  }

  get group() {
    return this.props.group;
  }

  componentWillMount() {
    this.setState({
      show: this.props.hasOwnProperty("show") ? this.props.show : false,
    });

    if (this.props.hasOwnProperty("spinnerService")) {
      this.spinnerService = this.props.spinnerService;
    }

    this.spinnerService._register(this);
  }

  componentWillUnmount() {
    this.spinnerService._unregister(this);
  }

  render() {
    const spinnerStyle = {
      color: "white",
      backgroundColor: "rgb(0 0 0 / 65%)",
      padding: 10,
      display: "flex",
      position: "fixed",
      left: 0,
      top: 0,
      zIndex: 9999,
      width: "100%",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
    };
    if (this.state.show) {
      const { text } = this.state;
      return (
        <div className="spinner" style={spinnerStyle}>
          {text && <div style={{ marginTop: "-50px" }}>{text}</div>}
          {this.props.children}
        </div>
      );
    }
    return "";
  }
}
