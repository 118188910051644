import React, { useState } from "react";

import MyIposTable from "./MyIposTable";
import OpenIposTable from "./OpenIposTable";
import UpcomingIposTable from "./UpcomingIposTable";
import FullScreenModal from "./FullScreenModal";
import UpdateIpo from "./actionSheet/UpdateIpo";
import CreateIpo from "./actionSheet/CreateIpo";
import Notification from "./Notification";

const IpoComponent = () => {
  const [actionSheet, setActionSheet] = useState({
    mode: "",
    data: null,
  });

  return (
    <div id="appCapsule">
      {/* <Notification /> */}
      <FullScreenModal>
        {actionSheet.mode === "new" ? (
          <CreateIpo setActionSheet={setActionSheet} data={actionSheet.data} title="Create IPO" />
        ) : (
          actionSheet.mode === "update" && (
            <UpdateIpo setActionSheet={setActionSheet} data={actionSheet.data} title="Update IPO" />
          )
        )}
      </FullScreenModal>
      <div className="section mt-2">
        <div className="section-title">Booked IPOs</div>
        <div className="card">
          <MyIposTable
            deleteIpoOrder={(data) => console.log(data)}
            setActionSheet={(data) => setActionSheet({ mode: "update", data: data })}
          />
        </div>
      </div>
      <div className="section mt-2">
        <div className="section-title">Open IPOs</div>
        <div className="card">
          <OpenIposTable setActionSheet={(data) => setActionSheet({ mode: "new", data: data })} />
        </div>
      </div>

      <div className="section mt-2">
        <div className="section-title">Upcoming IPOs</div>
        <div className="card">
          <UpcomingIposTable />
        </div>
      </div>
    </div>
  );
};

export default IpoComponent;
