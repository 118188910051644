import React from "react";

function OuterLogo() {
  return (
    <div className="text-center pb-3">
      <img
        src="../assets/img/trdr-horizontal.png"
        alt="logo"
        className="logo"
      />
    </div>
  );
}

export default OuterLogo;
