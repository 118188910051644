import React from "react";
import { currencyFormat } from "../../util/helper";

export default function AutoPilotCard({ stockDataList = [], ...props }) {
  return (
    <div className="wallet-card" style={{ padding: 0, border: "1.5px solid" }}>
      <div className="card-body" style={{ padding: 10 }}>
        {/* <h5 className="card-title">{`Stock ${objKey}`}</h5>
         */}
        <div className="card-title chip chip-outline">
          <span className="chip-label">AUTOPILOT</span>
        </div>
        <div className="table-responsive" style={{ height: 285 }}>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" style={{ padding: "10px 3px" }}>
                    Stocks
                  </th>
                  <th scope="col" style={{ padding: "10px 3px" }}>
                    Price
                  </th>
                  <th scope="col" style={{ padding: "10px 3px" }}>
                    Range
                  </th>
                </tr>
              </thead>
              <tbody>
                {stockDataList.map(
                  ({ name, current_price, max_price, min_price }) => (
                    <tr
                      key={`stock-table-${name}`}
                      className="borderless-last-tr"
                    >
                      <td style={{ padding: "10px 3px" }}>
                        {decodeURIComponent(name)}
                      </td>
                      <td
                        style={{ padding: "10px 3px" }}
                        //   className="text-end text-primary"
                      >
                        {currencyFormat(current_price)}
                      </td>
                      <td style={{ padding: "10px 3px" }}>{`${currencyFormat(
                        max_price
                      )} - ${currencyFormat(min_price)}`}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* {stockObj.map((objKey) => {
                const stockDataList = this.state.stocksList?.[objKey] || [];
                console.log("stockDataList", stockDataList);
                return (
                  <div className="section full mb-3" key={`stock-${objKey}`}>
                    <div className="section-title">{`Stock ${objKey}`}</div>

                    <div className="carousel-multiple splide">
                      <div className="splide__track">
                        <ul className="splide__list">
                          {stockDataList.map(
                            ({ name, current_price, max_price, min_price }) => (
                              <li
                                className="splide__slide"
                                key={`stock-${objKey}-${name}`}
                              >
                                <div className="card">
                                  <div
                                    className="card-body"
                                    style={{ padding: 10 }}
                                  >
                                    <h5
                                      className="card-title"
                                      style={{ fontSize: 13 }}
                                    >
                                      {name}
                                    </h5>
                                    <p className="card-text">
                                      <b>{currencyFormat(current_price)}</b>
                                    </p>
                                    <p className="card-text">{`${currencyFormat(
                                      min_price
                                    )} - ${currencyFormat(max_price)}`}</p>
                                  </div>
                                </div>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                );
              })} */}
    </div>
  );
}
