import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
const configApp = require('../config');


class StaticContent extends Component {
 
  constructor(props) {
    super(props);
    this.state = {
      url: "",
      isHome: false
    };
  }

  componentWillMount() {
    const { history } = this.props;
    this.unsubscribeFromHistory = history.listen(this.handleLocationChange);
    this.handleLocationChange(history.location);
  }

  componentWillUnmount() {
    if (this.unsubscribeFromHistory) this.unsubscribeFromHistory();
  }

  handleLocationChange = (location) => {
    this.setState({isHome:  location.pathname.toLowerCase() == "/signup" || location.pathname.toLowerCase() == "/signup/" ? true : false});
  }

    render() {
       if(this.state.isHome){
        return (
          <div className="col-md-6 col-lg-6 col-xl-6">
          <div className="row d-flex h-100 text-center text-md-left">
            <div className="col-lg-10 col-xl-10 mt-auto">  
              <h1 className="text-12 font-weight-200 mb-5  text-center"> <br/>Welcome to TRDR!</h1>
              <div className="text-center">
              <img className="text-center" src="../images/trdr-robo-standing.png" width="150px"  alt="TRDR"/>
              </div>
              <p className=" text-center">
              Let us prepare you before you start the sign up process. </p>
              <p className=" text-center">
              This will be the only manual work you will ever be doing with regards to your TRDR account. We promise you that.
              </p>
            </div>
            <div className="col-lg-12 mx-auto mt-auto mb-4 mb-md-0">
            </div>
          </div>
      </div>
          )
       }else{
        return (

          <div className="col-md-6 col-lg-7 col-xl-8">
          <div className="row d-flex h-100 text-center text-md-left">
            <div className="col-lg-10 col-xl-10 mt-auto">
                <div className="logo">
                  <img className="d-none d-sm-block" src="../images/trdr.png" width="100px" alt="TRDR"/>
                  <img className="d-block d-sm-none text-center mobile-logo" src="../images/trdr-horizontal.png" width="150px" alt="TRDR"/>
              </div>
             
              <h1 className="text-12 font-weight-200 mb-5 d-none d-sm-block text-left"> <br/><b>Welcome to TRDR.</b><br/>India's first 100% automated investment platform</h1>
              <h1 className="text-2 font-weight-200 mb-1  d-block d-sm-none text-left"><b>Welcome to TRDR.<br/>India's first 100% automated investment platform</b></h1>
            </div>
            <div className="col-lg-12 mx-auto mt-auto mb-4 mb-md-0">
            </div>
          </div>
      </div>
        
         

        )
       }
    //  
        

    }
}
export default withRouter(StaticContent);