import { query, mutation } from "../../api";
export async function getPortfolioData() {
  return query(
    "get_portfolio_data",
    {},
    `portfolio_value
    fund_invested
    ledger_balance
    modified_ts
    portfolio_data {
      scrip_name
      holding_qty
      rate_per_share
      total_value
    }`,
    "signin"
  );
}
