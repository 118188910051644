import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { gerOrderStatus } from "./api";
import { renderRedirect } from "../../util";
import { data } from "jquery";

class TransactionResponse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      txStatus: "",
      orderId: "",
      referenceId: "",
      amount: "",
      paymentMode: "",
      txMsg: "",
      txTime: "",
    };
  }
  async componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams != null) {
      let orderInfoResponse = await gerOrderStatus(queryParams.get("orderId"));
      if (orderInfoResponse?.data?.fetch_single_transaction) {
        let orderInfo = orderInfoResponse.data.fetch_single_transaction;
        this.setState({
          txStatus: orderInfo.txStatus,
          orderId: orderInfo.orderId,
          referenceId: orderInfo.referenceId,
          orderAmount: orderInfo.orderAmount,
          paymentMode: orderInfo.paymentMode,
          txMsg: orderInfo.txMsg,
          txTime: orderInfo.txTime,
        });

        console.log(orderInfo);
      }
    } else {
      //show error
      console.log("No valid params found in returnUrl");
    }
  }
  render() {
    return (
      <div id="appCapsule" className="full-height">
        <div className="row">
          <div className="col col-md-3 d-none d-md-block" />
          <div className="col">
            <div className="listed-detail mt-1">
              {this.state.txStatus === "SUCCESS" && (
                <div className="icon-wrapper">
                  <div className="iconbox" style={{ background: "green" }}>
                    <ion-icon name="checkmark-outline"></ion-icon>
                  </div>
                </div>
              )}
              {this.state.txStatus === "PENDING" && (
                <div className="icon-wrapper">
                  <div className="iconbox" style={{ background: "#ffb400" }}>
                    <ion-icon name="alert-circle-outline"></ion-icon>
                  </div>
                </div>
              )}
              {(this.state.txStatus === "FAILED" ||
                this.state.txStatus === "CANCELLED") && (
                  <div className="icon-wrapper">
                    <div className="iconbox" style={{ background: "#ff396f" }}>
                      <ion-icon name="close-outline"></ion-icon>
                    </div>
                  </div>
                )}

              <h3 className="text-center mt-2">{`TRANSACTION ${this.state.txStatus}`}</h3>
            </div>

            <div className="text-center">
              <h5>Order Id</h5>
              <h5>{this.state.orderId}</h5>
            </div>
            <div className="section mt-3 mb-2 wallet-card">
              <ul className="listview flush transparent simple-listview no-space">
                {this.state.txStatus === "SUCCESS" && (
                  <li>
                    <strong>Status</strong>
                    <span className="text-success">{this.state.txStatus}</span>
                  </li>
                )}
                {/* <li>
                  <strong>Order Id</strong>
                  <span>{this.state.orderId}</span>
                </li> */}
                <li>
                  <strong>Refrence Id</strong>
                  <span>{this.state.referenceId}</span>
                </li>
                <li>
                  <strong>Amount</strong>
                  <h3 className="m-0"> ₹ {this.state.orderAmount}</h3>
                </li>
                <li>
                  <strong>Payment Mode</strong>
                  <span>{this.state.paymentMode}</span>
                </li>
                <li>
                  <strong>Details</strong>
                  <span>{this.state.txMsg}</span>
                </li>
                <li>
                  <strong>Date</strong>
                  <span>{this.state.txTime}</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="col col-md-3 d-none d-md-block" />
        </div>
      </div>
    );
  }
}
export default withRouter(TransactionResponse);
