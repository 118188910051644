import React, { useEffect } from 'react';
import BidEntries from './BidEntries';
import { BidTitle, Title } from './UpdateIpo';

const ExpiredOrder = ({
    orderDetails,
    title,
    dismissModal
}) => {

    const maxAmount = (array) =>
        Math.max(array.map(item => item.quantity * item.rate))

    return (<>
        <Title
            title={title}
            dismissModal={dismissModal} />
        <div className="modal-body">
            <div className="row">
                <div className="col-md-6"></div>
                <div className="col-md-2"></div>
                <div className="col-sm-12 col-md-4">
                    <blockquote className="blockquote mb-0 p-1" style={{ backgroundColor: '#ffe291' }}>
                        <p className="text-12 text-center">
                            Unable to edit details; IPO expired
                        </p>
                    </blockquote>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <BidTitle title={orderDetails.scripid} />
                    <div className="form-group basic col-sm">
                        <label className="label">
                            UPI address
                        </label>
                        {
                            orderDetails && orderDetails.accountnumber_upiid
                        }
                    </div>

                    <BidEntries
                        disabled
                        bids={orderDetails ? orderDetails.bids : null} />
                </div>
            </div>
        </div>
    </>
    );
}

export default ExpiredOrder