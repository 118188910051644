const validateForm = (obj, text = "") => {
    let response = { status: true, err: "" };
    if (obj) {
        if (validateInput("", obj?.name) !== "") {
            return { status: false, err: `Error in name of ${text}` };
        }

        if (validateInput("Share percentage", obj?.percentage_of_share) !== "") {
            return { status: false, err: `Error in share percentage of ${text}` };
        }

        if (validateInput("", obj?.address) !== "") {
            return { status: false, err: `Error in address of ${text}` };
        }

        if (validateInput("", obj?.relationship) !== "") {
            return { status: false, err: `Error in relationship of ${text}` };
        }
        if (mobileValidator(obj?.mobile) !== "") {
            return { status: false, err: `Error in mobile no. of ${text}` };
        }

        if (emailValidator(obj?.email) !== "") {
            return { status: false, err: `Error in email address of ${text}` };
        }

        if (validateInput("", obj?.id_type) !== "") {
            return { status: false, err: `Error in ID type of ${text}` };
        }

        if (idValidator(obj?.id_type, obj?.id_number) !== "") {
            return { status: false, err: `Error in ID no. of ${text}` };
        }
        if (obj?.minor && typeof obj?.minor !== "undefined") {
            // if (validateInput("", obj?.dob_if_minor) !== "") {
            //     return { status: false, err: `Error in DOB of minor in ${text}` };
            // }
            if (validateInput("", obj?.guardian_name) !== "") {
                return { status: false, err: `Error in guardian name of ${text}` };
            }

            if (validateInput("", obj?.guardian_address) !== "") {
                return { status: false, err: `Error in guardian address of  ${text}` };
            }

            if (validateInput("", obj?.guardian_relationship) !== "") {
                return { status: false, err: `Error in guardian relation of ${text}` };
            }

            if (validateInput("", obj?.guardian_relationship) !== "") {
                return { status: false, err: `Error in guardian relation of ${text}` };
            }

            if (validateInput("", obj?.guardian_id_type) !== "") {
                return { status: false, err: `Error in guardian ID type of ${text}` };
            }

            if (mobileValidator(obj?.guardian_mobile) !== "") {
                return { status: false, err: `Error in guardian mobile no. of ${text}` };
            }

            if (mobileValidator(obj?.guardian_mobile) !== "") {
                return { status: false, err: `Error in guardian mobile no. of ${text}` };
            }

            if (emailValidator(obj?.guardian_email) !== "") {
                return { status: false, err: `Error in guardian email address of ${text}` };
            }

            if (idValidator(obj?.guardian_id_type, obj?.guardian_id_number) !== "") {
                return { status: false, err: `Error in guardian ID no. of ${text}` };
            }
        }
    }
    return response;
};

const validateInput = (fieldName, inputText) => {
    if (typeof inputText !== "undefined") {
        if (inputText === "") {
            return `${fieldName} is required`;
        }
        if (fieldName === "Share percentage") {
            if (inputText === "0") {
                return "A share percentage of zero is not possible";
            }
        }
        return "";
    }
};

const emailValidator = (inputText) => {
    if (typeof inputText !== "undefined") {
        if (inputText === "") {
            return "Email is required";
        } else {
            if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(inputText)) {
                return "Invalid email address";
            }
            return "";
        }
    }
};

const mobileValidator = (inputText) => {
    if (typeof inputText !== "undefined") {
        if (inputText === "") {
            return "Mobile no. is required";
        } else {
            if (!/^\d{10}$/.test(inputText)) {
                return "Invalid mobile no.";
            }
            return "";
        }
    }
};

const idValidator = (id, inputText) => {
    if (typeof id === "undefined") {
        return `please select an id type`;
    }
    if (typeof inputText !== "undefined") {
        if (inputText === "") {
            return `${
                id === "pan" ? "Pan card" : id === "aadhaar" ? "Aadhar card" : id.replaceAll("_", " ")
            } no. is required`;
        } else {
            if (id === "pan") {
                if (!/[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(inputText)) {
                    return "Invalid pan card no.";
                }
                return "";
            } else if (id === "aadhaar") {
                if (
                    !/(^[0-9]{4}[0-9]{4}[0-9]{4}$)|(^[0-9]{4}\s[0-9]{4}\s[0-9]{4}$)|(^[0-9]{4}-[0-9]{4}-[0-9]{4}$)/.test(
                        inputText
                    )
                ) {
                    return "Invalid aadhaar card no.";
                }
                return "";
            } else {
                return "";
            }
        }
    }
};

export { validateForm, validateInput, emailValidator, mobileValidator, idValidator };
