import NotFoundPage from "./Pages/404/NotFoundPage";
import About from "./Pages/About/About";
import Account from "./Pages/Account/Account";
import TransactionResponse from "./Pages/CashFree/TransactionResponse";
import Home from "./Pages/Home/Home";
import LanguageSelector from "./Pages/LanguageSelector/LanguageSelector";
import MobileOTP from "./Pages/Mobile/MobileOTP";
import VerifyEmailOTP from "./Pages/Mobile/VerifyEmailOTP";
import VerifyMobileOTP from "./Pages/Mobile/VerifyMobileOTP";
import Notification from "./Pages/Notification/Notification";
import Portfolio from "./Pages/Portfolio/Portfolio";
import Profile from "./Pages/Profile/Profile";
import Reports from "./Pages/Reports/Reports";
import Settings from "./Pages/Settings/Settings";
import Stock from "./Pages/Stock/Stock";
import Support from "./Pages/Support/Support";
import Terms from "./Pages/Terms/Terms";
import Welcome from "./Pages/Welcome/Welcome";
import Ipo from "./Pages/IPO";
import ExternalLogin from "./Components/external-login";
import AddNominee from "./Pages/Nominee/AddNominee";
import ViewNominee from './Pages/Nominee/NomineesList'
import Nominee from "./Pages/Nominee/Nominee";

const routes = [
  {
    path: "/",
    component: MobileOTP,
    needAuth: false,
  },
  {
    path: "/external-login",
    component: ExternalLogin,
    needAuth: false,
  },
  {
    path: "/language",
    component: LanguageSelector,
    needAuth: false,
  },
  {
    path: "/welcome",
    component: Welcome,
    needAuth: false,
  },
  {
    path: "/login",
    component: MobileOTP,
    needAuth: false,
  },
  {
    path: "/mobile-otp",
    component: VerifyMobileOTP,
    needAuth: false,
  },
  {
    path: "/verify-email",
    component: VerifyEmailOTP,
    needAuth: false,
  },
  {
    path: "/home",
    component: Home,
    needAuth: true,
  },
  {
    path: "/portfolio",
    component: Portfolio,
    needAuth: true,
  },
  {
    path: "/reports",
    component: Reports,
    needAuth: true,
  },
  {
    path: "/account",
    component: Account,
    needAuth: true,
  },
  {
    path: "/support",
    component: Support,
    needAuth: true,
  },
  {
    path: "/profile",
    component: Profile,
    needAuth: true,
  },
  {
    path: "/terms",
    component: Terms,
    needAuth: true,
  },
  {
    path: "/settings",
    component: Settings,
    needAuth: true,
  },
  {
    path: "/about",
    component: About,
    needAuth: true,
  },
  {
    path: "/notification",
    component: Notification,
    needAuth: true,
  },
  {
    path: "/cashfree/response",
    component: TransactionResponse,
    needAuth: true,
  },
  {
    path: "/stock",
    component: Stock,
    needAuth: true,
  },
  {
    path: "/trdr/ipo",
    component: Ipo,
    needAuth: true,
  },
  {
    path: "/add-nominee",
    component: AddNominee,
    needAuth: true,
  },
  {
    path: "/view-nominee",
    component: ViewNominee,
    needAuth: true,
  },
  {
    path: "/nominee",
    component: Nominee,
    needAuth: false,
  }
  // {
  //   path: "*",
  //   component: NotFoundPage,
  //   needAuth: false,
  // },
];

export default routes;
