import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { renderRedirect } from "../../util";

class Settings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      darkModeStatus: "",
      notificationEnbaled: "true",
      language: "English"
    };
    this.logout = this.logout.bind(this);
    this.onToggleDarkMode = this.onToggleDarkMode.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
  }

   async changeLanguage (event) {
    const { t, i18n } = this.props;
    i18n.changeLanguage(event.target.value);
  };

  async componentDidMount() {
    let finappDarkmode = localStorage.getItem("FinappDarkmode");
    if (!finappDarkmode) {
      localStorage.setItem("FinappDarkmode", "0");
    }
    finappDarkmode == "0" ? this.setState({
        ["darkModeStatus"]: ""}):
    this.setState({
        ["darkModeStatus"]: "checked"});
  }

  async onToggleDarkMode(e) {
    let finappDarkmode;
    let isDarkModeChecked = e.target.checked;
    isDarkModeChecked ? (finappDarkmode = "1") : (finappDarkmode = "0");
    localStorage.setItem("FinappDarkmode", finappDarkmode);
    this.setState({ darkModeStatus: isDarkModeChecked });
    console.log(
      "finappDarkmode-" +
        finappDarkmode +
        " darkModeStatus-" +
        isDarkModeChecked
    );

    // Dark Mode
    var checkDarkModeStatus = localStorage.getItem("FinappDarkmode");
    var switchDarkMode = document.querySelectorAll(".dark-mode-switch");
    var pageBody = document.querySelector("body");
    var pageBodyActive = pageBody.classList.contains("dark-mode");

    function switchDarkModeCheck(value) {
      switchDarkMode.forEach(function (el) {
        el.checked = value;
      });
    }

    // if dark mode on
    if (checkDarkModeStatus === 1 || checkDarkModeStatus === "1") {
      switchDarkModeCheck(true);
      if (pageBodyActive) {
        // dark mode already activated
      } else {
        pageBody.classList.add("dark-mode");
      }
    } else {
      switchDarkModeCheck(false);
    }
    switchDarkMode.forEach(function (el) {
      el.addEventListener("click", function () {
        var darkmodeCheck = localStorage.getItem("FinappDarkmode");
        if (darkmodeCheck === 1 || darkmodeCheck === "1") {
          pageBody.classList.remove("dark-mode");
          //localStorage.setItem("FinappDarkmode", "0");
          switchDarkModeCheck(false);
        } else {
          pageBody.classList.add("dark-mode");
          switchDarkModeCheck(true);
          //localStorage.setItem("FinappDarkmode", "1");
        }
      });
    });

  }
  async onToggleNotificatione(e) {}
  async logout(e) {
    localStorage.clear();
    renderRedirect(this.props, "/");
  }
  render() {
    return (
      <div id="appCapsule">
        <div class="section mt-3 text-left">
          <div class="section-title">Settings</div>
        </div>
        <ul class="listview image-listview text inset">
          <li>
            <div class="item">
              <div class="in">
                <div>Dark Mode Theme</div>
                <div class="form-check form-switch  ms-2">
                  <input
                    class="form-check-input dark-mode-switch"
                    type="checkbox"
                    id="darkmodeSwitch"
                    checked={this.state.darkModeStatus}
                    onClick={(e) => this.onToggleDarkMode(e)}
                  />
                  <label class="form-check-label" for="darkmodeSwitch"></label>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="item">
              <div class="in">
                <div>Select Language</div>
                <div class="dropdown">
                  <div class="input-wrapper">
                    <select
                      class="form-control custom-select"
                      value={this.state.language}
                      onChange={(e)=>this.changeLanguage(e)}
                    >
                      <option value="English">English</option>
                      <option value="Hindi">Hindi</option>
                      <option value="Marathi">Marathi</option>
                      <option value="Kannada">Kannada</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        
        <div class="section mt-3 text-center">
          <button
            type="button"
            class="btn btn-dark me-1 mb-1"
            onClick={() => this.logout()}
          >
            Logout
          </button>
        </div>
      </div>
    );
  }
}
export default withRouter(Settings);
