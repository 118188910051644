import React from "react";
import { withRouter } from "react-router-dom";

import { renderRedirect } from "../../util";
import { getProfileData } from "./api";

class Nominee extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoader: true,
        };
    }

    async componentDidMount() {
        this.setState({
            showLoader: true,
        });
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let token = params.get("token");

        if (!token) {
            token = localStorage.getItem("token");
        } else {
            token = localStorage.setItem("token", token);
        }

        let profileData = await getProfileData();
        if (profileData?.errors) {
            console.log("error");
            this.setState({ showLoader: false });
            return this._renderRedirect();
        } else {
            return this._renderRedirect("add-nominee");
        }
    }

    _renderRedirect = (url = "") => {
        renderRedirect(this.props, url === "" ? "/login" : "/" + url);
    };

    render() {
        return <div>Please wait...</div>;
    }
}
export default withRouter(Nominee);
