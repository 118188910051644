import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import IpoComponent from "./Ipo";

const queryClient = new QueryClient();

const Ipo = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <IpoComponent />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default Ipo;
