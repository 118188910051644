import React from "react";
import { withRouter } from "react-router-dom";
import { renderRedirect } from "../../util";

function NotFoundPage(props) {
  return (
    <div
      className="row p-2"
      style={{ backgroundColor: "black", height: "100%" }}
    >
      <div className="col col-6 m-auto">
        <div>
          <h2 style={{ color: "white" }}>Oops!</h2>
          <h3 style={{ color: "white" }}>
            We can't seem to find the page you're looking for.
          </h3>

          <button
            type="button"
            className="btn btn-outline-primary btn-block btn-lg page-not-found-button"
            onClick={() => renderRedirect(props, "/home")}
            style={{ color: "white", borderColor: "white" }}
          >
            Go To TRDR Home Page
          </button>
        </div>
      </div>
      <div className="col col-6 text-center m-auto">
        <img
          src="../assets/img/TRDR-404.gif"
          alt="page not found"
          className=" d-none d-md-block m-auto"
        />
        <img
          src="../assets/img/TRDR-404.gif"
          alt="page not found"
          className="d-md-none "
          style={{ width: "100%" }}
        />
      </div>
    </div>
  );
}

export default withRouter(NotFoundPage);
