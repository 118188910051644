import React from "react";

const Buttons = ({ expanded, ...props }) =>
    expanded ?
        <>
            <button type="button" onClick={props.updateIpo} className="btn btn-dark me-1">
                <a href="#" className="item" data-bs-toggle="modal" data-bs-target="#ModalBasic">
                    View
                </a>
            </button>
            <button onClick={props.deleteIpo} type="button" className="btn btn-dark me-1">
                Delete
            </button>
        </> :
        <button type="button" onClick={props.onClick} className="btn btn-dark me-1">
            <a onClick={props.onClick} className="item" data-bs-toggle="modal" data-bs-target="#ModalBasic">
                <div className="in">Bid</div>
            </a>
        </button>

export default Buttons