import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { renderRedirect } from "../../util";
import { currencyFormat, groupBy } from "../../util/helper";
import { getStockPortfolios, toggleRoboMode } from "./api";
import AutoPilotCard from "./AutoPilotCard";
class Stock extends Component {
  state = {
    stocksList: [],
  };
  async componentDidMount() {
    // document.querySelectorAll(".carousel-multiple").forEach((carousel) => {
    //   console.log("carousel", carousel);

    //   return new window.Splide(carousel, {
    //     perPage: 4,
    //     rewind: true,
    //     type: "loop",
    //     gap: 16,
    //     padding: 16,
    //     arrows: false,
    //     pagination: false,
    //     breakpoints: {
    //       768: {
    //         perPage: 2,
    //       },
    //       991: {
    //         perPage: 3,
    //       },
    //     },
    //   }).mount();
    // });

    const stockResponse = await getStockPortfolios();

    this.setState(
      {
        stocksList: groupBy(
          stockResponse?.data?.get_smart_portfolios?.smart_portfolio_data || [],
          "smart_portfolio_id"
        ),
      },
      () => {
        document.querySelectorAll(".carousel-multiple").forEach((carousel) => {
          return new window.Splide(carousel, {
            perPage: 4,
            rewind: true,
            type: "loop",
            gap: 16,
            padding: 10,
            arrows: true,
            pagination: 'slider',
            breakpoints: {
              768: {
                perPage: 1,
              },
              991: {
                perPage: 3,
              },
            },
          }).mount();
        });
      }
    );
  }

  toggleRoboMode = async () => {
    const toggleRoboResponse = await toggleRoboMode(true);
    if (toggleRoboResponse?.data?.toggle_robo_mode?._id) {
      renderRedirect(this.props, "/home");
    }
  };

  render() {
    const SelectButton = ({ style }) => (
      <div className="form-button-group  transparent" style={{ ...style }}>
        <button
          type="button"
          className="btn btn-primary btn-block btn-lg"
          onClick={this.toggleRoboMode}
        >
          CONFIRM
        </button>
      </div>
    );

    const CancelButton = ({ style }) => (
      <div className="form-button-group  transparent" style={{ ...style }}>
        <button
          type="button"
          className="btn btn-outline-primary btn-block btn-lg"
          onClick={() => renderRedirect(this.props, "/home")}
        >
          CANCEL
        </button>
      </div>
    );

    const stockObj = Object.keys(this.state.stocksList || []);

    return (
      <>
        {this.state.stocksList !== null &&
          this.state.stocksList.length !== 0 ? (
          <div id="appCapsule">
            <div className="section mt-2">
              <h3>Smart Portfolios</h3>
              <span className="text-12">
                The following smart portfolios will be picked for short-term
                deliveries when the right Buy/Sell signals are generated.{" "}
              </span>

              <div className="carousel-multiple splide">
                <div className="splide__track">
                  <ul className="splide__list">
                    {stockObj.map((objKey) => {
                      const stockDataList =
                        this.state.stocksList?.[objKey] || [];
                      return (
                        <li className="splide__slide" key={`stock-${objKey}`}>
                          <AutoPilotCard stockDataList={stockDataList} />
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>

            {/* <div className="section row mt-2">
              <div className="col col-12 mb-1">
                <div className="section mt-2">
                  <h3>Intraday</h3>
                  <span className="text-12">
                    Robo will do intraday trading based on the signals generated
                    by the TRDR strategies.
                  </span>
                </div>
              </div>
              <div className="col col-md-3 d-none d-md-block" />
              <div className="col">
                <div className="wallet-card">
                  {/* <div className="section-title">Intraday</div>
                  <p className="text-10">
                    Robo will do intraday trading based on the signals generated
                    by the TRDR strategies.
                  </p> * /}

                  <div className="row" style={{ alignItems: "center" }}>
                    <div className="col col-4">
                      <img
                        src="../assets/img/black-border-standing-robo.png"
                        alt="happy-robo-face"
                        className="logo"
                        style={{
                          width: "65%",
                        }}
                      />
                    </div>
                    <div className="col col-8">
                      <h5>
                        Robo comes on at 9:00 am every weekday and is active
                        till 3:45 pm in the evening.
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-md-3 d-none d-md-block" />
            </div>

            <div className="section row mt-2">
              <div className="col col-12 text-center">
                I acknowledge that I am aware of the market risks involved in
                investing in the stock markets.
              </div>
              <div className="col col-md-3 d-none d-md-block" />
              <div className="col">
                <div className="row">
                  <div className="col col-6">
                    <CancelButton style={{ position: "unset" }} />
                  </div>
                  <div className="col col-6">
                    <SelectButton style={{ position: "unset" }} />
                  </div>
                </div>
              </div>
              <div className="col col-md-3 d-none d-md-block" />
            </div> */}

            <div className="section row mt-2">
              <div className="col col-12 col-lg-6 mt-1 p-2">
                <div className="row wallet-card align-items-center ">
                  <div className="col col-4">
                    <img
                      src="../assets/img/black-border-standing-robo.png"
                      alt="happy-robo-face"
                      className="logo"
                      style={{
                        width: "65%",
                      }}
                    />
                  </div>
                  <div className="col col-8">
                    <h5>
                      Robo comes on at 9:00 am every weekday and is active till
                      3:45 pm in the evening.
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col col-12 col-lg-6 mt-1 p-2">
                <div className="row wallet-card align-items-center p-1 h-100-p">
                  <div className="col col-12 text-center">
                    I acknowledge that I am aware of the market risks involved
                    in investing in the stock markets.
                  </div>
                  <div className="col">
                    <div className="row">
                      <div className="col col-6">
                        <CancelButton style={{ position: "unset" }} />
                      </div>
                      <div className="col col-6">
                        <SelectButton style={{ position: "unset" }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div id="appCapsule">
            <div className="section mt-2">
              <h3>No Stocks</h3>
            </div>
          </div>
        )}
      </>
    );
  }
}
export default withRouter(Stock);
