import React from "react";

const ConfirmForm = ({ data }) => {
    return (
        <>
            <form>
                <div className="form-group basic">
                    <label className="label">Nominee Name</label>
                    <div className="input-group">
                        <input type="text" name="name" className="form-control" disabled value={data?.name} />
                    </div>
                </div>
                <div className="form-group basic">
                    <label className="label">Share Percentage</label>
                    <div className="input-group">
                        <input
                            type="text"
                            name="percentage_of_share"
                            className="form-control"
                            disabled
                            value={data?.percentage_of_share + "%"}
                        />
                    </div>
                </div>
                <div className="form-group basic">
                    <label className="label">Relationship</label>
                    <div className="input-group">
                        <input
                            type="text"
                            name="nomineeRelation"
                            className="form-control"
                            disabled
                            value={data?.relationship.replaceAll("_", " ")}
                            style={{ textTransform: "capitalize" }}
                        />
                    </div>
                </div>
                <div className="form-group basic">
                    <label className="label">Nominee Address</label>
                    <div className="input-group">
                        <input type="text" name="address" className="form-control" disabled value={data?.address} />
                    </div>
                </div>
                <div className="form-group basic">
                    <label className="label">Nominee Mobile</label>
                    <div className="input-group">
                        <input type="text" name="mobile" className="form-control" disabled value={data?.mobile} />
                    </div>
                </div>
                <div className="form-group basic">
                    <label className="label">Nominee Email</label>
                    <div className="input-group">
                        <input type="text" name="email" className="form-control" disabled value={data?.email} />
                    </div>
                </div>
                <div className="form-group basic">
                    <label className="label">Nominee ID Type</label>
                    <div className="input-group">
                        <input
                            type="text"
                            name="id_type"
                            className="form-control"
                            disabled
                            value={data?.id_type.replaceAll("_", " ")}
                            style={{ textTransform: "capitalize" }}
                        />
                    </div>
                </div>
                <div className="form-group basic">
                    <label className="label">Nominee ID Number</label>
                    <div className="input-group">
                        <input type="text" name="id_number" className="form-control" disabled value={data?.id_number} />
                    </div>
                </div>
                {data?.dob_if_minor ? (
                    <div className="form-group basic">
                        <label className="label">Date Of Birth</label>
                        <div className="input-group">
                            <input
                                type="text"
                                name="dob_if_minor"
                                className="form-control"
                                disabled
                                value={data?.dob_if_minor}
                            />
                        </div>
                    </div>
                ) : null}
                {data?.minor_guardian_info ? (
                    <>
                        <div className="form-group basic">
                            <label className="label">Name of Guardian</label>
                            <div className="input-group">
                                <input
                                    type="text"
                                    name="guardName"
                                    className="form-control"
                                    disabled
                                    value={data?.minor_guardian_info?.guardian_name}
                                />
                            </div>
                        </div>
                        <div className="form-group basic">
                            <label className="label">Address of Guardian</label>
                            <div className="input-group">
                                <input
                                    type="text"
                                    name="guardAddress"
                                    className="form-control"
                                    disabled
                                    value={data?.minor_guardian_info?.guardian_address}
                                />
                            </div>
                        </div>
                        <div className="form-group basic">
                            <label className="label">Mobile of Guardian</label>
                            <div className="input-group">
                                <input
                                    type="text"
                                    name="guardMobile"
                                    className="form-control"
                                    disabled
                                    value={data?.minor_guardian_info?.guardian_mobile}
                                />
                            </div>
                        </div>
                        <div className="form-group basic">
                            <label className="label">Email of Guardian</label>
                            <div className="input-group">
                                <input
                                    type="text"
                                    name="guardEmail"
                                    className="form-control"
                                    disabled
                                    value={data?.minor_guardian_info?.guardian_email}
                                />
                            </div>
                        </div>
                        <div className="form-group basic">
                            <label className="label">Relationship with Guardian</label>
                            <div className="input-group">
                                <input
                                    type="text"
                                    name="guardRelation"
                                    className="form-control"
                                    disabled
                                    style={{ textTransform: "capitalize" }}
                                    value={data?.minor_guardian_info?.guardian_relationship.replaceAll("-", " ")}
                                />
                            </div>
                        </div>
                        <div className="form-group basic">
                            <label className="label">Guardian ID Type</label>
                            <div className="input-group">
                                <input
                                    type="text"
                                    name="guardIdType"
                                    className="form-control"
                                    disabled
                                    value={data?.minor_guardian_info?.guardian_id_type.replaceAll("_", " ")}
                                    style={{ textTransform: "capitalize" }}
                                />
                            </div>
                        </div>
                        <div className="form-group basic">
                            <label className="label">Guardian ID Number</label>
                            <div className="input-group">
                                <input
                                    type="text"
                                    name="guardIdNumber"
                                    className="form-control"
                                    disabled
                                    value={data?.minor_guardian_info?.guardian_id_number}
                                />
                            </div>
                        </div>
                    </>
                ) : null}
            </form>
        </>
    );
};

export default ConfirmForm;
