export const idData = [
    {
        label: "Aadhaar",
        value: "aadhaar",
    },
    {
        label: "PAN",
        value: "pan",
    },
    {
        label: "Voter ID",
        value: "voter_id",
    },
    {
        label: "Driving Licence",
        value: "driving_licence",
    },
    {
        label: "College/ School id",
        value: "college_school_id",
    },
    {
        label: "Govt ID",
        value: "govt_id",
    },
    {
        label: "Passport",
        value: "passport",
    }
];

export const relationshipData = [
    {
        label: "Spouse",
        value: "spouse",
    },
    {
        label: "Son",
        value: "son",
    },
    {
        label: "Daughter",
        value: "daughter",
    },
    {
        label: "Mother",
        value: "mother",
    },
    {
        label: "Father",
        value: "father",
    },
    {
        label: "Brother",
        value: "brother",
    },
    {
        label: "Grand son",
        value: "grand_son",
    },
    {
        label: "Grand daughter",
        value: "grand_daughter",
    },
    {
        label: "Uncle",
        value: "uncle",
    },
    {
        label: "Aunty",
        value: "aunty",
    },
    {
        label: "Friend",
        value: "friend",
    }
];
