import React, { useEffect, useState } from 'react';

import { cutDecimals } from '../../../util/helper';
import { fetchInactiveIpoDetails } from '../api';

const BidEntries = ({
    disabled,
    bids,
    currentIpo
}) => {

    const [activeBids, setActiveBids] = useState(null)
    const [deletedBids, setDeletedBids] = useState(null)
    const [editMode, setEditMode] = useState({
        bidId: null,
        edit: false
    })

    const initialValues = {
        actioncode: '',
        cuttoffflag: '1',
        quantity: '',
        rate: '',
        lots: '',
    }

    useEffect(() => bids && setActiveBids(
        filterActiveBids(bids)
    ), [bids])

    useEffect(() => bids && setDeletedBids(
        filterDeletedBids(bids)
    ), [bids])

    // useEffect(() => console.log(fetchInactiveIpoDetails()), [])

    const calculateTotal = (array) =>
        array && Math.max(array.map(item => item.rate * item.quantity))

    const createNewEntry = () =>
        setActiveBids(previousState => ([
            ...previousState,
            initialValues
        ]))

    const filterActiveBids = array =>
        array.filter(item => item.actioncode === 'n' || item.actioncode === 'm')

    const filterDeletedBids = array =>
        array.filter(item => item.actioncode === 'd')

    const updateBidDetails = (key, data) =>
        // setActiveBids(previousState => ([
        //     ...previousState,
        //     [previousState.key]: data
        // ]))
        console.log(key, data)

    return (<>
        <div className="table-responsive">
            <div className="accordion" id="accordionExample1">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#accordion01" aria-expanded="true">
                            Active Bids
                        </button>
                    </h2>
                    <div id="accordion01" className="accordion-collapse collapse show" data-bs-parent="#accordionExample1">
                        <div className="accordion-body">
                            <div className="row">
                                <div className="col-md-6" style={{ display: 'flex' }}>
                                    <label style={{ display: "flex", alignItems: 'center', paddingRight: '1rem' }}>
                                        Investor type
                                    </label>
                                    <select className="form-select" onChange={e => console.log("dropdown")} selected="IND" style={{ width: '200px' }}>
                                        <option value="IND">Individual</option>
                                        <option disabled value="POL">Policy Holder</option>
                                    </select>
                                </div>
                                <div className="col-md-6">
                                </div>
                            </div>
                            <table className="table tableIpo">
                                <thead>
                                    <tr className="bidsTable">
                                        <th scope="col">No.</th>
                                        <th scope="col">
                                            Cutoff Price
                                            <p>{cutDecimals(100, 2)}</p>
                                        </th>
                                        <th scope="col">
                                            Price
                                            <p> {cutDecimals(110, 2)} - {cutDecimals(100, 2)} </p>
                                        </th>
                                        {
                                            !disabled &&
                                            <th scope="col">No. of lots</th>
                                        }
                                        <th scope="col" className="text-end">Total shares</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (activeBids) &&
                                        Object.values(activeBids).slice(0, 3).map((item, key) =>
                                            <tr key={key}>
                                                <th scope="row" style={{ height: '40px' }}>
                                                    <label className="label itemCenterFullHeight">
                                                        {key + 1}
                                                    </label>
                                                </th>
                                                <td style={{ padding: '5px' }}>
                                                    <div className="input-group itemCenterFullHeight">
                                                        <input
                                                            disabled={disabled}
                                                            style={{ height: 30, width: 30 }}
                                                            type="checkbox"
                                                            checked={item.cuttoffflag === '1' ? true : false}
                                                            className="form-check-input"
                                                            onChange={(e) => e.target.checked && updateBidDetails(key, { cuttoff: e.target.checked })}
                                                        />
                                                    </div>
                                                </td>
                                                <td style={{ padding: '5px' }}>
                                                    <div className="input-group">
                                                        <input
                                                            disabled={disabled || item.cuttoff}
                                                            style={{ border: 'none' }}
                                                            type="number"
                                                            className="form-control"
                                                            value={item.cuttoff ? `${item.cuttoff}` : item.rate !== 0 && `${item.rate}`}
                                                            onChange={e => updateBidDetails(key, { rate: parseFloat(e.target.value) })} />
                                                    </div>
                                                </td>
                                                {
                                                    !disabled &&
                                                    <td style={{ padding: '5px' }}>
                                                        <div className="input-group">
                                                            <input
                                                                disabled={disabled}
                                                                style={{ border: 'none' }}
                                                                type="number"
                                                                className="form-control"
                                                                placeholder='Number of lots'
                                                                value={item.lots !== 0 && `${item.lots}`}
                                                                onChange={e => updateBidDetails(key, { lots: parseInt(e.target.value) })} />
                                                        </div>
                                                    </td>
                                                }
                                                <td style={{ height: '40px', padding: '0px' }}>
                                                    <label className="label itemCenterFullHeight">
                                                        <input
                                                            disabled={true}
                                                            style={{ border: 'none' }}
                                                            type="number"
                                                            className="form-control"
                                                            placeholder='Number of lots'
                                                            value={item.quantity}
                                                            onChange={e => updateBidDetails(key, { lots: parseInt(e.target.value) })} />
                                                    </label>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                            {
                                !disabled &&
                                <div className='p-1'>
                                    <button onClick={() => createNewEntry()} type="button" className="btn btn-dark">
                                        Add bid entry
                                    </button>
                                </div>
                            }
                            <div className="col-sm-12 pt-1" style={{ color: "black", fontSize: 14 }}>
                                Total amount:{" "} {calculateTotal(activeBids)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordion02">
                            Deleted Bids
                        </button>
                    </h2>
                    <div id="accordion02" className="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div className="accordion-body">
                            {
                                (deletedBids && deletedBids.length > 0) ?
                                    <table className="table tableIpo">
                                        <thead>
                                            <tr className="bidsTable">
                                                <th scope="col">No.</th>
                                                <th scope="col">
                                                    Cutoff Price
                                                    <p>{cutDecimals(100, 2)}</p>
                                                </th>
                                                <th scope="col">
                                                    Price
                                                    <p> {cutDecimals(110, 2)} - {cutDecimals(100, 2)} </p>
                                                </th>
                                                {
                                                    !disabled &&
                                                    <th scope="col">No. of lots</th>
                                                }
                                                <th scope="col" className="text-end">Total shares</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (deletedBids) &&
                                                Object.values(deletedBids).map((item, key) =>
                                                    <tr key={key}>
                                                        <th scope="row" style={{ height: '40px' }}>
                                                            <label className="label itemCenterFullHeight">
                                                                {key + 1}
                                                            </label>
                                                        </th>
                                                        <td style={{ padding: '5px' }}>
                                                            <div className="input-group itemCenterFullHeight">
                                                                <input
                                                                    disabled={disabled}
                                                                    style={{ height: 30, width: 30 }}
                                                                    type="checkbox"
                                                                    checked={item.cuttoffflag === '1' ? true : false}
                                                                    className="form-check-input"
                                                                    onChange={(e) => e.target.checked && updateBidDetails(key, { cuttoff: e.target.checked })}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td style={{ padding: '5px' }}>
                                                            <div className="input-group">
                                                                <input
                                                                    disabled={disabled || item.cuttoff}
                                                                    style={{ border: 'none' }}
                                                                    type="number"
                                                                    className="form-control"
                                                                    value={item.cuttoff ? `${item.cuttoff}` : item.rate !== 0 && `${item.rate}`}
                                                                    onChange={e => updateBidDetails(key, { rate: parseFloat(e.target.value) })} />
                                                            </div>
                                                        </td>
                                                        {
                                                            !disabled &&
                                                            <td style={{ padding: '5px' }}>
                                                                <div className="input-group">
                                                                    <input
                                                                        disabled={disabled}
                                                                        style={{ border: 'none' }}
                                                                        type="number"
                                                                        className="form-control"
                                                                        placeholder='Number of lots'
                                                                        value={item.lots !== 0 && `${item.lots}`}
                                                                        onChange={e => updateBidDetails(key, { lots: parseInt(e.target.value) })} />
                                                                </div>
                                                            </td>
                                                        }
                                                        <td style={{ height: '40px', padding: '0px' }}>
                                                            <label className="label itemCenterFullHeight">
                                                                <input
                                                                    disabled={true}
                                                                    style={{ border: 'none' }}
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder='Number of lots'
                                                                    value={item.quantity}
                                                                    onChange={e => updateBidDetails(key, { lots: parseInt(e.target.value) })} />
                                                            </label>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                    :
                                    "No previously deleted Bids to show"
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                (activeBids && Object.values(activeBids).length < 3 && !disabled) &&
                <div className='p-1'>
                    <button onClick={() => createNewEntry()} type="button" className="btn btn-dark">
                        Add more bids
                    </button>
                </div>
            }
        </div>
    </>);
}

export default BidEntries