import React from "react";
import { currencyFormat } from "../../util/helper";

function TransactionsTable({ transactionsList, ...props }) {
  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Deposit Initiated On</th>
            <th scope="col">Status</th>
            <th scope="col">Payment Method</th>
            <th scope="col">Reference ID</th>
            <th scope="col" className="text-end">{`Amount (₹)`}</th>
          </tr>
        </thead>
        <tbody>
          {transactionsList.map(
            (
              {
                txTime,
                orderAmount,
                txStatus,
                paymentMode,
                referenceId,
                orderId,
              },
              index
            ) => (
              <>
                {referenceId && (
                  <tr key={orderId} className="borderless-last-tr">
                    <th scope="row">{txTime}</th>
                    <td>{txStatus}</td>
                    <td>{paymentMode}</td>
                    <td>{referenceId}</td>
                    <td className="text-end text-primary">
                      {currencyFormat(orderAmount)}
                    </td>
                  </tr>
                )}
              </>
            )
          )}
        </tbody>
      </table>
    </div>
  );
}

export default TransactionsTable;
