export const issueTitles = [{
    key: "opendatetime",
    label: "Issue open"
},
{
    key: "closedatetime",
    label: "Issue end"
},
{
    key: "",
    label: "Issue size"
},
{
    key: "issueprice",
    label: "Issue price"
},
{
    key: "tradinglot",
    label: "Trading lot"
},
{
    key: "tbd",
    label: "Market lot"
},
{
    key: "tickprice",
    label: "Tick size"
},
{
    key: "minbidqty",
    label: "Min. order qty"
},
{
    key: "discountvalue",
    label: "Discount"
}]


export const myIpos = [
    {
        "_id": "61c42ff73882060008450552",
        "status": null,
        "message": null,
        "user_id": "60e71932c6c9e1000731b52f",
        "created_ts": "1640247287488",
        "scripid": "BRNDBUCKET",
        "applicationno": "f9bf6e8c760873b0",
        "category": "IND",
        "applicantname": "Ricky Jacob",
        "depository": "CDSL",
        "dpid": "0",
        "clientbenfid": "1208800000009167",
        "chequereceivedflag": "n",
        "chequeamount": "110000",
        "panno": "AZRPJ7393K",
        "bankname": "8888",
        "location": "upiidl",
        "accountnumber_upiid": "amalragc@ybl",
        "ifsccode": "",
        "referenceno": "",
        "asba_upiid": "1",
        "statuscode": "0",
        "statusmessage": "",
        "bids": [
            {
                "bidid": "236708639",
                "quantity": "2000",
                "rate": "55",
                "cuttoffflag": "1",
                "orderno": "5396202370952efc",
                "actioncode": "n",
                "errorcode": "0",
                "message": "BID ENTRY ACCEPTED. BID ID 236708639"
            },
            {
                "bidid": "236708639",
                "quantity": "2000",
                "rate": "55",
                "cuttoffflag": "1",
                "orderno": "5396202370952efc",
                "actioncode": "n",
                "errorcode": "1",
                "message": "BID ENTRY ACCEPTED. BID ID 236708639"
            }
        ]
    },
    {
        "_id": "61c42ff73882060008450552",
        "status": null,
        "message": null,
        "user_id": "60e71932c6c9e1000731b52f",
        "created_ts": "1640247287488",
        "scripid": "BRNDBUCKET",
        "applicationno": "f9bf6e8c760873b0",
        "category": "IND",
        "applicantname": "Ricky Jacob",
        "depository": "CDSL",
        "dpid": "0",
        "clientbenfid": "1208800000009167",
        "chequereceivedflag": "n",
        "chequeamount": "110000",
        "panno": "AZRPJ7393K",
        "bankname": "8888",
        "location": "upiidl",
        "accountnumber_upiid": "amalragc@ybl",
        "ifsccode": "",
        "referenceno": "",
        "asba_upiid": "1",
        "statuscode": "0",
        "statusmessage": "",
        "bids": [
            {
                "bidid": "236708639",
                "quantity": "2000",
                "rate": "55",
                "cuttoffflag": "1",
                "orderno": "5396202370952efc",
                "actioncode": "n",
                "errorcode": "1",
                "message": "BID ENTRY ACCEPTED. BID ID 236708639"
            },
            {
                "bidid": "236708639",
                "quantity": "2000",
                "rate": "55",
                "cuttoffflag": "1",
                "orderno": "5396202370952efc",
                "actioncode": "n",
                "errorcode": "1",
                "message": "BID ENTRY ACCEPTED. BID ID 236708639"
            }
        ]
    },
    {
        "_id": "61c42ff73882060008450552",
        "status": null,
        "message": null,
        "user_id": "60e71932c6c9e1000731b52f",
        "created_ts": "1640247287488",
        "scripid": "BRNDBUCKET",
        "applicationno": "f9bf6e8c760873b0",
        "category": "IND",
        "applicantname": "Ricky Jacob",
        "depository": "CDSL",
        "dpid": "0",
        "clientbenfid": "1208800000009167",
        "chequereceivedflag": "n",
        "chequeamount": "110000",
        "panno": "AZRPJ7393K",
        "bankname": "8888",
        "location": "upiidl",
        "accountnumber_upiid": "amalragc@ybl",
        "ifsccode": "",
        "referenceno": "",
        "asba_upiid": "1",
        "statuscode": "0",
        "statusmessage": "",
        "bids": [
            {
                "bidid": "236708639",
                "quantity": "2000",
                "rate": "55",
                "cuttoffflag": "1",
                "orderno": "5396202370952efc",
                "actioncode": "n",
                "errorcode": "0",
                "message": "BID ENTRY ACCEPTED. BID ID 236708639"
            },
            {
                "bidid": "236708639",
                "quantity": "2000",
                "rate": "55",
                "cuttoffflag": "1",
                "orderno": "5396202370952efc",
                "actioncode": "n",
                "errorcode": "0",
                "message": "BID ENTRY ACCEPTED. BID ID 236708639"
            }
        ]
    }
]