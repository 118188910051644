import { query, mutation } from "../../api";
export async function getStockPortfolios() {
  return mutation(
    "get_smart_portfolios",
    {},
    `smart_portfolio_data {
      smart_portfolio_id
      name
      current_price
      min_price
      max_price
    }`,
    "admin"
  );
}

export async function toggleRoboMode(robo_mode) {
  console.log("robo_mode", robo_mode);
  return mutation(
    "toggle_robo_mode",
    { robo_mode },
    ` _id
    mobile
    robo_mode`,
    "signin"
  );
}
