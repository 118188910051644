import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { renderRedirect } from "../util";
class ExternalLogin extends Component {
    async componentDidMount() {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let token = params.get('token');
        localStorage.setItem("token", token);
        renderRedirect(this.props, "/home");
    }
    render() {
        return (
            <div class="row">
                Redirecting
            </div>
        )
    }
}
export default withRouter(ExternalLogin);