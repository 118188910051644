import React from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";

import { renderRedirect } from "../../util";
import { getProfileData } from "./api";
import LoadingContent from "../../Components/loading-content";
import ConfirmationPage from "./Confirmation";

class NomineesList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoader: true,
            activeCard: "1",
            nominees: [],
        };
    }

    async componentDidMount() {
        let profileData = await getProfileData();
        if (profileData?.data) {
            let data = profileData?.data?.fetch_signup_application;

            if (data?.nominee_consent === false) {
                toast.error("Your consent has already been updated");
                return this._renderRedirect();
            }

            this.setState({
                nominees: data?.nominees ?? [],
                showLoader: false,
            });
        }
    }

    _renderRedirect = (url = "") => {
        renderRedirect(this.props, url === "" ? "/profile" : "/" + url);
    };

    render() {
        return (
            <div id="appCapsule">
                <LoadingContent showLoader={this.state.showLoader}>
                    <ConfirmationPage data={this.state.nominees} />
                    <br />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <h3 className="section-title">For any changes or updation, please contact care@trdr.money</h3>
                    </div>
                </LoadingContent>
            </div>
        );
    }
}
export default withRouter(NomineesList);
