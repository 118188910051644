import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import LoadingContent from "../../Components/loading-content";
import { getFaqs } from "./api";
class Support extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faqs: [],
      showLoader: true,
    };
  }
  async componentDidMount() {
    let response = await getFaqs();
    let faqs =
      response.data.get_faq != null && response.data.get_faq.faq_info != null
        ? response.data.get_faq.faq_info
        : [];
    this.setState({ faqs, showLoader: false });
  }
  render() {
    return (
      <div id="appCapsule">
        <div className="section mt-2">
          <div className="section-title">FAQS</div>
          <LoadingContent showLoader={this.state.showLoader}>
            {this.state.faqs != null && this.state.faqs.length !== 0 ? (
              <>
                {this.state.faqs.map(({ topic_id, topic, subtopic }) => (
                  <div
                    className="wallet-card mt-2"
                    key={`topic-key-${topic_id}`}
                  >
                    <div className="card-body p-0">
                      <h4 className="card-title">{`${topic_id} ${topic}`}</h4>

                      <div
                        className="accordion mt-1"
                        id={`accordion-${topic_id}`}
                      >
                        {subtopic &&
                          subtopic.map(
                            ({
                              subtopic_number,
                              subtopic_title,
                              steps_title,
                              details,
                              steps,
                            }) => (
                              <div className="accordion-item">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#accordion-desc-${topic_id}${subtopic_number}`}
                                  >
                                    {`${topic_id}.${subtopic_number} ${
                                      subtopic_title ?? ""
                                    }`}
                                  </button>
                                </h2>
                                <div
                                  id={`accordion-desc-${topic_id}${subtopic_number}`}
                                  className="accordion-collapse collapse"
                                  data-bs-parent={`#accordion-${topic_id}`}
                                >
                                  <div className="accordion-body">
                                    <p>{details ?? ""}</p>
                                    <h4>{steps_title ?? ""}</h4>
                                    <ul className="primary">
                                      {steps &&
                                        steps.map(
                                          ({ step_details, step_number }) => (
                                            <li
                                              key={`${topic_id}${subtopic_number}${step_number}`}
                                            >
                                              <p>{step_details}</p>
                                            </li>
                                          )
                                        )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              "No FAQs"
            )}
          </LoadingContent>
        </div>
        <div className="section mt-2 mb-2">
          <div className="wallet-card">
            <div className="card-body">
              <div className="p-1">
                <div className="text-center">
                  <h2 className="text-primary mb-2">Support</h2>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://m.me/trdr.club"
                    className="btn btn-facebook btn-icon me-05"
                  >
                    <ion-icon name="logo-facebook"></ion-icon>
                  </a>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://wa.me/919341060007"
                    className="btn btn-whatsapp btn-icon me-05"
                  >
                    <ion-icon name="logo-whatsapp"></ion-icon>
                  </a>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://twitter.com/TRDR_club"
                    className="btn btn-twitter btn-icon me-05"
                  >
                    <ion-icon name="logo-twitter"></ion-icon>
                  </a>

                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.linkedin.com/company/trdr-money/"
                    className="btn btn-linkedin btn-icon me-05"
                  >
                    <ion-icon name="logo-linkedin"></ion-icon>
                  </a>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="mailto: care@trdr.money"
                    className="btn btn-twitch btn-icon me-05"
                  >
                    <ion-icon name="mail"></ion-icon>
                  </a>

                  {/* <a href="#" className="btn btn-instagram btn-icon me-05">
                    <ion-icon name="logo-instagram"></ion-icon>
                  </a>

                  <a href="#" className="btn btn-twitch btn-icon me-05">
                    <ion-icon name="logo-twitch"></ion-icon>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Support);
