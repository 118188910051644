/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, Component } from "react";
import { getProfileData } from "../Profile/api";
import { submit_withdraw_request, get_withdraw_requests } from "./api";
import { spinnerService } from "../../Components/spinner.service";
import LoadingContent from "../../Components/loading-content";
const config = require("../../config");

class WithdrawMoney extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: false,
      withdrawAmount: "",
      errors: {
        withdrawAmount: false,
      },
      showLoading: true,
    };
    this.handleUserInput = this.handleUserInput.bind(this);
    this.withdrawFund = this.withdrawFund.bind(this);
    this.withdrawMoney = this.withdrawMoney.bind(this);
  }

  validateForm() {
    let errors = this.state.errors;
    let isValid = false;
    if (this.state.withdrawAmount) {
      isValid = true;
    }
    this.setState({ isValid });
    return isValid;
  }

  async withdrawMoney(e) {
    let getProfileDataResponse = await getProfileData();
    console.log(getProfileDataResponse);
    if (getProfileDataResponse?.data?.fetch_signup_application) {
      this.setState({
        profileData: getProfileDataResponse?.data?.fetch_signup_application,
      });
    }
  }

  async withdrawFund(e) {
    let withdrawAmount = this.state.withdrawAmount;
    let isValid = this.validateForm();
    if (isValid) {
      spinnerService.show("mainSpinner", "Please wait...");
      let withdrawAmountResponse = await submit_withdraw_request(withdrawAmount);
      spinnerService.hide("mainSpinner");
      if (withdrawAmountResponse?.data?.submit_withdraw_request) {
        alert("Success - Your request for withdrawal has been received!")
      }
      else {
        alert("Error - Please contact TRDR Admin for support.")
      }
    }
  }
  async componentDidMount() {
    //call api function
    const profileDataResponse = await getProfileData();
    const profileData = profileDataResponse?.data?.fetch_signup_application;
    if (profileData) this.setState({ profileData });

    this.setState({
      showLoading: false,
    });
  }

  async handleUserInput(event) {
    const name = event.target.name;
    let value = event.target.value;
    let errors = this.state.errors;
    console.log("Name-" + name + " value=" + value);
    switch (name) {
      case "withdrawAmount":
        let regexp = /^[0-9\b]+$/;
        if (
          value == null ||
          !regexp.test(value) ||
          value > 100000 ||
          value < 0
        ) {
          errors.withdrawAmount = true;
          this.setState({ withdrawAmount: null });
        } else {
          errors.withdrawAmount = false;
          this.setState({ withdrawAmount: value });
        }
        break;
      default:
        this.setState({ [event.target.name]: event.target.value });
        break;
    }
    console.log(errors);
    window.setTimeout(
      function () {
        this.validateForm();
      }.bind(this),
      600
    );
  }

  render() {
    const { t } = this.props;
    const { } = this.state;
    return (
      <>
        <div className="section">
          <LoadingContent showLoader={this.state.showLoading}>
            <div className="wallet-card">
              <div className="balance">
                <div className="left">
                  <span className="title">Withdraw Money</span>
                </div>
                <div className="right">
                  <a
                    href="#"
                    className="button"
                    data-bs-toggle="modal"
                    data-bs-target="#withdrawMoney"
                    onClick={(e) => this.withdrawMoney(e)}
                  >
                    <ion-icon name="add-outline"></ion-icon>
                  </a>
                </div>
              </div>

              <span className="text-10">
                {`Withdraw money from TRDR and credit to Bank ${this.state.profileData?.bank_name ?? ""
                  }`}
              </span>
            </div>
          </LoadingContent>
        </div>
        <div
          className="modal fade action-sheet"
          id="withdrawMoney"
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="row">
              <div className="col col-md-3 d-none d-md-block" />
              <div className="col">
                <div
                  className="modal-content"
                  style={{ borderRadius: "10px 10px 0px 0px" }}
                >
                  <div className="modal-header">
                    <h5 className="modal-title">Withdraw Funds</h5>
                  </div>
                  <div className="modal-body">
                    <div className="action-sheet-content">
                      <form>
                        <div className="form-group basic">
                          <label className="label">
                            Enter amount to withdraw
                          </label>
                          <div className="input-group">
                            <span
                              className="input-group-text"
                              id="basic-addona1"
                            >
                              {" "}
                              ₹{" "}
                            </span>
                            <input
                              type="text"
                              name="withdrawAmount"
                              className="form-control deposit-amount-input"
                              placeholder="Enter an amount"
                              value={this.state.withdrawAmount}
                              onChange={(e) => this.handleUserInput(e)}
                              onBlur={(e) => this.handleUserInput(e)}
                            />
                          </div>
                          <span className="error text-12">
                            Please enter amount between ₹1 - ₹100,000
                          </span>
                          {/* {this.state.errors.withdrawAmount && (
                            <span className="error text-12">
                              Please enter amount between ₹1,000 - ₹100,000
                            </span>
                          )} */}
                        </div>
                        <div className="row">
                          <div className="col col-6">
                            <div className="form-group basic">
                              <button
                                type="button"
                                className="btn btn-outline-primary btn-block btn-lg"
                                data-bs-toggle="modal"
                                data-bs-target="#withdrawMoney"
                              >
                                CLOSE
                              </button>
                            </div>
                          </div>
                          <div className="col col-6">
                            <div className="form-group basic">
                              <button
                                type="button"
                                className="btn btn-primary btn-block btn-lg"
                                data-bs-dismiss="modal"
                                disabled={!this.state.isValid}
                                onClick={(e) => this.withdrawFund(e)}
                              >
                                SUBMIT
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-md-3 d-none d-md-block" />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WithdrawMoney;
