/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter,
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import { GuardProvider, GuardedRoute } from "react-router-guards";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./Pages/Home/Home";
import { Spinner } from "./Components/spinner";
import MenuBar from "./Components/menu-bar";
import StaticContent from "./Components/static-content";
import Footer from "./Components/footer";
import Header from "./Components/header";
import { requireLogin } from "./util/login";
import React, { Component, Suspense } from "react";
import "./i18n";
import LanguageSelector from "./Pages/LanguageSelector/LanguageSelector";
import { createBrowserHistory } from "history";
import TransactionResponse from "./Pages/CashFree/TransactionResponse";
import Portfolio from "./Pages/Portfolio/Portfolio";
import Stock from "./Pages/Stock/Stock";
import Reports from "./Pages/Reports/Reports";
import Account from "./Pages/Account/Account";
import Support from "./Pages/Support/Support";
import Profile from "./Pages/Profile/Profile";
import Terms from "./Pages/Terms/Terms";
import About from "./Pages/About/About";
import Notification from "./Pages/Notification/Notification";
import MobileOTP from "./Pages/Mobile/MobileOTP";
import VerifyMobileOTP from "./Pages/Mobile/VerifyMobileOTP";
import VerifyEmailOTP from "./Pages/Mobile/VerifyEmailOTP";
import Settings from "./Pages/Settings/Settings";
import Welcome from "./Pages/Welcome/Welcome";
import MenuSideBar from "./Components/menu-sidebar";
import NotFoundPage from "./Pages/404/NotFoundPage";
import routes from "./routes";
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHome: false,
    };
  }

  componentWillMount() {
    const { history } = this.props;
    this.unsubscribeFromHistory = history.listen(this.handleLocationChange);
    //this.handleLocationChange(history.location);
  }

  componentWillUnmount() {
    if (this.unsubscribeFromHistory) this.unsubscribeFromHistory();
  }

  handleLocationChange = (location) => {
    this.setState({
      isHome: ["/signup", "/signup/"].includes(location.pathname.toLowerCase())
        ? true
        : false,
    });
  };

  render() {
    let currentPageObj = null;

    routes.forEach((obj) => {
      if (obj.path === this.props.history.location.pathname)
        currentPageObj = obj;
    });

    const showHeaderAndMenu = Boolean(currentPageObj?.needAuth);

    const isSpecifiedRoute = Boolean(currentPageObj);

    console.log(
      "isHome",
      this.props.history.location.pathname,
      currentPageObj,
      showHeaderAndMenu,
      "isSpecifiedRoute",
      isSpecifiedRoute
    );

    return (
      <Suspense fallback={null}>
        <ToastContainer
                    autoClose={3000}
                    position="top-center"
                    closeButton={false}
                    toastStyle={{ backgroundColor: "black", color: "white" }}
                />
        <div>
        <Spinner name="mainSpinner">
          <div className="lds-ellipsis-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </Spinner>
          <div id="loader">
            <div className="lds-ripple">
              <div></div>
              <div></div>
            </div>
          </div>
          {showHeaderAndMenu && (
            <div className="row">
              <div className="col col-lg-12">
                <Header />
              </div>
              {/* hide on lg and wider screens */}
              <div className="col d-lg-none">
                <Header showMenuOutLine />
              </div>
            </div>
          )}

          <div
            className="row"
            style={{
              height: "100vh",
              marginLeft: "unset",
              marginRight: "unset",
            }}
          >
            {showHeaderAndMenu && (
              <div
                className="col col-lg-2 d-none d-lg-block"
                style={{
                  height: "100%",
                  overflow: "hidden",
                  borderRight: "1px solid lightgrey",
                }}
              >
                <MenuSideBar />
              </div>
            )}
            {isSpecifiedRoute && (
              <div
                className="col"
                style={{
                  height: "100%",
                  overflow: "scroll",
                }}
              >
                <GuardProvider guards={[requireLogin]}>
                  <Switch>
                    {routes.map(({ path, component, needAuth }) => (
                      <GuardedRoute
                        exact
                        key={path}
                        path={path}
                        component={component}
                        meta={{ auth: needAuth }}
                      />
                    ))}
                  </Switch>
                </GuardProvider>
              </div>
            )}
            {!isSpecifiedRoute && (
              <div className="col p-0">
                <NotFoundPage />
              </div>
            )}
          </div>
          {showHeaderAndMenu && (
            <div className="row">
              <div className="col d-md-none">
                <Footer />
              </div>
            </div>
          )}

          <div
            className="modal inset fade action-sheet ios-add-to-home"
            id="ios-add-to-home-screen"
            tabIndex="-1"
            role="dialog"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Add to Home Screen</h5>
                  <a href="#" className="close-button" data-bs-dismiss="modal">
                    <ion-icon name="close"></ion-icon>
                  </a>
                </div>
                <div className="modal-body">
                  <div className="action-sheet-content text-center">
                    <div className="mb-1">
                      <img
                        src="../assets/img/icon/192x192.png"
                        alt="image"
                        className="imaged w64 mb-2"
                      />
                    </div>
                    <div>
                      Install <strong>Finapp</strong> on your iPhone's home
                      screen.
                    </div>
                    <div>
                      Tap <ion-icon name="share-outline"></ion-icon> and Add to
                      homescreen.
                    </div>
                    <div className="mt-2">
                      <button
                        className="btn btn-primary btn-block"
                        data-bs-dismiss="modal"
                      >
                        CLOSE
                      </button>
                    </div>
                  </div>

                  <div
                    className="modal inset fade action-sheet ios-add-to-home"
                    id="ios-add-to-home-screen"
                    tabIndex="-1"
                    role="dialog"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">Add to Home Screen</h5>
                          <a
                            href="#"
                            className="close-button"
                            data-bs-dismiss="modal"
                          >
                            <ion-icon name="close"></ion-icon>
                          </a>
                        </div>
                        <div className="modal-body">
                          <div className="action-sheet-content text-center">
                            <div className="mb-1">
                              <img
                                src="../assets/img/icon/192x192.png"
                                alt="image"
                                className="imaged w64 mb-2"
                              />
                            </div>
                            <div>
                              Install <strong>Finapp</strong> on your iPhone's
                              home screen.
                            </div>
                            <div>
                              Tap <ion-icon name="share-outline"></ion-icon> and
                              Add to homescreen.
                            </div>
                            <div className="mt-2">
                              <button
                                className="btn btn-primary btn-block"
                                data-bs-dismiss="modal"
                              >
                                CLOSE
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    );
  }
}
export default App;
