import React from "react";
import ContentLoader, {
  Facebook,
  Code,
  List,
  BulletList,
} from "react-content-loader";

const style = {
  // height: "50px",
  width: "100%",
};

const LoadingContent = ({ showLoader, contentType = "code", ...props }) => {
  return (
    <>
      {showLoader && (
        <div className="wallet-card">
          {contentType === "facebook" && <Facebook style={style} />}
          {contentType === "content" && <ContentLoader style={style} />}
          {contentType === "code" && <Code style={style} />}
          {contentType === "list" && <List style={style} />}
          {contentType === "bulletList" && <BulletList style={style} />}
        </div>
      )}
      {!showLoader && <>{props?.children ?? null}</>}
    </>
  );
};
export default LoadingContent;
