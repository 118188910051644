import React, { useState } from "react";
import { toast } from "react-toastify";

import { updateConsentToFalse } from "./api";
import { renderRedirect } from "../../util";
import LoadingContent from "../../Components/loading-content";

const ConsentPage = ({ handleYesBtn, props }) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleModal = () => {
        setIsOpen(!modalIsOpen);
    };

    const handleConfirm = async () => {
        handleModal();
        setLoading(true);
        let update = await updateConsentToFalse();
        if (!update?.data) {
            toast.error(update?.errors[0]?.message || "Something Went Wrong");
            return setLoading(false);
        } else {
            toast.success("Thanks. We have updated your account");
            setLoading(false);
            return _renderRedirect();
        }
    };

    const _renderRedirect = (url = "") => {
        renderRedirect(props, url === "" ? "/home" : "/" + url);
    };

    return (
        <>
            <LoadingContent showLoader={loading}>
                <div className="row">
                    <div className="col col-12 col-md-12 col-lg-12">
                        <div className="section mt-2">
                            <div className="stat-box" style={{ borderColor: "black" }}>
                                <div className="row">
                                    <div
                                        className="col col-12 col-md-12 col-lg-12 mt-1"
                                        style={{ display: "flex", justifyContent: "center" }}
                                    >
                                        <h2>Do you want to add a nominee?</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        className="col col-12 col-md-12 col-lg-12 mt-1"
                                        style={{ display: "flex", justifyContent: "center" }}
                                    >
                                        <button
                                            className="btn btn-dark me-1 mb-1"
                                            onClick={() => handleYesBtn()}
                                            disabled={modalIsOpen}
                                        >
                                            Yes
                                        </button>
                                        <button className="btn btn-dark me-1 mb-1" onClick={() => handleModal()}>
                                            No
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {modalIsOpen ? (
                    <div className="row">
                        <div className="col col-12 col-md-12 col-lg-12">
                            <div className="section mt-2">
                                <div className="stat-box" style={{ borderColor: "black" }}>
                                    <div className="row">
                                        <div
                                            className="col col-12 col-md-12 col-lg-12 mt-1"
                                            style={{ display: "flex", justifyContent: "center" }}
                                        >
                                            <h3>
                                                You have clicked "No". By proceeding, nominees will not be added to your
                                                account
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div
                                            className="col col-12 col-md-12 col-lg-12 mt-1"
                                            style={{ display: "flex", justifyContent: "center" }}
                                        >
                                            <button className="btn btn-dark me-1 mb-1" onClick={() => handleModal()}>
                                                CANCEL
                                            </button>
                                            <button className="btn btn-dark me-1 mb-1" onClick={() => handleConfirm()}>
                                                CONFIRM
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </LoadingContent>
        </>
    );
};

export default ConsentPage;
