import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { renderRedirect, workFlowSteps } from "../../util";
import {
  getOTP,
  authenitcateUser,
  getCurrentWorkflow,
  startSignUpFlow,
  startSignUpWorkFlow,
  sendEmailSignin,
} from "./api";
import $ from "jquery";
import { spinnerService } from "../../Components/spinner.service";
import OuterLogo from "../../Components/outer-logo";

class MobileOTP extends Component {
  validEmailRegex = new RegExp(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/);
  validPhoneRegex = new RegExp(/^[0-9\b]+$/);

  constructor(props) {
    super(props);
    this.state = {
      mobile: "",
      formErrors: { mobile: "" },
      mobileValid: false,
      errorMessage: null,
    };
  }

  async componentDidMount() {
    // $("#otp-screen .form-control").keyup(function () {
    //   if (this.value.length === 0) {
    //     $(this).blur().parent().prev().children(".form-control").focus();
    //   } else if (this.value.length === this.maxLength) {
    //     $(this).blur().parent().next().children(".form-control").focus();
    //   }
    // });

    let auth = await authenitcateUser();
    if (auth && auth.data && auth.data.authenticate_user) {
      let user = auth.data.authenticate_user;
      if (user.status === true && user.approval_status === "approved") {
        await startSignUpFlow();
        await startSignUpWorkFlow();
        let workflow = await getCurrentWorkflow();
        if (
          workflow &&
          workflow.data &&
          workflow.data.get_current_workflow &&
          workflow.data.get_current_workflow.current_workflow_step_id
        ) {
          let workFlowRedirect = workFlowSteps();
          let currentWorkflowId =
            workflow.data.get_current_workflow.current_workflow_step_id;
          workFlowRedirect.forEach((res) => {
            if (res._id === currentWorkflowId) {
              this._renderRedirect(res.redirect);
            }
          });
        }
      }
    }
  }

  _renderRedirect = (url = "") => {
    renderRedirect(this.props, url === "" ? "/mobile-otp" : "/" + url);
  };

  _getOTP = async () => {
    spinnerService.show("mainSpinner", "Please wait...");

    // if (this.validEmailRegex.test(this.state.mobile)) {
    //   let response = await sendEmailSignin(this.state.mobile);
    //   localStorage.setItem("email", this.state.mobile);
    //   if (response.data.send_email_signin.status) {
    //     spinnerService.hide("mainSpinner");
    //     this._renderRedirect("/verify-email");
    //   }
    // } else {
    // };
    let otp = await getOTP(this.state.mobile);
    if (!otp?.data) {
      this.setState({
        errorMessage: otp?.errors[0]?.message || "Something Went Wrong",
      });
    }
    console.log("otp", otp);

    if (otp?.data?.get_session_otp?.status) {
      localStorage.setItem("mobile", this.state.mobile);
      localStorage.setItem("session_id", otp.data.get_session_otp?.session_id);
      spinnerService.hide("mainSpinner");
      this._renderRedirect();
    }
  };

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    if (this.state.errorMessage) {
      this.setState({ errorMessage: null });
    }
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let mobileValid = this.state.mobileValid;
    //check if mobile or email
    if (
      // !this.validEmailRegex.test(value) &&
      !(this.validPhoneRegex.test(value) && value.length === 10)
    ) {
      fieldValidationErrors.mobile = "Please enter a valid phone number";
      mobileValid = false;
    } else {
      mobileValid = true;
    }
    this.setState(
      { formErrors: fieldValidationErrors, mobileValid: mobileValid },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({ formValid: this.state.mobileValid });
  }

  errorClass(error) {
    return error.length === 0 ? "" : "is-invalid";
  }

  render() {
    const LoginButton = ({ style }) => (
      <div className="form-button-group  transparent" style={{ ...style }}>
        <button
          type="button"
          disabled={!this.state.formValid}
          onClick={this._getOTP}
          className="btn btn-primary btn-block btn-lg"
        >
          Log in
        </button>
      </div>
    );
    return (
      <div id="appCapsule">
        <OuterLogo />
        <div className="section mt-2 text-center">
          <h2>Sign in to your account</h2>
          {/* <h4>Fill the form to log in</h4> */}
        </div>
        <div className="row">
          <div className="col col-md-4 d-none d-md-block" />
          <div className="col">
            <div className="section mb-5 p-2">
              {this.state.errorMessage && (
                <div className="alert alert-danger" role="alert">
                  {this.state.errorMessage}
                </div>
              )}
              <div>
                <div className="card-body pb-1">
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="email1">
                        Mobile Number
                      </label>
                      <input
                        className={`login-mobile-input form-control   floating-label-field floating-label-field--s1`}
                        name="mobile"
                        required
                        placeholder="Enter mobile number"
                        value={this.state.mobile}
                        onChange={(event) => this.handleUserInput(event)}
                        autoFocus={true}
                      />
                      <i className="clear-input">
                        <ion-icon
                          name="close-circle"
                          role="img"
                          className="md hydrated"
                          aria-label="close circle"
                        ></ion-icon>
                      </i>
                    </div>
                    <div className="input-info">
                      Enter your 10 digit registered mobile number
                    </div>
                    <h5 className="mt-1">
                      Don’t have a TRDR account?{" "}
                      <a
                        href={process.env.REACT_APP_SIGNUP_URL}
                        style={{ color: "#0d6efd" }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Sign up
                      </a>{" "}
                      for TRDR.
                    </h5>
                  </div>
                </div>

                {/* <div className="form-links mt-2">
                <div>
                  <a href="app-register.html">Sign Up</a>
                </div>
              </div> */}
              </div>
            </div>
          </div>
          <div className="col col-md-4 d-none d-md-block" />
        </div>
        <div className="row">
          <div className="col col-md-4 d-none d-md-block" />
          <div className="col d-none d-md-block">
            <LoginButton style={{ position: "unset" }} />
          </div>
          <div className="col col-md-4 d-none d-md-block" />
        </div>
        <div className="row">
          <div className="col d-md-none">
            <LoginButton />
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(MobileOTP);
