import { query, mutation } from "../../api";


export async function updateNotificationStatus(id){
    return mutation("mark_notification_as_read", {"notification_id": id}, 
     `success
      message`,
      "notification");
}
export async function getNotifications() {
    // return {
    //     "data": {
    //       "fetch_notifications": {
    //         "notification_info": [
    //           {
    //             "_id": "60b71cc2c60f0c215b223fb8",
    //             "status": "read",
    //             "user_id": "60a76dfe7781d03514985914",
    //             "type": "info",
    //             "title": "test7",
    //             "text": "this is for testing",
    //             "details": "hola hola",
    //             "created_ts": "1622613186191"
    //           },
    //           {
    //             "_id": "60b71cbec60f0c215b223fb7",
    //             "status": "read",
    //             "user_id": "60a76dfe7781d03514985914",
    //             "type": "info",
    //             "title": "test6",
    //             "text": "this is for testing",
    //             "details": "hola hola",
    //             "created_ts": "1622613182756"
    //           },
    //           {
    //             "_id": "60b71cbbc60f0c215b223fb6",
    //             "status": "unread",
    //             "user_id": "60a76dfe7781d03514985914",
    //             "type": "info",
    //             "title": "test5",
    //             "text": "this is for testing",
    //             "details": "hola hola",
    //             "created_ts": "1622613179533"
    //           },
    //           {
    //             "_id": "60b71cb8c60f0c215b223fb5",
    //             "status": "read",
    //             "user_id": "60a76dfe7781d03514985914",
    //             "type": "info",
    //             "title": "test4",
    //             "text": "this is for testing",
    //             "details": "hola hola",
    //             "created_ts": "1622613176382"
    //           },
    //           {
    //             "_id": "60b71cb3c60f0c215b223fb4",
    //             "status": "read",
    //             "user_id": "60a76dfe7781d03514985914",
    //             "type": "info",
    //             "title": "test3",
    //             "text": "this is for testing",
    //             "details": "hola hola",
    //             "created_ts": "1622613171546"
    //           }
    //         ]
    //       }
    //     }
    //   };
    return query(
      "fetch_notifications",
      {},
      `notification_info {
        _id
        status
        user_id
        type
        title
        text
        details
        created_ts
      }`,
      "notification"
    );
  }
  