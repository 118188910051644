import { query, mutation } from "../../api";
export async function getFaqs() {
  return query(
    "get_faq",
    {},
    `faq_info {
        topic_id
        topic
        subtopic {
          subtopic_number
          subtopic_title
          details
          steps_title 
          steps {
            step_number
            step_details
          }
        }
      }`,
    "admin"
  );
}
