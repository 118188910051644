import { GQLNow } from "gql-now";
const configApp = require("../config");

export function graphQLObj(type = "") {
  let reqHeader = { "x-trdr-org": configApp.REACT_APP_HEADER_ORG, crossDomain: true };
  if (
    localStorage.getItem("token") &&
    localStorage.getItem("token") != null &&
    localStorage.getItem("token") != "null" &&
    localStorage.getItem("token") != "undefined"
  ) {
    reqHeader["x-access-token"] = localStorage.getItem("token");
  }

  let REACT_APP_API_URL = configApp.REACT_APP_API_URL;
  switch (type) {
    case "file":
      REACT_APP_API_URL = REACT_APP_API_URL + "/trdr-file-services";
      break;
    case "email":
      REACT_APP_API_URL = REACT_APP_API_URL + "/trdr-email-services";
      break;
    case "digio":
      REACT_APP_API_URL = REACT_APP_API_URL + "/trdr-digio-services";
      break;
    case "admin":
      REACT_APP_API_URL = REACT_APP_API_URL + "/trdr-admin-services";
      break;
    case "signin":
      REACT_APP_API_URL = REACT_APP_API_URL + "/trdr-member-signin-services";
      break;
    case "notification":
      REACT_APP_API_URL = REACT_APP_API_URL + "/trdr-notification-services";
      break;
    case "cashfree":
      REACT_APP_API_URL = REACT_APP_API_URL + "/trdr-cashfree-services";
      break;
    case "eipo":
      REACT_APP_API_URL = REACT_APP_API_URL + "/trdr-eipo-services";
      break;
    default:
      REACT_APP_API_URL = REACT_APP_API_URL + "/trdr-member-services";
      break;
  }

  return new GQLNow(REACT_APP_API_URL, reqHeader);
}

export async function query(operation_name, field, response_field, type = "") {
  return await graphQLObj(type).query(operation_name, field, response_field);
}

export async function mutation(operation_name, field, response_field, type = "") {
  return await graphQLObj(type).mutation(operation_name, field, response_field);
}
