import React, { useState } from "react";
import CollapseCard from "./CollapseCard";
import ConfirmForm from "./ConfirmForm";

const ConfirmationPage = ({ data, allActive = false }) => {
    const [active, setActive] = useState("1");

    const handleActiveCard = (value) => {
        if (value === active) {
            setActive("0");
        } else {
            setActive(value);
        }
    };

    return (
        <>
            {data.map((e, i) => {
                return (
                    <CollapseCard
                        key={i}
                        active={allActive ? allActive : active === e.nominee_number}
                        onClick={allActive ? null : () => handleActiveCard(e.nominee_number)}
                        title={`Nominee ${e.nominee_number}`}
                        content={<ConfirmForm data={e} />}
                    />
                );
            })}
        </>
    );
};

export default ConfirmationPage;
