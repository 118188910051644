import React, { useEffect, useState, useLayoutEffect } from "react";

import { cutDecimals } from "../../../util/helper";

const BidEntriesNew = ({
  currentIpo,
  activeBids,
  setActiveBids,
  investorType,
  setInvestorType,
}) => {
  const updateActiveBids = (data, id) => {
    let updatedEntry = { ...activeBids[id], ...data };
    let newArr = activeBids.map((item, key) => (key === id ? updatedEntry : item));
    console.log(data);
    setActiveBids(newArr);
  };
  const [investorTypes, setInvestorTypes] = useState([]);
  const demoTypes = {
    IND: "INDIVIDUAL",
    CO: "BODIES CORPORATE",
    EMP: "EMPLOYEE",
    POL: "POLICY HOLDER",
    OTH: "OTHERS",
  };

  const createNewEntry = () => {
    let entry = {
      cuttoff: "1",
      rate: currentIpo[0].ceilingprice,
      lots: 1,
      quantity: currentIpo[0].minbidqty,
    };
    let newArr = [...activeBids, entry];
    setActiveBids(newArr);
  };

  const setCutoff = (id, status) =>
    updateActiveBids(
      {
        cuttoff: status,
        rate: status === "1" ? currentIpo[0].ceilingprice : currentIpo[0].floorprice,
      },
      id
    );

  const updateLots = (id, lots) =>
    updateActiveBids(
      {
        lots: lots,
        quantity: parseInt(lots) * parseInt(currentIpo[0].minbidqty),
      },
      id
    );

  const filterInvestorTypes = (array) => [...new Set(array.map((item) => item.category))];

  const totalAmountCalculator = () => {
    let totalPrices = activeBids.map((item) => parseFloat(item.rate) * parseFloat(item.quantity));
    return Math.max(...totalPrices);
  };

  useEffect(() => {
    activeBids.length < 3 && createNewEntry();
  }, [currentIpo]);

  useEffect(() => {
    totalAmountCalculator();
  }, [activeBids]);

  useEffect(() => setInvestorTypes(filterInvestorTypes(currentIpo)), [currentIpo]);

  return (
    <>
      <div className="table-responsive">
        <div className="row">
          <div className="col-md-6" style={{ display: "flex" }}>
            <label style={{ display: "flex", alignItems: "center", paddingRight: "1rem" }}>
              Investor type
            </label>
            <select
              className="form-select"
              value={demoTypes.investorType}
              onChange={(e) => setInvestorType(e.target.value)}
              style={{ flex: 1 }}>
              {Object.keys(demoTypes).map((item) => (
                <option value={item} key={item}>
                  {item}-{demoTypes[item]}
                </option>
              ))}
            </select>
            {/* <select
              className="form-select"
              value={investorType}
              onChange={(e) => setInvestorType(e.target.value)}
              style={{ flex: 1 }}>
              {investorTypes.map((item) => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))}
            </select> */}
          </div>
        </div>
        <table className="table tableIpo">
          <thead>
            <tr className="bidsTable">
              <th scope="col">No.</th>
              <th scope="col">
                Cutoff Price
                <p>{currentIpo && cutDecimals(currentIpo[0].cuttoff, 2)}</p>
              </th>
              <th scope="col">
                Price
                <p>
                  {" "}
                  {cutDecimals(currentIpo[0].floorprice, 2)} -{" "}
                  {cutDecimals(currentIpo[0].ceilingprice, 2)}{" "}
                </p>
              </th>
              <th scope="col">No. of lots</th>
              <th scope="col" className="text-end">
                Total shares
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.values(activeBids).map((item, key) => (
              <tr key={key}>
                <th scope="row" style={{ height: "40px" }}>
                  <label className="label itemCenterFullHeight">1</label>
                </th>
                <td style={{ padding: "5px" }}>
                  <div className="input-group itemCenterFullHeight">
                    <input
                      checked={item.cuttoff === "1"}
                      onChange={(e) => setCutoff(key, e.target.checked ? "1" : "0")}
                      style={{ height: 30, width: 30 }}
                      type="checkbox"
                      className="form-check-input"
                    />
                  </div>
                </td>
                <td style={{ padding: "5px" }}>
                  <div className="input-group">
                    <input
                      disabled={item.cuttoff === "1"}
                      value={item.rate}
                      onChange={(e) => updateActiveBids({ rate: e.target.value }, key)}
                      style={{ border: "none" }}
                      type="number"
                      className="form-control"
                      placeholder="Price"
                    />
                  </div>
                </td>
                <td style={{ padding: "5px" }}>
                  <div className="input-group">
                    <input
                      value={item.lots}
                      onChange={(e) => updateLots(key, e.target.value)}
                      style={{ border: "none" }}
                      type="number"
                      className="form-control"
                      placeholder="Number of lots"
                    />
                  </div>
                </td>
                <td className="text-end text-primary" style={{ height: "40px", padding: "0px" }}>
                  <label className="label itemCenterFullHeight">
                    <input
                      value={item.quantity}
                      disabled={true}
                      style={{ border: "none" }}
                      type="number"
                      className="form-control"
                      placeholder="Total shares"
                    />
                  </label>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="col-sm-12 pt-1" style={{ color: "black", fontSize: 14 }}>
          Total amount:{totalAmountCalculator()}
        </div>
        {activeBids && Object.values(activeBids).length < 3 && (
          <div className="p-1">
            <button onClick={() => createNewEntry()} type="button" className="btn btn-dark">
              Add more bids
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default BidEntriesNew;