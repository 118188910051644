/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import LoadingContent from "../../Components/loading-content";
import { getHomePageData } from "../Home/api";
import AddMoneyActionSheet from "./AddMoneyActionSheet";
import LedgerBalance from "./LedgerBalance";
import PassBook from "./PassBook";
import WithdrawMoney from "./WithdrawMoney";

const Account = () => {
  return (
    <div id="appCapsule">
      <div className="section mt-2">
        <div className="mt-3 text-left">
          <div className="section-title pb-0">Account</div>
          {/* <span className="text-10">Stocks as on T-2</span> */}
        </div>
      </div>
      <div className="row">
        <div className="col col-12 col-md-4 mt-2">
          <AddMoneyActionSheet />
        </div>
        <div className="col col-12 col-md-4 mt-2">
          <LedgerBalance />
        </div>
        <div className="col col-12 col-md-4 mt-2">
          <WithdrawMoney />
        </div>
      </div>

      <div className="row mt-2">
        <div className="col col-12">
          <PassBook />
        </div>
      </div>
    </div>
  );
};
export default withRouter(Account);
