import { query, mutation } from "../../api";
export async function getProfileData() {
  return mutation(
    "fetch_signup_application",
    {},
    `_id
    status
    message
    user_id
    fullName
    firstName
    lastName
    emailAddress
    gender
    dob
    pan
    martialStatus
    residentialStatus
    permanentAddress
    communicationAddress
    fathersName
    mothersName
    occupationType
    annualIncome
    isPoliticalyExposed
    pan_front
    cheque_image
    signature_image
    profile_image
    latitude
    longitude
    razorpay_order_id
    account_number
    ifsc
    account_type
    penny_drop_status
    bank_name
    account_status
    nominee_consent
    nominees{
      nominee_number
      percentage_of_share
    }`
  );
}
export async function getMemberData() {
  return query(
    "get_member_data",
    {},
    `status
    _id
    name
    mobile
    approval_status
    org_id
    roles
    dp_id
    ucc
    robo_mode
    trdr_id
    demat_acc_number
    depository_participant
    depository_name`,
    "signin"
  );
}
