/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, Component } from "react";
import { getProfileData } from "../Profile/api";
import { getCashFreeOrder, verifyUPI } from "./api";
import { spinnerService } from "../../Components/spinner.service";
import LoadingContent from "../../Components/loading-content";
const config = require("../../config");

class AddMoneyActionSheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: false,
      depositAmount: "",
      upi_id: "",
      showUpiInput: false,
      paymentMethod: "",
      profileData: {
        bank_name: "",
        ifsc: "",
        account_number: "",
      },
      isUpiIdVerified: false,
      errors: {
        upi_id: false,
        depositAmount: false,
      },
      cashFreeFormOrder: {},

      showLoading: true,
    };
    this.handleUserInput = this.handleUserInput.bind(this);
    this.depositFund = this.depositFund.bind(this);
    this.addMoney = this.addMoney.bind(this);
  }

  validateForm() {
    let errors = this.state.errors;
    let isValid = false;
    if (this.state.depositAmount && this.state.paymentMethod) {
      isValid = true;
      if (this.state.paymentMethod === "upi") {
        isValid =
          this.state.upi_id &&
            this.state.isUpiIdVerified &&
            this.state.isUpiIdValid
            ? true
            : false;
      }
    }
    this.setState({ isValid });
    return isValid;
  }

  async addMoney(e) {
    let getProfileDataResponse = await getProfileData();
    console.log(getProfileDataResponse);
    if (getProfileDataResponse?.data?.fetch_signup_application) {
      this.setState({
        profileData: getProfileDataResponse?.data?.fetch_signup_application,
      });
    }
  }

  async depositFund(e) {
    let depositAmount = this.state.depositAmount;
    let isValid = this.validateForm();
    if (isValid) {
      let fields = {
        amount: depositAmount,
        returnUrl: config.REACT_APP_BASE_URL + "/cashfree/response"
      };
      let selectedPaymentMethod = this.state.paymentMethod;
      fields.payment_mode = this.state.paymentMethod;
      if (selectedPaymentMethod === "upi") {
        fields.upi_id = this.state.upi_id;
      }
      let cashFreeFormOrderResponse = await getCashFreeOrder(fields);
      if (cashFreeFormOrderResponse?.data?.payment_gateway)
        this.setState({
          cashFreeFormOrder: cashFreeFormOrderResponse.data.payment_gateway,
        });
      this.refs.cashFreeForm.submit();
    }
  }
  async componentDidMount() {
    //call api function
    const profileDataResponse = await getProfileData();
    const profileData = profileDataResponse?.data?.fetch_signup_application;
    if (profileData) this.setState({ profileData });

    this.setState({
      showLoading: false,
    });
  }

  async handleUserInput(event) {
    const name = event.target.name;
    let value = event.target.value;
    let errors = this.state.errors;
    console.log("Name-" + name + " value=" + value);
    switch (name) {
      case "depositAmount":
        let regexp = /^[0-9\b]+$/;
        if (
          value == null ||
          !regexp.test(value) ||
          value > 100000 ||
          value < 500
        ) {
          errors.depositAmount = true;
          this.setState({ depositAmount: null });
        } else {
          errors.depositAmount = false;
          this.setState({ depositAmount: value });
        }
        break;
      case "upi_id":
        if (value.length === 0) {
          errors.upi_id = true;
          this.setState({ upi_id: null });
        } else {
          errors.upi_id = false;
          this.setState({ upi_id: value });
        }
        this.setState({ isUpiIdVerified: false });
        break;
      case "paymentMethod":
        this.setState({ paymentMethod: value });
        if (value === "upi") this.setState({ showUpiInput: true });
        if (value === "nb") {
          this.setState({ showUpiInput: false });
          errors.upi_id = false;
          this.setState({ upi_id: null });
          this.setState({ isUpiIdVerified: false });
        }
        break;
      case "verifyUPI":
        //Check Status
        spinnerService.show("mainSpinner", "Please wait...");
        let verificationResponse = await verifyUPI(this.state.upi_id);
        spinnerService.hide("mainSpinner");
        let isUpiIdValid = false;
        if (verificationResponse?.data?.validate_upi_id?.status)
          isUpiIdValid = verificationResponse.data.validate_upi_id.status;
        this.setState({ isUpiIdVerified: true });
        this.setState({ isUpiIdValid: isUpiIdValid });
        if (!isUpiIdValid) this.setState({ upi_id: null });
        break;
      default:
        this.setState({ [event.target.name]: event.target.value });
        break;
    }
    console.log(errors);
    window.setTimeout(
      function () {
        this.validateForm();
      }.bind(this),
      600
    );
  }

  render() {
    const { t } = this.props;
    const { showUpiInput, cashFreeFormOrder } = this.state;
    let upiVerificationResponse;
    let upiIdMessage;
    if (this.state.isUpiIdVerified) {
      upiVerificationResponse = this.state.isUpiIdValid ? (
        <span className="error text-12">
          Verified! Please click Add Money to start payment process.
        </span>
      ) : (
        <span className="error text-12">
          Sorry, we could not verify the UPI ID.
        </span>
      );
    } else {
      upiVerificationResponse = "";
    }
    if (this.state.errors.upi_id) {
      upiIdMessage = (
        <span className="error text-12">Please enter a valid UPI ID</span>
      );
    } else {
      if (showUpiInput && this.state.upi_id && !this.state.isUpiIdVerified)
        upiIdMessage = (
          <span className="error text-12">
            Click on verify button to verify the UPI ID
          </span>
        );
    }
    return (
      <>
        <form
          id="cashFreeForm"
          ref="cashFreeForm"
          method="post"
          // action="https://test.cashfree.com/billpay/checkout/post/submit"
          action="https://www.cashfree.com/checkout/post/submit"
        >
          <input
            type="hidden"
            name="accountNumber"
            value={cashFreeFormOrder.accountNumber}
          />
          <input type="hidden" name="appId" value={cashFreeFormOrder.appId} />
          <input
            type="hidden"
            name="customerEmail"
            value={cashFreeFormOrder.customerEmail}
          />
          <input
            type="hidden"
            name="customerName"
            value={cashFreeFormOrder.customerName}
          />
          <input
            type="hidden"
            name="customerPhone"
            value={cashFreeFormOrder.customerPhone}
          />
          <input type="hidden" name="ifsc" value={cashFreeFormOrder.ifsc} />
          <input
            type="hidden"
            name="notifyUrl"
            value={cashFreeFormOrder.notifyUrl}
          />
          <input
            type="hidden"
            name="orderAmount"
            value={cashFreeFormOrder.orderAmount}
          />
          <input
            type="hidden"
            name="orderCurrency"
            value={cashFreeFormOrder.orderCurrency}
          />
          <input
            type="hidden"
            name="orderId"
            value={cashFreeFormOrder.orderId}
          />
          <input
            type="hidden"
            name="orderNote"
            value={cashFreeFormOrder.orderNote}
          />
          {cashFreeFormOrder.paymentOption === "nb" && (
            <input
              type="hidden"
              name="paymentCode"
              value={cashFreeFormOrder.paymentCode}
            />
          )}
          <input
            type="hidden"
            name="paymentOption"
            value={cashFreeFormOrder.paymentOption}
          />
          <input
            type="hidden"
            name="returnUrl"
            value={cashFreeFormOrder.returnUrl}
          />
          {cashFreeFormOrder.paymentOption === "upi" && (
            <input
              type="hidden"
              name="upi_vpa"
              value={cashFreeFormOrder.upi_vpa}
            />
          )}
          <input
            type="hidden"
            name="signature"
            value={cashFreeFormOrder.signature}
          />
        </form>

        <div className="section">
          <LoadingContent showLoader={this.state.showLoading}>
            <div className="wallet-card">
              <div className="balance">
                <div className="left">
                  <span className="title">Add Money to Invest</span>
                </div>
                <div className="right">
                  <a
                    href="#"
                    className="button"
                    data-bs-toggle="modal"
                    data-bs-target="#addMoneyActionSheet"
                    onClick={(e) => this.addMoney(e)}
                  >
                    <ion-icon name="add-outline"></ion-icon>
                  </a>
                </div>
              </div>

              <span className="text-10">
                {`Add money from your ${this.state.profileData?.bank_name ?? ""
                  } account linked to TRDR`}
              </span>
            </div>
          </LoadingContent>
        </div>
        <div
          className="modal fade action-sheet"
          id="addMoneyActionSheet"
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="row">
              <div className="col col-md-3 d-none d-md-block" />
              <div className="col">
                <div
                  className="modal-content"
                  style={{ borderRadius: "10px 10px 0px 0px" }}
                >
                  <div className="modal-header">
                    <h5 className="modal-title">Deposit Funds</h5>
                  </div>
                  <div className="modal-body">
                    <div className="action-sheet-content">
                      <form>
                        <div className="form-group basic">
                          <div className="input-wrapper">
                            <label className="label" htmlFor="account1">
                              {" "}
                              From{" "}
                            </label>
                            <label className="modal-title" htmlFor="account1">
                              {" "}
                              {this.state.profileData.bank_name +
                                "-" +
                                this.state.profileData.account_number}
                            </label>
                          </div>
                        </div>
                        <div className="form-group basic">
                          <label className="label">
                            Enter amount to deposit
                          </label>
                          <div className="input-group">
                            <span
                              className="input-group-text"
                              id="basic-addona1"
                            >
                              {" "}
                              ₹{" "}
                            </span>
                            <input
                              type="text"
                              name="depositAmount"
                              className="form-control deposit-amount-input"
                              placeholder="Enter an amount"
                              value={this.state.depositAmount}
                              onChange={(e) => this.handleUserInput(e)}
                              onBlur={(e) => this.handleUserInput(e)}
                            />
                          </div>
                          <span className="error text-12">
                            Please enter amount between ₹500 - ₹100,000
                          </span>
                          {/* {this.state.errors.depositAmount && (
                            <span className="error text-12">
                              Please enter amount between ₹1,000 - ₹100,000
                            </span>
                          )} */}
                        </div>
                        <div className="form-group basic">
                          <label className="label">
                            Choose a payment mode you prefer
                          </label>
                          <div className="input-group mb-2">
                            <div className="card-body">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="paymentMethod"
                                  id="paymentMethodUPI"
                                  value="upi"
                                  onChange={(e) => this.handleUserInput(e)}
                                />
                                <label
                                  className="form-check-label"
                                  for="paymentMethodUPI"
                                >
                                  UPI
                                </label>
                                {showUpiInput && (
                                  <div className="input-group">
                                    <div>
                                      <input
                                        type="text"
                                        name="upi_id"
                                        className="form-control upi-id-input"
                                        placeholder="Enter UPI ID"
                                        value={this.state.upi_id}
                                        spellCheck="false"
                                        onChange={(e) =>
                                          this.handleUserInput(e)
                                        }
                                        onBlur={(e) => this.handleUserInput(e)}
                                      />
                                    </div>
                                    {
                                      <button
                                        type="button"
                                        class="btn btn-dark me-1 mb-1"
                                        name="verifyUPI"
                                        onClick={(e) => this.handleUserInput(e)}
                                      >
                                        Verify
                                      </button>
                                    }
                                  </div>
                                )}
                                {upiVerificationResponse}
                                {upiIdMessage}
                              </div>

                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="paymentMethod"
                                  id="paymentMethodNetBnk"
                                  value="nb"
                                  onChange={(e) => this.handleUserInput(e)}
                                />
                                <label
                                  className="form-check-label"
                                  for="paymentMethodNetBnk"
                                >
                                  NetBanking
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col col-6">
                            <div className="form-group basic">
                              <button
                                type="button"
                                className="btn btn-outline-primary btn-block btn-lg"
                                data-bs-toggle="modal"
                                data-bs-target="#addMoneyActionSheet"
                              >
                                CLOSE
                              </button>
                            </div>
                          </div>
                          <div className="col col-6">
                            <div className="form-group basic">
                              <button
                                type="button"
                                className="btn btn-primary btn-block btn-lg"
                                data-bs-dismiss="modal"
                                disabled={!this.state.isValid}
                                onClick={(e) => this.depositFund(e)}
                              >
                                ADD MONEY
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-md-3 d-none d-md-block" />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AddMoneyActionSheet;
