/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { renderRedirect, workFlowSteps } from "../../util";
import {
  getOTP,
  getCashFreeOrder,
  getProfileData,
  getHomePageData,
  fetchNotificationLatest,
  verifyUPI,
} from "./api";
import $ from "jquery";
import AddMoneyActionSheet from "../Account/AddMoneyActionSheet";
import { spinnerService } from "../../Components/spinner.service";
import { query, mutation } from "../../api";
import Footer from "../../Components/footer";
import { withTranslation } from "react-i18next";
import { currencyFormat, percentageFormat, pnlFormat } from "../../util/helper";
import { getMemberData } from "../Profile/api";
import { toggleRoboMode } from "../Stock/api";
import LoadingContent from "../../Components/loading-content";

const config = require("../../config");
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      portfolio_value: "",
      fund_invested: "",
      ledger_balance: "",
      dp_holding_value: "",
      ledger_balance_type: "",
      dp_total_value: "",
      previous_longshort_value: "",
      longshort_value: "",
      investment_amount: "",
      payout_amount: "",
      profit_loss_amount: "",
      performance_percentage: "",
      payout_value: "",
      ledger_modified_ts: "",
      portfolio_modified_ts: "",

      mobile: "",
      iaccept: "",

      notificationList: [],
      roboMode: false,

      loader: true,
    };
    this.autoFocus();
  }

  autoFocus = () => {
    $("#first").focus();
  };

  async componentDidMount() {
    //call api function
    let homePageData = await getHomePageData();
    let notificationResponse = await fetchNotificationLatest();
    const memberData = await getMemberData();

    const profileDataResponse = await getProfileData();

    const profileData = profileDataResponse?.data?.fetch_signup_application;

    const notifications =
      notificationResponse?.data?.fetch_notification_by_cateogory
        ?.notification_info || [];
    const notificationList = notifications.slice(0, 5);
    // if (notificationList && notificationList.length > 0)
    //   this.setState({ latestNotification: notificationList[0].text });

    // console.log(homePageData);
    // if (homePageData.data.get_home_page_data.ledger_balance_type != null)
    this.setState({
      portfolio_value: homePageData?.data?.get_home_page_data?.portfolio_value || 0,
      fund_invested: homePageData?.data?.get_home_page_data?.fund_invested || 0,
      ledger_balance: homePageData?.data?.get_home_page_data?.ledger_balance || 0,
      ledger_balance_type: homePageData?.data?.get_home_page_data?.ledger_balance_type || "",
      dp_holding_value: homePageData?.data?.get_home_page_data?.dp_holding_value || 0,
      dp_total_value: homePageData?.data?.get_home_page_data?.dp_total_value || 0,
      previous_longshort_value: homePageData?.data?.get_home_page_data?.previous_longshort_value || 0,
      longshort_value: homePageData?.data?.get_home_page_data?.longshort_value || 0,
      investment_amount: homePageData?.data?.get_home_page_data?.investment_amount || 0,
      payout_amount: homePageData?.data?.get_home_page_data?.payout_amount || 0,
      profit_loss_amount: homePageData?.data?.get_home_page_data?.profit_loss_amount || 0,
      performance_percentage: homePageData?.data?.get_home_page_data?.performance_percentage || 0,
      payout_value: homePageData?.data?.get_home_page_data?.payout_value || 0,
      ledger_modified_ts: homePageData?.data?.get_home_page_data?.ledger_modified_ts || 0,
      portfolio_modified_ts: homePageData?.data?.get_home_page_data?.portfolio_modified_ts || 0,


      notificationList,
      roboMode: memberData?.data?.get_member_data?.robo_mode || false,
      profileData,
      loader: false,
    });
  }

  _renderRedirect = (url = "") => {
    renderRedirect(this.props, url === "" ? "/signup/mobile-otp" : "/" + url);
  };

  _getOTP = async () => {
    //spinnerService.show("mainSpinner", "Please wait...");

    let otp = await getOTP(this.state.mobile).catch((res) => {
      alert(res);
    });
    localStorage.setItem("mobile", this.state.mobile);

    if (otp.data.get_otp.status) {
      spinnerService.hide("mainSpinner");
      this._renderRedirect();
    }
  };

  setRoboOnorOff() {
    if (!this.state.roboMode) {
      renderRedirect(this.props, "/stock");
    } else {
      document.getElementById("roboActionButton").click();
    }
  }

  async roboModeOff() {
    const toggleRoboResponse = await toggleRoboMode(false);
    if (toggleRoboResponse?.data?.toggle_robo_mode?._id) {
      // const memberData = await getMemberData();
      document.getElementById("roboActionButton").click();

      this.setState({
        roboMode: Boolean(
          toggleRoboResponse?.data?.toggle_robo_mode?.robo_mode
        ),
        // roboMode: memberData?.data?.get_member_data?.robo_mode || false,
      });
    }
  }

  render() {
    const { t } = this.props;
    const { showUpiInput, notificationList } = this.state;
    let accountUpdateHeader = null;
    if (notificationList) {
      accountUpdateHeader = notificationList[0];
    }
    const sadHappyImgUrl = `../assets/img/${
      this.state.roboMode ? "happy" : "sad"
    }-robo-face.png`;
    return (
      <div id="appCapsule">
        <button
          style={{ display: "none" }}
          id="roboActionButton"
          data-bs-toggle="modal"
          data-bs-target="#roboModeOffActionSheet"
        />
        <div className="row">
          <div className="col col-12 col-md-6 mt-2">
            <div className="section">
              <LoadingContent showLoader={this.state.loader}>
                <div className="wallet-card" style={{ padding: "15px 24px" }}>
                  <div className="row" style={{ alignItems: "center" }}>
                    <div className="col col-8 text-center">
                      <div className="d-none d-md-block">
                        <div className="row" style={{ alignItems: "center" }}>
                          <div className="col col-6 text-end">
                            <img
                              src={sadHappyImgUrl}
                              alt="happy-robo-face"
                              className="logo"
                              style={{
                                width: "50%",
                              }}
                            />
                          </div>
                          <div className="col col-6 text-start">
                            <span>ROBO MODE</span>
                          </div>
                        </div>
                      </div>
                      <div className="d-md-none">
                        <img
                          src={sadHappyImgUrl}
                          alt="happy-robo-face"
                          className="logo"
                          style={{ width: "50%" }}
                        />
                        <div>ROBO MODE</div>
                      </div>
                    </div>
                    <div className="col col-4 text-start">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="SwitchCheckDefault1"
                          checked={this.state.roboMode}
                          onClick={() => this.setRoboOnorOff()}
                        />
                        <label
                          className="form-check-label"
                          for="SwitchCheckDefault1"
                        ></label>
                      </div>
                    </div>
                  </div>

                  {/* <p className="text-10">We currently do not support Manual Mode. Click above to turn Robo ON. </p> */}
                </div>
              </LoadingContent>
            </div>
          </div>

          <div className="col col-12 col-md-6 mt-2">
            <AddMoneyActionSheet />
          </div>
        </div>

        <div className="row">
          <div className="col col-12 col-md-12 col-lg-12">
            <div className="section mt-2">
              <LoadingContent showLoader={this.state.loader}>
                <div className="stat-box">
                  <div className="row">

                    <div className="col col-6 col-md-6 col-lg-3 mt-2">
                      <div className="title">Investment</div>
                      <div className="value">
                        {currencyFormat(this.state.investment_amount || 0)}
                      </div>
                    </div>

                    <div className="col col-6 col-md-6 col-lg-3 mt-2">
                      <div className="title">Stocks Value</div>
                      <div className="value">
                        {currencyFormat(this.state.dp_total_value || 0)}
                      </div>
                    </div>

                    <div className="col col-6 col-md-6 col-lg-3 mt-2">
                      <div className="title">P/L</div>
                      <div
                        className={
                          this.state.performance_percentage > 0
                            ? "value text-success"
                            : "value text-danger"
                        }>
                        {pnlFormat(this.state.profit_loss_amount || 0)}
                      </div>
                    </div>

                    <div className="col col-6 col-md-6 col-lg-3 mt-2">
                      <div className="title">Performance</div>
                      <div
                        className={
                          this.state.performance_percentage > 0
                            ? "value text-success"
                            : "value text-danger"
                        }>
                        {percentageFormat(this.state.performance_percentage || 0)}
                      </div>
                    </div>

                    <span className="text-10 mt-2">Last updated: {new Intl.DateTimeFormat("en-IN", {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                    }).format(this.state.portfolio_modified_ts)}</span>

                  </div>
                </div>
              </LoadingContent>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col col-12 col-md-12 col-lg-12">
            <div className="section mt-2">
              <LoadingContent showLoader={this.state.loader}>
                <div className="stat-box">
                  <div className="row">

                    <div className="col col-6 col-md-6 col-lg-6 mt-2">
                      <div className="title">Cash Balance</div>
                      <div
                        className={
                          this.state.ledger_balance_type === "CR"
                            ? "value text-success"
                            : "value text-danger"
                        }
                      >
                        {`${currencyFormat(this.state.ledger_balance || 0)} ${this.state.ledger_balance_type
                          }`}
                      </div>
                    </div>

                    <div className="col col-6 col-md-6 col-lg-6 mt-2">
                      <div className="title">Payout</div>
                      <div className={
                        this.state.payout_amount >= 0
                          ? "value text-success"
                          : "value text-danger"
                      }>
                        {currencyFormat(this.state.payout_amount || 0)}
                      </div>
                    </div>

                    <span className="text-10 mt-2">Last updated: {new Intl.DateTimeFormat("en-IN", {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                    }).format(this.state.portfolio_modified_ts)}</span>

                  </div>
                </div>
              </LoadingContent>
            </div>
          </div>
        </div>

        {/* <div className="row"> */}
          {/* <div className="col col-12 col-md-12 col-lg-4">
            <div className="section mt-2">
              <LoadingContent showLoader={this.state.loader}>
                <div className="stat-box">
                  <div className="title">{t("home.labelFund")}</div>
                  <div className="value">
                    {currencyFormat(this.state.portfolio_value || 0)}
                  </div>

                  <span className="text-10">
                    Total sum of stocks value and ledger balance
                  </span>
                </div>
              </LoadingContent>
            </div>
          </div> */}

          {/* <div className="col col-6 col-md-6 col-lg-6">
            <div className="section mt-2">
              <LoadingContent showLoader={this.state.loader}>
                <div className="stat-box">
                  <div className="title">Ledger Balance</div>
                  <div
                    className={
                      this.state.ledger_balance_type === "CR"
                        ? "value text-success"
                        : "value text-danger"
                    }
                  >
                    {`${currencyFormat(this.state.ledger_balance || 0)} ${this.state.ledger_balance_type
                      }`}
                  </div>
                </div>
              </LoadingContent>
            </div>
          </div> */}

          {/* <div className="col col-12 col-md-6 col-lg-6">
            <div className="section mt-2">
              <LoadingContent showLoader={this.state.loader}>
                <div className="stat-box">
                  <div className="title">Stocks Value</div>
                  <div className="value">
                    {currencyFormat(this.state.dp_holding_value || 0)}
                  </div>

                  <span className="text-10">Stocks as on {new Intl.DateTimeFormat("en-IN", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                  }).format(this.state.portfolio_modified_ts)}</span>
                </div>
              </LoadingContent>
            </div>
          </div> */}
        {/* </div> */}

        <div className="row">
          <div className="col col-12 mt-2">
            <div className="section">
              <LoadingContent showLoader={this.state.loader}>
                <div className="wallet-card stat-box">
                  {/* <p className="text-10">We currently do not support Manual Mode. Click above to turn Robo ON. </p> */}
                  <div className="left">
                    <span className="title">Account Updates</span>
                  </div>

                  {accountUpdateHeader ? (
                    <div id="accordionExample1">
                      <div>
                        <div className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#accordion01"
                            aria-expanded="false"
                            style={{
                              fontSize: 14,
                              borderColor: "transparent",
                              boxShadow: "none",
                              color: "black",
                            }}
                          >
                            <div className="content">
                              <h4 className="">
                                {decodeURIComponent(
                                  accountUpdateHeader?.title || ""
                                )}
                              </h4>
                              {/* <div className="text-10">
                              {accountUpdateHeader?.details || ""}
                            </div> */}
                            </div>
                          </button>
                        </div>
                        <div
                          id="accordion01"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample1"
                        >
                          <div
                            className="accordion-body"
                            style={{ padding: "10px 0px" }}
                          >
                            {this.state.notificationList.length > 0 && (
                              <div className="section" style={{ padding: 0 }}>
                                <div className="timeline timed ms-1 me-2">
                                  {this.state.notificationList.length > 0 &&
                                    this.state.notificationList.map(
                                      (notification, index) => (
                                        <div
                                          className="item"
                                          key={notification.created_ts}
                                        >
                                          <span className="time">
                                            {new Intl.DateTimeFormat("en-US", {
                                              year: "2-digit",
                                              month: "2-digit",
                                              day: "2-digit",
                                              hour: "2-digit",
                                              minute: "2-digit",
                                            }).format(notification.created_ts)}
                                          </span>
                                          <div className="dot"></div>
                                          <div className="content">
                                            <h4 className="title">
                                              {decodeURIComponent(
                                                notification?.title || ""
                                              )}
                                            </h4>
                                            <div className="text">
                                              {decodeURIComponent(
                                                notification?.text || ""
                                              )}
                                            </div>
                                            <div className="text">
                                              {decodeURIComponent(
                                                notification?.details || ""
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    )}
                                </div>
                                <button
                                  type="button"
                                  class="btn btn-text-secondary me-1 mb-1"
                                  style={{ paddingLeft: 80 }}
                                  onClick={() =>
                                    this._renderRedirect("notification")
                                  }
                                >
                                  View More
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <span className="ps-1">No Updates</span>
                  )}
                </div>
              </LoadingContent>
            </div>
          </div>
        </div>

        <div
          className="modal fade action-sheet"
          id="withdrawActionSheet"
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Withdraw Money</h5>
              </div>
              <div className="modal-body">
                <div className="action-sheet-content">
                  <form>
                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="account2d">
                          {" "}
                          From{" "}
                        </label>
                        <select
                          className="form-control custom-select"
                          id="account2d"
                        >
                          <option value="0">Savings (*** 5019)</option>
                          <option value="1">Investment (*** 6212)</option>
                          <option value="2">Mortgage (*** 5021)</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="text11d">
                          {" "}
                          To{" "}
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="text11d"
                          placeholder="Enter IBAN"
                        />
                        <i className="clear-input">
                          <ion-icon name="close-circle"></ion-icon>
                        </i>
                      </div>
                    </div>

                    <div className="form-group basic">
                      <label className="label">Enter Amount</label>
                      <div className="input-group mb-2">
                        <span className="input-group-text" id="basic-addonb1">
                          {" "}
                          ${" "}
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter an amount"
                          value="100"
                        />
                      </div>
                    </div>

                    <div className="form-group basic">
                      <button
                        type="button"
                        className="btn btn-primary btn-block btn-lg"
                        data-bs-dismiss="modal"
                      >
                        Withdraw
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade action-sheet"
          id="sendActionSheet"
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Send Money</h5>
              </div>
              <div className="modal-body">
                <div className="action-sheet-content">
                  <form>
                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="account2">
                          {" "}
                          From{" "}
                        </label>
                        <select
                          className="form-control custom-select"
                          id="account2"
                        >
                          <option value="0">Savings (*** 5019)</option>
                          <option value="1">Investment (*** 6212)</option>
                          <option value="2">Mortgage (*** 5021)</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="text11">
                          {" "}
                          To{" "}
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="text11"
                          placeholder="Enter bank ID"
                        />
                        <i className="clear-input">
                          <ion-icon name="close-circle"></ion-icon>
                        </i>
                      </div>
                    </div>

                    <div className="form-group basic">
                      <label className="label">Enter Amount</label>
                      <div className="input-group mb-2">
                        <span className="input-group-text" id="basic-addon1">
                          {" "}
                          ${" "}
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter an amount"
                          value="100"
                        />
                      </div>
                    </div>

                    <div className="form-group basic">
                      <button
                        type="button"
                        className="btn btn-primary btn-block btn-lg"
                        data-bs-dismiss="modal"
                      >
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade action-sheet"
          id="exchangeActionSheet"
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Exchange Money</h5>
              </div>
              <div className="modal-body">
                <div className="action-sheet-content">
                  <form>
                    <div className="row">
                      <div className="col-6">
                        <div className="form-group basic">
                          <div className="input-wrapper">
                            <label className="label" htmlFor="currency1">
                              {" "}
                              From{" "}
                            </label>
                            <select
                              className="form-control custom-select"
                              id="currency1"
                            >
                              <option value="1">EUR</option>
                              <option value="2">USD</option>
                              <option value="3">AUD</option>
                              <option value="4">CAD</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group basic">
                          <div className="input-wrapper">
                            <label className="label" htmlFor="currency2">
                              {" "}
                              To{" "}
                            </label>
                            <select
                              className="form-control custom-select"
                              id="currency2"
                            >
                              <option value="1">USD</option>
                              <option value="1">EUR</option>
                              <option value="2">AUD</option>
                              <option value="3">CAD</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group basic">
                      <label className="label">Enter Amount</label>
                      <div className="input-group mb-2">
                        <span className="input-group-text" id="basic-addon2">
                          {" "}
                          ${" "}
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter an amount"
                          value="100"
                        />
                      </div>
                    </div>

                    <div className="form-group basic">
                      <button
                        type="button"
                        className="btn btn-primary btn-block btn-lg"
                        data-bs-dismiss="modal"
                      >
                        Exchange
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade action-sheet"
          id="roboModeOffActionSheet"
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="row">
              <div className="col col-md-3 d-none d-md-block" />
              <div className="col">
                <div
                  className="modal-content"
                  style={{ borderRadius: "10px 10px 0px 0px" }}
                >
                  <div className="modal-header">
                    <h5 className="modal-title">Robo Mode OFF</h5>
                  </div>
                  <div className="modal-body">
                    <div className="action-sheet-content">
                      <div className="row">
                        <div className="col col-12 text-center">
                          <img
                            src="../assets/img/sad-robo-face.png"
                            alt="sad-robo-face"
                            className="logo"
                            style={{
                              width: "25%",
                            }}
                          />
                        </div>
                        {/* <div className="col col-12 mb-1">
                          <span className="text-10">
                            Currently we only have ROBO MODE as an option for
                            trading & investing.
                          </span>
                        </div> */}
                        <div className="col col-12 mb-1 mt-1 text-center">
                          <h6>
                            By turning off Robo TRDR, you will be putting a stop
                            to the Robo Assistant and TRDR will stop investing
                            your money.
                          </h6>
                          <h5>
                            NOTE: We will activate this command by end of day
                            today.
                          </h5>
                        </div>
                        <div className="col col-6">
                          <button
                            type="button"
                            className="btn btn-outline-primary btn-block btn-lg "
                            data-bs-toggle="modal"
                            data-bs-target="#roboModeOffActionSheet"
                          >
                            CANCEL
                          </button>
                        </div>
                        <div className="col col-6">
                          <button
                            type="button"
                            className="btn btn-primary btn-block btn-lg "
                            onClick={() => this.roboModeOff()}
                          >
                            CONFIRM
                          </button>
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-md-3 d-none d-md-block" />
            </div>
          </div>
        </div>

        {/* <div className="section">
          <div className="row mt-2">
            <div className="col-6">
              <div className="stat-box">
                <div className="title">Ledger Balance</div>
                <div
                  className={
                    this.state.ledger_balance_type === "Cr?"
                      ? "value text-success"
                      : "value text-danger"
                  }
                >
                  ₹ {this.state.ledger_balance || 0}{" "}
                  {this.state.ledger_balance_type}
                </div>

                <span className="text-10">Your current balance</span>
              </div>
            </div>
            <div className="col-6">
              <div className="stat-box">
                <div className="title">DP Holding Value</div>
                <div className="value">₹ {this.state.dp_holding_value || 0}</div>

                <span className="text-10">* stocks as on T-2 days</span>
              </div>
            </div>
          </div>
        </div> */}
        <br></br>
      </div>
    );
  }
}
export default withTranslation()(withRouter(Home));
