import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import OuterLogo from "../../Components/outer-logo";
import { renderRedirect } from "../../util";

class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  async componentDidMount() {
    document.querySelectorAll(".carousel-slider").forEach((carousel) =>
      new window.Splide(carousel, {
        perPage: 1,
        rewind: false,
        type: "loop",
        gap: 16,
        padding: 16,
        arrows: false,
        pagination: true,
      }).mount()
    );
  }

  _renderRedirect = () => {
    renderRedirect(this.props, "/home");
  };
  render() {
    const ContinueButton = ({ style }) => (
      <div className="form-button-group  transparent" style={{ ...style }}>
        <button
          type="button"
          onClick={this._renderRedirect}
          className="btn btn-primary btn-block btn-lg"
        >
          CONTINUE
        </button>
      </div>
    );

    return (
      <div id="appCapsule">
        {/* <OuterLogo /> */}

        <div className="carousel-slider splide">
          <div className="splide__track">
            <ul className="splide__list">
              <li className="splide__slide p-2">
                {/* <img src="assets/img/sample/photo/vector1.png" alt="alt" className="imaged w-100 square mb-4" /> */}
                <h2>Passive investing brings freedom</h2>
                <img
                  src="../assets/img/welcome-sliders/welcome-slider-1.png"
                  alt="slider-1"
                  className="imaged w-100 square mb-4"
                />

                {/* <p>TRDR puts your investment on auto-pilot!</p> */}
                <p className="card-text">
                  Automatically execute investment best practices, and takes the
                  work out of manually handling your own investments.
                </p>
              </li>
              <li className="splide__slide p-2">
                {/* <img src="assets/img/sample/photo/vector1.png" alt="alt" className="imaged w-100 square mb-4" /> */}
                <h2>Automate your investments</h2>
                <img
                  src="../assets/img/welcome-sliders/welcome-slider-2.png"
                  alt="slider-2"
                  className="imaged w-100 square mb-4"
                />

                <p className="card-text">
                  Add funds to invest. Set Robo Mode ON. Review the strategies
                  that will be executed.
                </p>
              </li>
              <li className="splide__slide p-2">
                {/* <img src="assets/img/sample/photo/vector1.png" alt="alt" className="imaged w-100 square mb-4" /> */}
                <h2>Life is too short for trading terminals</h2>
                <img
                  src="../assets/img/welcome-sliders/welcome-slider-3.png"
                  alt="slider-3"
                  className="imaged w-100 square mb-4"
                />

                <p className="card-text">
                  No need to be glued to a trading terminal. Track your
                  portfolio performance, and reports here.
                </p>

                <div className="row">
                  <div className="col col-md-4 d-none d-md-block" />
                  <div className="col col-12 col-md-4">
                    <ContinueButton
                      style={{ position: "unset", paddingTop: 30 }}
                    />
                  </div>
                  <div className="col col-md-4 d-none d-md-block" />
                </div>
              </li>
            </ul>
          </div>
        </div>
        {/* <div className="row">
          <div className="col col-md-4 d-none d-md-block" />
          <div className="col d-none d-md-block">
            <ContinueButton style={{ position: "unset", paddingTop: 30 }} />
          </div>
          <div className="col col-md-4 d-none d-md-block" />
        </div>
        <div className="row">
          <div className="col d-md-none">
            <ContinueButton />
          </div>
        </div> */}
      </div>
    );
  }
}
export default withRouter(Welcome);
