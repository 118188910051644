import { query, mutation } from "../../api";
import axios from "axios";
import * as FormData from "form-data";
const configApp = require("../../config");

export async function authenitcateUser() {
  return query(
    "authenticate_user",
    {},
    `status, _id, mobile, approval_status, roles`
  );
}

export async function getOTP(mobile) {
  return mutation("get_otp", { mobile: mobile }, `status`);
}

export async function verifyOTP(mobile, otp) {
  return mutation("verify_otp", { mobile: mobile, otp: otp }, `status, token`);
}

export async function startSignUpFlow() {
  return mutation("start_signup_application", {}, `status, application_id`);
}

export async function startSignUpWorkFlow() {
  return mutation(
    "start_signup_workflow",
    {},
    ` status
    _id
    signup_application_id
    workflow_status
    current_workflow_step_id
    workflow_steps {
      _id
      name
    }`
  );
}

export async function updateSignApplication(reqObj) {
  return mutation(
    "update_signup_application",
    reqObj,
    `_id
    status
    message
    fullName
    lastName
    emailAddress
    gender
    pan
    martialStatus
    residentialStatus
    permanentAddress
    communicationAddress
    fathersName
    mothersName
    occupationType
    annualIncome
    isPoliticalyExposed`
  );
}

export async function getHomePageData() {
  return query(
    "get_home_page_data",
    {},
    `portfolio_value
     fund_invested
     ledger_balance
     ledger_balance_type
     dp_holding_value
     dp_total_value
     previous_longshort_value
     longshort_value
     investment_amount
     payout_amount
     profit_loss_amount
     performance_percentage
     payout_value
     ledger_modified_ts
     portfolio_modified_ts`,
    "signin"
  );
}

export async function fetchNotificationLatest() {
  return query(
    "fetch_notification_by_cateogory",
    { type: "home" },
    `notification_info {
      _id
      status
      user_id
      type
      title
      text
      details
      created_ts
    }`,
    "notification"
  );
}

export async function getOrderInfo() {
  return mutation(
    "create_order_id",
    {},
    `status
    order_id
    bank_name
    ifsc
    cashfree_bank_code
    account_number`,
    "cashfree"
  );
}


export async function getProfileData() {
    return mutation(
      "fetch_signup_application",
      {},
      `_id
      status
      account_number
      ifsc
      bank_name
      account_status`
    );
  }
