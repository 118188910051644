import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import { cutDecimals } from "../../../util/helper";
import SuccessComponent from "./SuccessComponent";
import DetailsTable from "./DetailsTable";
import BidEntries from './BidEntries';
import ExpiredOrder from './ExpiredOrder';

const UpdateIpo = ({
    setActionSheet,
    data,
    title
}) => {

    const queryClient = useQueryClient();
    const ipoDetails = queryClient.getQueryData('ipos')

    const [orderDetails, setOrderDetails] = useState(null)
    const [currentIpo, setCurrentIpo] = useState(null)
    const [orderStatus, setOrderStatus] = useState(null)

    useEffect(() => {
        if (ipoDetails) {

            let ipos = ipoDetails.data.eipo_get_open_issues.open_issues
            setCurrentIpo(
                findIpo(ipos, data.scripid)
            )
        }
    }, [])

    useEffect(() => currentIpo === null && setOrderStatus('expired'), [])
    useEffect(() => setOrderDetails(data), [])


    const dismissModal = () =>
        setActionSheet({
            mode: 'update',
            data: null
        })

    const findIpo = (ipos, scripid) =>
        ipos.filter(item => (item.symbol === scripid))

    const removeDuplicates = array => array.reduce((acc, current) => {

        const x = acc.find(item => item.category === current.category);
        if (!x) {
            return acc.concat([current]);
        } else {
            return acc;
        }
    }, []);

    //catagories filter
    // const filterCategories = data => removeDuplicates(currentIpo).filter(item => item.category)

    const Declaration = ({ checked, onAccept }) =>
        <div className="input-group p-1" style={{ display: "flex", flexDirection: 'row' }}>
            <div>
                <input
                    style={{ marginRight: 5 }}
                    type="checkbox"
                    className="form-check-input"
                    checked={checked}
                    onChange={(e) => onAccept(e.target.checked)}
                />
            </div>
            <div style={{ flex: 1 }}>
                <p>
                    I hereby undertake that I have read the Red Herring Prospectus
                    and I am an eligible UPI bidder as per the applicable provisions
                    of the SEBI (Issue of Capital and Disclosure Requirement) Regulation, 2009.
                </p>
            </div>
        </div>

    const ActionButtons = ({ loading, onSubmit, resetForm, submitDisabled }) =>
        <>
            <button
                onClick={() => resetForm()}
                type="button"
                className="btn-outline-primary btn-lg"
                data-bs-dismiss="modal"
                data-bs-target="#addMoneyActionSheet"
            >
                CLOSE
            </button>
            <button
                disabled={submitDisabled}
                type="button"
                className="btn-primary btn-lg"
                onClick={() => onSubmit()} >
                {
                    loading ?
                        <div className="spinner-border text-light" role="status"></div> :
                        "SUBMIT"
                }
            </button>
        </>

    return (
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                {
                    orderStatus === 'success' ?
                        <SuccessComponent title={'Thanks for placing an IPO bid.'} /> :
                        orderStatus === 'expired' ?
                            <ExpiredOrder
                                orderDetails={orderDetails}
                                title={title}
                                dismissModal={dismissModal} /> :
                            <>
                                <Title
                                    title={title}
                                    dismissModal={dismissModal} />
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-md-6"></div>
                                        <div className="col-sm-12 col-md-6">
                                            <blockquote className="blockquote mb-0 p-1" style={{ backgroundColor: '#ffe291' }}>
                                                <p className="text-12">
                                                    IPO window will remain open from 10 AM untill 5 PM. Please use your VPA (UPI ID) for placing bids.
                                                </p>
                                            </blockquote>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col col-sm-3">
                                            <DetailsTable data={currentIpo ? currentIpo[0] : null} />
                                        </div>
                                        <div className="col col-sm-9 p-2 pt-0">
                                            <BidTitle title={currentIpo && currentIpo[0].symbol} />
                                            <div className="form-group basic col-sm">
                                                <label className="label">
                                                    UPI address
                                                </label>
                                                {
                                                    orderDetails && orderDetails.accountnumber_upiid
                                                }
                                            </div>
                                            <hr />
                                            <BidEntries
                                                bids={orderDetails ? orderDetails.bids : null}
                                                currentIpo={currentIpo} />
                                            <Declaration checked={true} onAccept={() => console.log("checked")} />
                                        </div>
                                    </div>
                                </div >
                            </>
                }
                <div className="modal-footer">
                    <ActionButtons
                        resetForm={() => console.log('reset form')}
                        loading={false}
                        submitDisabled={orderStatus === 'expired'}
                        onSubmit={() => console.log("Submit")} />
                </div>
            </div >
        </div >
    );
}

export const Title = ({ title, dismissModal }) =>
    <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
        <a onClick={() => dismissModal()} data-bs-dismiss="modal">Close</a>
    </div>

export const BidTitle = ({ title }) =>
    <div className="form-group basic">
        <div className="input-wrapper">
            <label className="label" htmlFor="account1">
                ScripId
            </label>
            <label className="modal-title" htmlFor="account1">
                {title}
            </label>
        </div>
    </div>

export default UpdateIpo