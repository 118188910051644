import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import LoadingContent from "../../Components/loading-content";

import { fetchMyIposList } from "./api";

import Buttons from "./Buttons";

const MyIposTable = ({
    setActionSheet,
    deleteIpoOrder
}) => {

    const { isLoading, data } = useQuery('myIpos', fetchMyIposList)
    const [myIpos, setMyIpos] = useState([])

    useEffect(
        () => data && setMyIpos(
            bidFilter(data.data.get_my_ipo_orders.my_ipo_orders)
        ), [data])

    const getBidErrorCount = array => Object.keys(array).map(
        keys => Object.values(array[keys].bids).map(
            item => item.errorcode
        ).filter(item => item === "0")
            .length
    )

    const bidFilter = array => Object.values(array).filter(
        (item, key) => getBidErrorCount(array)[key] !== 0 && item)

    const generateTimeStamp = ts =>
        new Date(parseInt(ts)).toLocaleString()

    const confirmDelete = (data) =>
        window.confirm("Are you sure you want to delete this IPO order ?") &&
        deleteIpoOrder(data)

    return (
        <LoadingContent showLoader={isLoading}>
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Stock</th>
                            <th scope="col">Category</th>
                            <th scope="col">Timestamp</th>
                            <th scope="col" className="text-end">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            myIpos.length === 0 ?
                                <tr className="borderless-last-tr">
                                    <th scope="row">No IPOs to show</th>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr> :
                                Object.keys(myIpos)
                                    .map(key =>
                                        <tr key={key} className="borderless-last-tr">
                                            <th scope="row">{myIpos[key].scripid}</th>
                                            <td>{myIpos[key].category}</td>
                                            <td>{generateTimeStamp(myIpos[key].created_ts)}</td>
                                            <td className="text-end text-primary">
                                                <Buttons
                                                    expanded
                                                    updateIpo={() => setActionSheet(myIpos[key])}
                                                    deleteIpo={() => confirmDelete(myIpos[key])} />
                                            </td>
                                        </tr>
                                    )
                        }
                    </tbody>
                </table>
            </div>
        </LoadingContent>
    );
}

export default MyIposTable;
