import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import SuccessComponent from "./SuccessComponent";
import DetailsTable from "./DetailsTable";
import { postIpoOrder, verifyUPI, get_drhp_link } from "../api";
import BidEntriesNew from "./BidEntriesNew";
import { ipoOrderResolver } from "../../../util/helper";

const CreateIpo = ({ setActionSheet, data, title }) => {
  const queryClient = useQueryClient();
  const ipoDetails = queryClient.getQueryData("ipos");
  const submitIpoOrderMutation = useMutation(
    () => postIpoOrder(ipoOrderResolver(currentIpo[0].symbol, upi, investorType, activeBids)),
    {
      onSuccess: (res) => {
        let order = res.data.eipo_order;
        console.log(res.data);
        if (order.bids === null) alert("Please select a valid category. eg: IND");
        if (order.bids) {
          let errorBids = order.bids.filter((item) => item.errorcode !== "0");
          if (errorBids.length !== 0) alert(errorBids.map((item) => item.message));
          else {
            setOrderSuccess({ success: true, response: order });
          }
        }
      },
      onError: (err) => console.log(err),
    }
  );

  const [investorType, setInvestorType] = useState("IND");
  const [orderSuccess, setOrderSuccess] = useState(false);
  const [currentIpo, setCurrentIpo] = useState(null);
  const [upi, setUpi] = useState(null);
  const [upiVerified, setUpiVerified] = useState(null);
  const [agree, setAgree] = useState(true);
  const [drhpDetails, setDRHPDetails] = useState(null);

  const [activeBids, setActiveBids] = useState([]);

  useEffect(() => {
    if (ipoDetails) {
      let ipos = ipoDetails.data.eipo_get_open_issues.open_issues;
      setCurrentIpo(findIpo(ipos, data.symbol));
      set_drhp_details(data.symbol);      
    }
  }, [data]);

  const validateUpi = (data) => {
    setUpiVerified("loading");
    verifyUPI(data)
      .then((res) =>
        res.data.validate_upi_id.status ? setUpiVerified(true) : setUpiVerified(false)
      )
      .catch(() => setUpiVerified(false));
  };

  const set_drhp_details = async (scripid) => {
    const drhpResponse = await get_drhp_link(scripid);
    setDRHPDetails(
      drhpResponse?.data?.get_drhp_link || {}
    );
  };

  const dismissModal = () =>
    setActionSheet({
      mode: "create",
      data: null,
    });

  const findIpo = (ipos, scripid) => ipos.filter((item) => item.symbol === scripid);

  const resetForm = () => {
    setUpiVerified(null);
    setCurrentIpo(null);
    setOrderSuccess(false);
    setActiveBids([]);
    setUpi(null);
  };

  const Title = ({ title, dismissModal }) => (
    <div className="modal-header">
      <h5 className="modal-title">{title}</h5>
      <a onClick={() => dismissModal()} data-bs-dismiss="modal">
        Close
      </a>
    </div>
  );

  const BidTitle = ({ title }) => (
    <div className="form-group basic">
      <div className="input-wrapper">
        <label className="label" htmlFor="account1">
          ScripId
        </label>
        <label className="modal-title" htmlFor="account1">
          {title}
        </label>
      </div>
    </div>
  );

  const Declaration = ({ checked, onAccept }) => (
    <div className="input-group p-1" style={{ display: "flex", flexDirection: "row" }}>
      <div>
        <input
          style={{ marginRight: 5 }}
          type="checkbox"
          className="form-check-input"
          checked={checked}
          onChange={(e) => onAccept(e.target.checked)}
        />
      </div>
      <div style={{ flex: 1 }}>
        <p>
          I hereby undertake that I have read the Red Herring Prospectus and I am an eligible UPI
          bidder as per the applicable provisions of the SEBI (Issue of Capital and Disclosure
          Requirement) Regulation, 2009.
        </p>
      </div>
    </div>
  );

  const ActionButtons = ({ onSubmit, resetForm }) => (
    <>
      <button
        onClick={() => resetForm()}
        type="button"
        className="btn-outline-primary btn-lg"
        data-bs-dismiss="modal"
        data-bs-target="#addMoneyActionSheet">
        CLOSE
      </button>
      {!orderSuccess && (
        <button type="button" className="btn-primary btn-lg" onClick={() => onSubmit()}>
          {submitIpoOrderMutation.isLoading ? (
            <div
              className="spinner-border text-light"
              style={{ height: "1rem", width: "1rem" }}
              role="status"
            />
          ) : (
            "SUBMIT"
          )}
        </button>
      )}
    </>
  );

  return (
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        {orderSuccess ? (
          <SuccessComponent
            response={orderSuccess.response}
            title={"Thanks for placing an IPO bid."}
          />
        ) : (
          <>
            <Title title={title} dismissModal={dismissModal} />
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6"></div>
                <div className="col-sm-12 col-md-6">
                  <blockquote
                    className="blockquote mb-0 p-1"
                    style={{ backgroundColor: "#ffe291" }}>
                    <p className="text-12">
                      IPO window will remain open from 10 AM untill 5 PM. Please use your VPA (UPI
                      ID) for placing bids.
                    </p>
                  </blockquote>
                </div>
              </div>

              <div className="row">
                <div className="col col-sm-3">
                  <DetailsTable data={currentIpo ? currentIpo[0] : null} drhp={drhpDetails ? drhpDetails : {}} />
                </div>
                <div className="col col-sm-9 p-2 pt-0">
                  <BidTitle title={currentIpo && currentIpo[0].symbol} />
                  <div className="form-group basic col-sm">
                    <label className="label">UPI address</label>
                    <div className="input-group row">
                      <div className="col-8">
                        <input
                          type="text"
                          name="upi"
                          className="form-control upi-id-input"
                          placeholder="Enter UPI ID"
                          value={upi || ""}
                          onChange={(e) => setUpi(e.target.value)}
                          onBlur={(e) => validateUpi(e.target.value)}
                        />
                      </div>
                      <div className="col-4">
                        <button
                          type="button"
                          style={{ borderRadius: 10, height: 40 }}
                          className="btn-primary btn-block btn-lg p-0.5"
                          name="verifyUPI"
                          onClick={() => validateUpi(upi)}>
                          {upiVerified === "loading" ? (
                            <div
                              className="spinner-border text-light"
                              style={{ height: "1rem", width: "1rem" }}
                              role="status"></div>
                          ) : upiVerified ? (
                            "Verified"
                          ) : (
                            "Verify"
                          )}
                        </button>
                      </div>
                    </div>
                    {upiVerified ? "Verified" : "Please enter a valid upi id"}
                  </div>
                  {upiVerified && (
                    <>
                      <BidEntriesNew
                        investorType={investorType}
                        setInvestorType={setInvestorType}
                        activeBids={activeBids}
                        setActiveBids={setActiveBids}
                        currentIpo={currentIpo}
                      />
                      <Declaration checked={agree} onAccept={(status) => setAgree(status)} />
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        <div className="modal-footer">
          <ActionButtons
            resetForm={() => resetForm()}
            onSubmit={() =>
              agree
                ? submitIpoOrderMutation.mutate()
                : alert("Please agree to the terms and conditions")
            }
          />
        </div>
      </div>
    </div>
  );
};

export default CreateIpo;
