import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import { Link } from 'react-router-dom';
const configApp = require('../config');

class MenuBar extends Component {
  
  async componentDidMount() {
    this.setState({url: window.location.origin + this.props.location.pathname })
  }
    render() {               

        return (
          <div class="row">
            <div class="col-sm-6 text-uppercase text-4 text-white text-center text-sm-left">
              <a href="https://trdr.in"><img  src="../images/trdr-horizontal.png" width="150px" alt="TRDR"/></a>
            </div>  
            {/* <div class="col-sm-6 text-2  font-weight-300 text-center text-sm-right"> 
            <a class=" text-3 font-weight-600" href="#">Robo</a> /&nbsp;
            <a class=" text-3 font-weight-600" href="#">Pricing</a> /&nbsp;
            <a class="text-3 font-weight-600" href="#">Partner</a> /&nbsp;
            <a class="text-3 font-weight-600" href="/signup/mobile">Sign Up</a> /&nbsp;
            <a class=" text-3 font-weight-600" href="#">Log in</a> 
            </div> */}
          </div>
)} 
}
export default withRouter(MenuBar);