import { query, mutation } from "../../api";
export async function getReport() {
  return query(
    "get_report",
    {},
    `netposition_pnl          
          client_scrips {
            symbol
            data_type
            exchange_name
            scrip_name
            buy_qty
            buy_lot
            buy_value
            buy_avg
            sell_qty
            sell_lot
            sell_value
            sell_avg
            net_qty
            net_lot
            net_value
            net_avg
            market_rate
            market_value
            profit_loss
          }
          transaction_charges {
            tran_charges
            sebi_charges
            stt
            stamp_duty_charges
            central_gst_on_brokerage
            central_gst_on_tran_charges
            state_gst_on_brokerage
            state_gst_on_tran_charges
            integrated_gst_on_brokerage
            integrated_gst_on_tran_charges
            other_details
            seq_turnover
            delivery_turnover
            charges
            central_gst
            state_gst
          }
          total_details {
            buy_qty
            buy_value
            sell_qty
            sell_value
            net_qty
            net_value
            net_avg
            profit_loss
          }`,
    "signin"
  );
}
export async function update_report(reportList) {
  return mutation(
    "update_report",
    {},
    `netposition_report:${reportList}) {
        netposition_pnl,
        netposition_report{symbol,buy_qty,buy_lot,buy_value}
      }}`
  );
}
