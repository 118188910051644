export function renderRedirect(props, url) {
    props.history.push(url);
}

export function workFlowSteps(){
    let workflow_steps = [
        {
          "_id": "60967b9f27f851983d320084",
          "name": "mobile_verification",
          "redirect": ""
        },
        {
          "_id": "60967b9f27f851765d320085",
          "name": "email_verification",
          "redirect": "signup/email"
        },
        {
          "_id": "60967b9f27f851a8f0320086",
          "name": "account_fee_payment",
          "redirect": "signup/fees"
        },
        {
          "_id": "60967b9f27f85133c5320087",
          "name": "digilocker_aadhaar_pan_geo_location",
          "redirect": "signup/pan"
        },
        {
          "_id": "60967b9f27f8514baa320088",
          "name": "pan_upload",
          "redirect": ""
        },
        {
          "_id": "60967b9f27f851e421320089",
          "name": "personal_details",
          "redirect": ""
        },
        {
          "_id": "60967b9f27f851989f32008a",
          "name": "address_details",
          "redirect": ""
        },
        {
          "_id": "60967b9f27f851656a32008b",
          "name": "additional_details",
          "redirect": ""
        },
        {
          "_id": "60967b9f27f851504c32008c",
          "name": "cheque_bank_statement_upload",
          "redirect": ""
        },
        {
          "_id": "60967b9f27f8515c2632008d",
          "name": "bank_details",
          "redirect": ""
        },
        {
          "_id": "60967b9f27f851fe1532008e",
          "name": "signature_upload",
          "redirect": ""
        },
        {
          "_id": "60967b9f27f851388432008f",
          "name": "ipv_selfie",
          "redirect": ""
        },
        {
          "_id": "60967b9f27f85158cb320090",
          "name": "ipv_Video",
          "redirect": ""
        },
        {
          "_id": "60967b9f27f8519ef3320091",
          "name": "agreement_doc_generate_and_sign",
          "redirect": ""
        },
        {
          "_id": "60967b9f27f851735c320092",
          "name": "email_with_appliction_confirmation_agreement",
          "redirect": ""
        },
        {
          "_id": "60967b9f27f8517753320093",
          "name": "email_once_account_set",
          "redirect": ""
        }
      ];

      return workflow_steps;
}
