import React, { useEffect, useState } from "react";
import LoadingContent from "../../Components/loading-content";
import { getDepositTransactions, get_withdraw_requests } from "./api";
import TransactionsTable from "./TransactionsTable";
import WithdrawalTransactionsTable from "./WithdrawalTransactionsTable";

function PassBook() {
  const [depositTransactionsList, setDepositTransactionsList] = useState([]);
  const [withdrawalTransactionsList, setWithdrawalTransactionsList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);

  const fetchDepositTransactionsList = async () => {
    const depostiResponse = await getDepositTransactions();
    setDepositTransactionsList(
      depostiResponse?.data?.fetch_transactions?.transaction_info.reverse() || []
    );
    setShowLoader(false);
  };

  const fetchWithdrawalTransactionsList = async () => {
    const withdrawResponse = await get_withdraw_requests();
    setWithdrawalTransactionsList(
      withdrawResponse?.data?.get_withdraw_requests?.withdraw_requests.reverse() || []
    );
    setShowLoader(false);
  };

  useEffect(() => {
    fetchDepositTransactionsList();
    fetchWithdrawalTransactionsList();
  }, []);
  return (
    <div className="section">
      <div className="wallet-card">
        <div className="card-body p-0">
          <h4 className="card-title">Transactions</h4>
          <ul className="nav nav-tabs lined" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                data-bs-toggle="tab"
                href="#deposits"
                role="tab"
              >
                Deposits
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                href="#withdrawals"
                role="tab"
              >
                Withdrawals
              </a>
            </li>
          </ul>
          <div className="tab-content mt-2">
            <div
              className="tab-pane fade show active"
              id="deposits"
              role="tabpanel"
            >
              {
                depositTransactionsList.length >= 1
                  ? <LoadingContent showLoader={showLoader}><TransactionsTable transactionsList={depositTransactionsList} /></LoadingContent>
                  : <LoadingContent showLoader={showLoader}>You have not made any deposits yet.</LoadingContent>
              }
            </div>
            <div className="tab-pane fade" id="withdrawals" role="tabpanel">
              {
                withdrawalTransactionsList.length >= 1
                  ? <LoadingContent showLoader={showLoader}><WithdrawalTransactionsTable transactionsList={withdrawalTransactionsList} /></LoadingContent>
                  : <LoadingContent showLoader={showLoader}>You have not made any withdrawals yet.</LoadingContent>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PassBook;
