import jwt_decode from "jwt-decode";
import { workFlowSteps } from "../util";
import { mutation } from "../api"
import { spinnerService } from "../Components/spinner.service";
import {
  authenitcateUser
} from "../Pages/Home/api";


export async function requireLogin(to, from, next) {

  spinnerService.show("mainSpinner", "Please wait...");

  let identifier = await getCurrentWorkflowId().catch(err => {

  });

  if (identifier) {
    let url = getCurrentRedirectUrl(identifier);
  }

  if (to.meta.auth) {
    const { localStorage } = window;
    const token = localStorage.getItem("token");
    if (token) {
      try {
        let auth = await authenitcateUser();
        if (auth && auth.data && auth.data.authenticate_user) {
          let user = auth.data.authenticate_user;
          if (user.status === true && user.approval_status === "approved") {
            spinnerService.hide("mainSpinner");
            next();
          } else {
            spinnerService.hide("mainSpinner");
            next.redirect("/");
          }
        } else {
          spinnerService.hide("mainSpinner");
          next.redirect("/");
        }
        // const userToken = jwt_decode(token);
        // const currentTimeStamp = Math.round(new Date().getTime() / 1000);
        // const exp = userToken.exp - currentTimeStamp;
        // if (!token || exp <= 0) {
        //   spinnerService.hide("mainSpinner");
        //   next.redirect("/");
        // } else {
        //   spinnerService.hide("mainSpinner");

        //   next();
        //   // next.redirect("/"+url);
        // }
      } catch (e) {
        spinnerService.hide("mainSpinner");
        next.redirect("/");
        console.log(e);
      }
    } else {
      spinnerService.hide("mainSpinner");
      next.redirect("/");
    }
  } else {
    spinnerService.hide("mainSpinner");
    next();
  }

}
export function isTokenExpired() {
  return false;
}

export async function getCurrentWorkflowId() {

  let workflow = await getCurrentWorkflow();
  if (workflow && workflow.data && workflow.data.get_current_workflow && workflow.data.get_current_workflow.current_workflow_step_id) {
    let currentWorkflowId = workflow.data.get_current_workflow.current_workflow_step_id;
    localStorage.setItem("identifier", currentWorkflowId);
    return currentWorkflowId;
  }
}

export function getCurrentRedirectUrl(currentWorkflowId) {
  let url = "";
  let workFlowRedirect = workFlowSteps();
  workFlowRedirect.forEach(res => {
    if (res._id == currentWorkflowId) {
      url = res.redirect;
    }
  });
  return url;
}

export async function getCurrentWorkflow() {
  return mutation("get_current_workflow", {}, `status, _id, signup_application_id, workflow_status, current_workflow_step_id, workflow_steps{_id,name}`);
}

export async function workFlowStepUpdate(workflow_step_id) {
  return mutation("workflow_step_submit", { workflow_step_id: workflow_step_id }, `status
  message
  current_workflow_step_id`);
}