import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import LoadingContent from "../../Components/loading-content";
import { getPortfolioData } from "./api";
class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      portfolioData: null,
      showLoader: true,
    };
  }
  async componentDidMount() {
    let data = await getPortfolioData();
    if (data.data.get_portfolio_data.portfolio_data !== null)
      this.setState({
        modified_ts: data.data.get_portfolio_data.modified_ts,
        portfolioData: data.data.get_portfolio_data.portfolio_data,
        showLoader: false,
      });
  }
  render() {
    return (
      <div id="appCapsule">
        <div className="section mt-2">
          <div className="mt-3 text-left">
            <div className="section-title pb-0">Portfolio</div>
            <span className="text-10">Stocks as on {new Intl.DateTimeFormat("en-IN", {
              year: "2-digit",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }).format(this.state.modified_ts)}</span>
          </div>
          {this.state.portfolioData === null ? (
            <div className="section mt-3 text-center">
              <div className="section-title">
                There are currently no stocks in your portfolio :(
              </div>
            </div>
          ) : (
            <LoadingContent showLoader={this.state.showLoader}>
              <div className="card p-1">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Symbol</th>
                        <th scope="col" className="text-end">
                          Holding Qty
                        </th>
                        <th scope="col" className="text-end">
                          Rate per Share
                        </th>
                        <th scope="col" className="text-end">
                          Total Value
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {(this.state.portfolioData || []).map((row, index) => (
                        <>
                          {row?.scrip_name === "Total :" ? (
                            <tr className="borderless-last-tr">
                              <th scope="row">
                                <h5>{row.scrip_name}</h5>
                              </th>
                              <td className="text-end">
                                <h5>
                                  {row?.holding_qty ? row.holding_qty : 0.00}
                                </h5>
                              </td>
                              <td className="text-end">
                                <h5>
                                  {row?.rate_per_share
                                    ? Number(row.rate_per_share).toFixed(2)
                                    : ""}
                                </h5>
                              </td>
                              <td className="text-end">
                                <h5>
                                  {row?.total_value
                                    ? Number(row.total_value).toFixed(2)
                                    : 0.00}
                                </h5>
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <th scope="row">{row.scrip_name}</th>
                              <td className="text-end">
                                {row?.holding_qty ? row.holding_qty : ""}
                              </td>
                              <td className="text-end">
                                {row?.holding_qty
                                  ? Number(row.rate_per_share).toFixed(2)
                                  : ""}
                              </td>
                              <td className="text-end">
                                {row?.holding_qty
                                  ? Number(row.total_value).toFixed(2)
                                  : ""}
                              </td>
                            </tr>
                          )}
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </LoadingContent>
          )}
        </div>
      </div>
    );
  }
}
export default withRouter(Portfolio);
